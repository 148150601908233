import React, { useState, useContext, useEffect } from 'react';
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import { useImmerReducer } from 'use-immer';
import { Alert, Grid, Button, Modal, TextField, Typography, IconButton, Snackbar, FormHelperText, Autocomplete } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'; 
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; 
import CloseIcon from '@mui/icons-material/Close';
import StateContext from "../../Contexts/StateContext";
import AddRoundedIcon from '@mui/icons-material/AddCircleOutlined';
import AddScientist from './AddScientist';
import { formatDate } from '../../Utils/DateUtils';


const AddPublication = ({ open, onClose }) => {
    const {config, environment } = require('../../index');
    const navigate = useNavigate()    
    const GlobalState = useContext(StateContext); 
    const userId = GlobalState.userId;
    

    const initialState = {
        
        publicationTitle: '',
        publicationDate: dayjs(),
        publicationPlace: '',
        publicationType: '',
        publicationPublisher: '',
        publicationFullReference: '',
        sendRequest: 0,
        contributor: "",
        userProfile:{
            companyName: '',
            phoneNumber: '',
        },
        scientist: "",
        researchField: "",
        scientistFullNames: [],
        openSnack: false,
        disabledAddPPublicationBtn: false,
        TitleError: {
            hasError: false,
            errorMessage: ''
        },
        DateError: {
            hasError: false,
            errorMessage: ''
        },
        
        PlaceError: {
            hasError: false,
            errorMessage: ''
        },
        PublisherError: {
            hasError: false,
            errorMessage: ''
        },
        FullReferenceError: {
            hasError: false,
            errorMessage: ''
        },
        ScientistError: {
            hasError: false,
            errorMessage: ''
        },        
    }    

    function ReducerFunction(draft, action) {
        switch (action.type) {
            case 'catchScientistChange':
                draft.scientist = action.scientistChosen;
                draft.ScientistError.hasError = false;
                draft.ScientistError.errorMessage = '';
                break;
            case 'catchPublicationTitleChange':
                draft.publicationTitle = action.publicationTitleChosen;
                draft.TitleError.hasError = false;
                draft.TitleError.errorMessage = ''; 
                break;
            case 'catchPublicationDateChange':
                draft.publicationDate = action.publicationDateChosen;
                draft.DateError.hasError = false;
                draft.DateError.errorMessage = '';
                break;
            case 'catchPublicationTypeChange':
                draft.publicationType = action.publicationTypeChosen;                
                break;
            case 'catchPublicationPlaceChange':
                draft.publicationPlace = action.publicationPlaceChosen;
                draft.PlaceError.hasError = false;
                draft.PlaceError.errorMessage = '';
                break;
            case 'catchPublicationPublisherChange':
                draft.publicationPublisher = action.publicationPublisherChosen;
                draft.PublisherError.hasError = false;
                draft.PublisherError.errorMessage = '';
                break;
            case 'catchPublicationFullReferenceChange':
                draft.publicationFullReference = action.publicationFullReferenceChosen;
                draft.FullReferenceError.hasError = false;
                draft.FullReferenceError.errorMessage = '';
                break;
            case 'catchUserProfileInfo':
                draft.userProfile.companyName = action.profileObject.company_name; 
                draft.userProfile.phoneNumber = action.profileObject.phone_number;
                break;
            case 'catchScientistFullNames':
                draft.scientistFullNames = action.scientistFullNames;       
                break;
            case 'changeSendRequest':
                draft.sendRequest = draft.sendRequest + 1;
                break;
            case 'reset':
                return {
                    ...initialState,
                    userProfile: draft.userProfile
                };
            case 'openTheSnack':
                draft.openSnack = true;
                break;
            case 'allowAddPublicationBtn':
                draft.disabledAddPublicationBtn = false;
                break;
            case 'emptyTitle':
                draft.TitleError.hasError = true;
                draft.TitleError.errorMessage = "A cím mező nem lehet üres!";
                break;
            case 'emptyDate':
                draft.DateError.hasError = true;
                draft.DateError.errorMessage = "A dátum mező nem lehet üres!";
                break;
            case 'emptyFullReference':
                draft.FullReferenceError.hasError = true;
                draft.FullReferenceError.errorMessage = "A teljes hivatkozás mező nem lehet üres!";
                break;
            case 'emptyScientist':
                draft.ScientistError.hasError = true;
                draft.ScientistError.errorMessage = "A szerző mező nem lehet üres!";
                break; 
            case 'updateResearchField':
                draft.researchField = action.researchField;
                break;
            default:
                break;
        }
    }
    
    const [state, dispatch] = useImmerReducer(ReducerFunction, initialState)
    const [isAddScientistModalOpen, setAddScientistModalOpen] = useState(false); 

    const handleAddScientistClick = () => {        
        setAddScientistModalOpen(true);
    };

    const handleReset = () => {
        dispatch({ type: 'reset' });
    };  
    

    //request to get profile info
    useEffect(() => {
        async function getProfileInfo() {
            try {
                const profileEndpoint = config[environment].getProfileEndpoint(userId); 
                const response = await axios.get(profileEndpoint);                  
                dispatch({type: 'catchUserProfileInfo', profileObject: response.data});
            } catch (e) {
                console.log(e.response);
            }
        }
        getProfileInfo();
    }, [config, environment, dispatch, userId]);

    //getting scientist names with research field
    useEffect(() => {
        const scientistIdWithFullNameEndpoint = config[environment].scientistIdWithFullNameEndpoint;
        async function GetScientistNames() {
            try {
                const response = await axios.get(scientistIdWithFullNameEndpoint);
                const scientistFullNames = response.data.map(scientist => ({
                    ...scientist,
                    id: scientist.id,
                    full_name: scientist.full_name,
                    fields_of_science_id: scientist.fields_of_science_id, // Assuming 'fields_of_science' is an object with an 'id' property
                    fields_of_science_name: scientist.fields_of_science_name, // Assuming 'fields_of_science' is an object with a 'name' property
                }));
                dispatch({type: 'catchScientistFullNames', scientistFullNames});
            } catch (e) {
                console.log(e.response);
            }
        }
        GetScientistNames();
    }, [config, environment, dispatch]);
      

    useEffect(() => {
        if (state.sendRequest) {
            const data = new FormData();
            data.append('title', state.publicationTitle);
            data.append('publication_date', formatDate(state.publicationDate));
            data.append('publication_type', state.publicationType);
            data.append('publication_place', state.publicationPlace);
            data.append('publisher', state.publicationPublisher);
            data.append('full_reference', state.publicationFullReference);
            data.append('contributor', GlobalState.userId);
            data.append('field_of_science', state.scientistFullNames[0].fields_of_science_id);
            data.append('scientist', state.scientistFullNames[0].id);

             
            
            async function AddPublication() {
                try {
                    const createPublicationEndpoint =config[environment].createPublicationEndpoint;                    
                    const response = await axios.post(createPublicationEndpoint, data);
                    dispatch({type: 'openTheSnack'});
                    window.location.reload();
                } catch (e) {
                    dispatch({type:'allowAddPublicationBtn'});
                }
            }
            AddPublication();
        }
    }, [state.sendRequest]);

    useEffect(()=>{
        if (state.openSnack){
          setTimeout(()=>{
            navigate('/academia');
          }, 1500)
        }
      }, [state.openSnack]);
    

    function SubmitButtonDisplay(){
           
        if(GlobalState.userIsLogged && state.userProfile.companyName !== null && state.userProfile.companyName !== "" && 
            state.userProfile.phoneNumber !== null && state.userProfile.phoneNumber !== "" ){
            return (<Button variant='contained'  type='submit' disabled={state.disabledAddPropertyBtn} >BEKÜLDÉS</Button>);
        } 
        else if(GlobalState.userIsLogged && (state.userProfile.companyName === null || state.userProfile.companyName === "" || 
            state.userProfile.phoneNumber === null || state.userProfile.phoneNumber === "")){
            return (<Button variant='outlined'  onClick={()=> navigate("/profile")}  >TÖLTSD KI A PROFILOD OBJEKTUMOK HOZZAADÁSÁHOZ</Button>);
        }
        else if (!GlobalState.userIsLogged){
            return (<Button variant='outlined'  onClick={()=> navigate("/login")}  >JELENTKEZZ BE OBJEKTUMOK HOZZAADÁSÁHOZ</Button>);
        }
    }


    function FormSubmit(e) {
        e.preventDefault();        
        if(!state.TitleError.hasError && !state.DateError.hasError && !state.PlaceError.hasError && 
            !state.PublisherError.hasError && !state.FullReferenceError.hasError && !state.ScientistError.hasError && state.publicationTitle  &&
            state.publicationDate && state.publicationFullReference && state.scientist) {
           {dispatch({type: 'changeSendRequest'});
            dispatch({type: 'disabledAddScientistBtn'});}
        }
        else if(!state.publicationTitle) {
            dispatch({type: 'emptyTitle'});
        }
        else if(!state.publicationDate) {
            dispatch({type: 'emptyDate'});
        }
        else if(!state.publicationFullReference) {
            dispatch({type: 'emptyFullReference'});   
        }
        else if(!state.scientist) {
            dispatch({type: 'emptyScientist'});
        }
    } 

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Modal open={open} onClose={onClose}>
                <form onSubmit={FormSubmit}>
                    <Grid
                    item
                    container
                    direction="column"
                    alignItems="stretch"
                    justifyContent="center"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '100%', // Set default width to full width
                        maxWidth: 400, // Set max width for computer screens
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        '@media (min-width: 768px)': { // Tablet media query
                        maxWidth: '66.66%', // 2/3 of the screen width for tablets
                        },
                        '@media (min-width: 992px)': { // Large tablet/desktop media query
                        maxWidth: '50%', // 1/2 of the screen width for computers
                        },
                    }}
                    >
                    <Grid container justifyContent="space-between" >
                        <Typography variant='h4'>Publikáció hozzáadása</Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid item sx={{ marginTop: "1rem" }}>
                        <Grid container  >
                            <Grid item xs={6} >
                            
                            <Autocomplete 
                                variant="standard"
                                options={state.scientistFullNames}
                                getOptionLabel={(option) => `${option.full_name} (${option.fields_of_science_name})`}
                                onChange={(e, value) => {
                                    dispatch({type: 'catchScientistChange', scientistChosen: value ? value.full_name : ''});
                                    dispatch({type: 'updateResearchField', researchField: value ? value.fields_of_science : ''});
                                }}
                                renderInput={(params) => 
                                    <TextField 
                                        {...params} 
                                        label="Szerző (székelyföldi főszerző, társszerző, szerkesztő)" 
                                        required 
                                        fullWidth 
                                        className="autocomplete-input"
                                    />
                                }
                                sx={{ mb: 2 }} 
                            />                           
                            </Grid >
                            
                            <Grid item xs={1}>
                                <IconButton color="primary" onClick={handleAddScientistClick} >
                                    <AddRoundedIcon  />
                                </IconButton>
                            </Grid>
                            <Grid item xs={5}>
                                <TextField variant="standard" label="Kiadvány típusa" 
                                value={state.publicationType}
                                onChange={(e) => dispatch({type: 'catchPublicationTypeChange', publicationTypeChosen: e.target.value})}
                                fullWidth />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ marginTop: "1rem" }}>
                        <Grid container spacing={2} justifyContent={'space-between'}>
                            <Grid item xs={6}>
                            <TextField
                                required
                                variant="standard"
                                label="Cím"
                                value={state.publicationTitle}
                                onChange={(e) => dispatch({type: 'catchPublicationTitleChange', publicationTitleChosen: e.target.value})}                    
                                fullWidth
                                sx={{ mb: 2 }}
                            />
                            </Grid>
                            <Grid item xs={5}>
                                <Grid container direction="row" >
                                    <Grid item xs={7}>
                                        <DatePicker 
                                            required
                                            label="Kiadás dátuma" 
                                            defaultValue={dayjs()} 
                                            fullWidth
                                            sx={{ mb: 2 }} 
                                        />
                                    </Grid>
                                    <Grid item xs={5} container justifyContent="flex-end">
                                        <FormHelperText sx={{fontStyle: 'italic', marginLeft:'.5rem'}}>Ha nincs pontos dátum, legyen év-január-1</FormHelperText>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>        

                    <Grid item sx={{ marginTop: "1rem" }}>
                        <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField variant="standard" label="Kiadás helye" 
                            value={state.publicationPlace}
                            onChange={(e) => dispatch({type: 'catchPublicationPlaceChange', publicationPlaceChosen: e.target.value})}
                            fullWidth sx={{ mb: 2 }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField variant="standard" label="Kiadó" 
                            value={state.publicationPublisher}
                            onChange={(e) => dispatch({type: 'catchPublicationPublisherChange', publicationPublisherChosen: e.target.value})}
                            fullWidth sx={{ mb: 2 }} />
                        </Grid>
                        </Grid>
                    </Grid>        

                    <Grid item sx={{ marginTop: "1rem" }}>
                        <TextField variant="outlined"  multiline rows= {10} label="Teljes hivatkozás" 
                        value={state.publicationFullReference}
                        onChange={(e) => dispatch({type: 'catchPublicationFullReferenceChange', publicationFullReferenceChosen: e.target.value})}
                        fullWidth sx={{ mb: 2 }} />
                    </Grid>
                    <Grid item sx={{ marginTop: "1rem" }}>                        
                        {SubmitButtonDisplay()}                        
                        <Button variant="contained" onClick={handleReset} sx={{ ml: 2 }}>
                            Visszaállítás
                        </Button>
                    </Grid>
                    </Grid>
                </form>
                
            </Modal>
            <Snackbar
                open={state.openSnack}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                >
                <Alert severity="success">
                    A publikációt sikeresen hozzáadtad!
                </Alert>
            </Snackbar>
            <AddScientist open={isAddScientistModalOpen} onClose={() => setAddScientistModalOpen(false)} />
        </LocalizationProvider>
    );
}

export default AddPublication;