import React, { useEffect,  useRef, useMemo, useContext } from 'react';  
import {useNavigate} from "react-router-dom";
import Axios from "axios"
import { useImmerReducer } from 'use-immer';
import {Grid,  Snackbar, Typography, Button,  TextField, 
    FormControlLabel, Checkbox, Alert} from "@mui/material";

import { MapContainer, TileLayer, Marker, useMap, Polygon, } from 'react-leaflet'
import L from 'leaflet';

import StateContext from "../../Contexts/StateContext";

import { areaOptions, AranyosszekOptions, CsikszekOptions, GyergyoszekOptions, KaszonfiuszekOptions, KereszturfiuszekOptions,
     KezdiszekOptions, MarosszekOptions, MiklosvarfiuszekOptions, OrbaiszekOptions, SepsiszekOptions, UdvarhelyszekOptions,
      } from "../../Constants/GeographicConstants";

import {listingTypeOptions, propertyStatusOptions, rentalFrequencyOptions } from "../../Constants/IngatlanOptions";



const defaultIcon = new L.Icon({
    iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png',  
    iconSize: [25, 41],  
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

//Polygon coordinates of civil parishes
const Aranyosgyéres = [];
const Torockó = [];
const Torockószentgyörgy = [];

function AddProperty() {
    const {config, environment } = require('../../index');
    const navigate = useNavigate()
    const GlobalState = useContext(StateContext) 
    const userId = GlobalState.userId;
   

    const initialState = {
        //form fields
        titleValue: '',
        listingTypeValue: '', 
        descriptionValue: '',
        areaValue: '',
        boroughValue: '',
        latitudeValue: '',
        longitudeValue: '',
        propertyStatusValue: '',       
        rentFrequencyValue: '',
        roomsValue: '',
        furnishedValue: false,
        poolValue: false,
        elevatorValue: false,
        airconditioningValue: false,
        parkingValue: false,
        wellnessValue: false,
        kitchenValue: false,
        breakfastValue: false,
        petsAllowedValue: false,
        wifiValue: false,
        airportTransferValue: false,
        vacationTicketValue: false,
        picture1Value: '',
        picture2Value: '',
        picture3Value: '',
        picture4Value: '',
        picture5Value: '',
        picture6Value: '',
        picture7Value: '',
        picture8Value: '',
        picture9Value: '',
        picture10Value: '', 
        mapInstance: null,

        markerPosition:{
            lat: "45.8643",
            lng: "25.7848",
        },
        uploadedPictures: [],
        sendRequest: 0,
        seller: "",
        userProfile:{
            companyName: '',
            phoneNumber: '',
        },
        openSnack: false,
        disabledAddPropertyBtn: false,
        //form errors
        titleErrors:{
            hasError: false,
            errorMessage: '',
        },
        listingTypeErrors:{
            hasError: false,
            errorMessage: '',
        },
        propertyStatusErrors:{
            hasError: false,
            errorMessage: '',
        },       
        priceErrors:{
            hasError: false,
            errorMessage: '',
        },
        areaErrors:{
            hasError: false,
            errorMessage: '',
        },
        boroughErrors:{
            hasError: false,
            errorMessage: '',
        },
        
       };
   
       function ReducerFunction(draft, action){
           switch(action.type){
               case 'catchTitleChange':
                  draft.titleValue = action.titleChosen;
                  draft.titleErrors.hasError = false;
                  draft.titleErrors.errorMessage = '';
                  break;
                case 'catchListingTypeChange':
                    draft.listingTypeValue = action.listingTypeChosen;
                    draft.listingTypeErrors.hasError = false;
                    draft.listingTypeErrors.errorMessage = '';
                    break;
                case 'catchDescriptionChange':
                    draft.descriptionValue = action.descriptionChosen;
                    break;
                case 'catchAreaChange':
                    draft.areaValue = action.areaChosen;
                    draft.areaErrors.hasError = false;
                    draft.areaErrors.errorMessage = '';
                    break;
                case 'catchBoroughChange':
                    draft.boroughValue = action.boroughChosen;
                    draft.boroughErrors.hasError = false;
                    draft.boroughErrors.errorMessage = '';
                    break;
                case 'catchLatitudeChange':
                    draft.latitudeValue = action.latitudeChosen;
                    break;
                case 'catchLongitudeChange':
                    draft.longitudeValue = action.longitudeChosen;
                    break;
                case 'catchPropertyStatusChange':
                    draft.propertyStatusValue = action.propertyStatusChosen;
                    draft.propertyStatusErrors.hasError = false;
                    draft.propertyStatusErrors.errorMessage = '';
                    break;                
                case 'catchPriceChange':
                    draft.priceValue = action.priceChosen;
                    draft.priceErrors.hasError = false;
                    break;
                case 'catchRentFrequencyChange':
                    draft.rentFrequencyValue = action.rentFrequencyChosen;
                    break;
                case 'catchRoomsChange':
                    draft.roomsValue = action.roomsChosen;
                    break;
                case 'catchFurnishedChange':
                    draft.furnishedValue = action.furnishedChosen;
                    break;
                case 'catchPoolChange':
                    draft.poolValue = action.poolChosen;
                    break;
                case 'catchElevatorChange':
                    draft.elevatorValue = action.elevatorChosen;
                    break;
                case 'catchAirconditioningChange':
                    draft.airconditioningValue = action.airConditioningChosen;
                    break;
                case 'catchParkingChange':
                    draft.parkingValue = action.parkingChosen;
                    break;
                case 'catchWellnessChange':
                    draft.wellnessValue = action.wellnessChosen;
                    break;
                case 'catchKitchenChange':
                    draft.kitchenValue = action.kitchenChosen;
                    break;
                case 'catchBreakfastChange':
                    draft.breakfastValue = action.breakfastChosen;
                    break;
                case 'catchPetsAllowedChange':
                    draft.petsAllowedValue = action.petsAllowedChosen;
                    break;
                case 'catchWifiChange':
                    draft.wifiValue = action.wifiChosen;
                    break;
                case 'catchAirportTransferChange':
                    draft.airportTransferValue = action.airportTransferChosen;
                    break;
                case 'catchVacationTicketChange':
                    draft.vacationTicketValue = action.vacationTicketChosen;
                    break;
                case 'catchPicture1Change':
                    draft.picture1Value = action.picture1Chosen;
                    break;
                case 'catchPicture2Change':
                    draft.picture2Value = action.picture2Chosen;
                    break;
                case 'catchPicture3Change':
                    draft.picture3Value = action.picture3Chosen;
                    break;
                case 'catchPicture4Change':
                    draft.picture4Value = action.picture4Chosen;
                    break;
                case 'catchPicture5Change':
                    draft.picture5Value = action.picture5Chosen;
                    break;
                case 'catchPicture6Change':
                    draft.picture6Value = action.picture6Chosen;
                    break;
                case 'catchPicture7Change':
                    draft.picture7Value = action.picture7Chosen;
                    break;
                case 'catchPicture8Change':
                    draft.picture8Value = action.picture8Chosen;
                    break;
                case 'catchPicture9Change':
                    draft.picture9Value = action.picture9Chosen;
                    break;
                case 'catchPicture10Change':
                    draft.picture10Value = action.picture10Chosen;
                    break;  
                case 'getMap':
                    draft.mapInstance = action.mapData;
                    break;
                case 'changeMarkerPosition':
                    draft.markerPosition.lat = action.changeLatitude;
                    draft.markerPosition.lng = action.changeLongitude;
                    draft.latitudeValue = '';
                    draft.longitudeValue = '';
                    break;
                case 'catchUploadedPictures':
                    draft.uploadedPictures = action.picturesChosen;
                    break;
                case 'changeSendRequest':
                    draft.sendRequest = draft.sendRequest + 1;
                    break; 
                case 'catchUserProfileInfo':
                    draft.userProfile.companyName = action.profileObject.company_name; 
                    draft.userProfile.phoneNumber = action.profileObject.phone_number;
                    break;
                case 'openTheSnack':
                    draft.openSnack = true;
                    break;
                case 'disabledAddPropertyBtn':
                    draft.disabledAddPropertyBtn = true;
                    break;
                case 'allowTheAddPropertyBtn':
                    draft.disabledAddPropertyBtn = false;
                    break;
                case 'catchTitleErrors':
                    if(action.titleChosen.length === 0){
                        draft.titleErrors.hasError = true;
                        draft.titleErrors.errorMessage = "A cím mező nem lehet üres!";
                    }                    
                    break;
                case 'catchListingTypeErrors':
                    if(action.listingTypeChosen.length === 0){
                        draft.listingTypeErrors.hasError = true;
                        draft.listingTypeErrors.errorMessage = "A típus mező nem lehet üres!";
                    }
                    break;
                case 'catchPropertyStatusErrors':
                    if(action.propertyStatusChosen.length === 0){
                        draft.propertyStatusErrors.hasError = true;
                        draft.propertyStatusErrors.errorMessage = "A státusz mező nem lehet üres!";
                    }
                    break;
                case 'catchPriceErrors':
                    if(action.priceChosen.length === 0){
                        draft.priceErrors.hasError = true;
                        draft.priceErrors.errorMessage = "Az ár mező nem lehet üres!";
                    }
                    break;
                case 'catchAreaErrors':
                    if(action.areaChosen.length === 0){
                        draft.areaErrors.hasError = true;
                        draft.areaErrors.errorMessage = "A terület mező nem lehet üres!";
                    }
                    break;
                case 'catchBoroughErrors':
                    if(action.boroughChosen.length === 0){
                        draft.boroughErrors.hasError = true;
                        draft.boroughErrors.errorMessage = "A város mező nem lehet üres!";
                    }
                    break;
                case 'emptyTitle':
                    draft.titleErrors.hasError = true;
                    draft.titleErrors.errorMessage = "A cím mező nem lehet üres!";
                    break;

                case 'emptyListingType':
                    draft.listingTypeErrors.hasError = true;
                    draft.listingTypeErrors.errorMessage = "A típus mező nem lehet üres!";
                    break;
                case 'emptyPropertyStatus':
                    draft.propertyStatusErrors.hasError = true;
                    draft.propertyStatusErrors.errorMessage = "A státusz mező nem lehet üres!";
                    break;
                case 'emptyPrice':
                    draft.priceErrors.hasError = true;
                    draft.priceErrors.errorMessage = "Az ár mező nem lehet üres!";
                    break;
                case 'emptyArea':
                    draft.areaErrors.hasError = true;
                    draft.areaErrors.errorMessage = "A terület mező nem lehet üres!";
                    break;
                case 'emptyBorough':
                    draft.boroughErrors.hasError = true;
                    draft.boroughErrors.errorMessage = "A város mező nem lehet üres!";
                    break;
                    
           }
       }
    const [state, dispatch] = useImmerReducer(ReducerFunction, initialState)

    function MapComponent({ dispatch }) {
    const map = useMap();
    useEffect(() => {
        dispatch({ type: 'getMap', mapData: map });
    }, [dispatch, map]);
    return null;
}

    useEffect(()=>{
        if(state.boroughValue === "Aranyosgyéres"){
            state.mapInstance.flyTo([46.0733, 25.6], 12 );  
            dispatch({type: 'changeMarkerPosition', changeLatitude: 46.0833, changeLongitude: 25.6});          
        }
        else if (state.boroughValue === "Torockó"){
            state.mapInstance.flyTo([46.0833, 25.7], 12 );
            dispatch({type: 'changeMarkerPosition', changeLatitude: 46.0833, changeLongitude: 25.6});
        }
        else if (state.boroughValue === "Torockószentgyörgy"){
            state.mapInstance.flyTo([46.0633, 25.8], 12 );
            dispatch({type: 'changeMarkerPosition', changeLatitude: 46.0833, changeLongitude: 25.6});
        }
        
    }, [state.boroughValue]);

    function BoroughDisplay(){
        if (state.boroughValue === "Aranyosgyéres"){
            return <Polygon pathOptions={{color: 'red'}} positions={Aranyosgyéres}/>
        }
        else if (state.boroughValue === "Torockó"){
            return <Polygon pathOptions={{color: 'red'}} positions={Torockó}/>
        }
        else if (state.boroughValue === "Torockószentgyörgy"){
            return <Polygon pathOptions={{color: 'red'}} positions={Torockószentgyörgy}/>
        }   
        
    }    

    const markerRef = useRef(null);
    const eventHandlers = useMemo(
    () => ({
        dragend() {
        const marker = markerRef.current;
        if (marker != null) {
            const latLng = marker.getLatLng();
            const latitude = latLng?.lat || 0;
            const longitude = latLng?.lng || 0;
            dispatch({ type: 'catchLatitudeChange', latitudeChosen: latitude });
            dispatch({ type: 'catchLongitudeChange', longitudeChosen: longitude });
        }
        },
    }),
    [dispatch]
    );
    
    useEffect(() => {
        if (state.mapInstance) {
          const marker = markerRef.current;
          const updateMarkerPosition = () => {
            if (marker) {
              const latLng = marker.getLatLng();
              if (latLng) {
                const latitude = latLng.lat || 0;
                const longitude = latLng.lng || 0;
                dispatch({ type: 'catchLatitudeChange', latitudeChosen: latitude });
                dispatch({ type: 'catchLongitudeChange', longitudeChosen: longitude });
              }
            }
          };
      
          marker.addEventListener('dragend', updateMarkerPosition);
      
          return () => {
            // Cleanup event listener when component unmounts
            marker.removeEventListener('dragend', updateMarkerPosition);
          };
        }
      }, [state.mapInstance, dispatch]);
      

    useEffect(() => {       
    }, [state.latitudeValue, state.longitudeValue]);  
   
    useEffect(()=>{ 
        if(state.uploadedPictures[0]){
            dispatch({type: 'catchPicture1Change', picture1Chosen: state.uploadedPictures[0],        
            });
        }
    }, [state.uploadedPictures[0]]);

    useEffect(()=>{
        if(state.uploadedPictures[1]){
            dispatch({type: 'catchPicture2Change', picture2Chosen: state.uploadedPictures[1],        
            });
        } 
    }, [state.uploadedPictures[1]]);

    useEffect(()=>{
        if(state.uploadedPictures[2]){
            dispatch({type: 'catchPicture3Change', picture3Chosen: state.uploadedPictures[2],
            });
        }
    }, [state.uploadedPictures[2]]);
    
    useEffect(()=>{
        if(state.uploadedPictures[3]){
            dispatch({type: 'catchPicture4Change', picture4Chosen: state.uploadedPictures[3],
            });
        }
    }, [state.uploadedPictures[3]]);

    useEffect(()=>{
        if(state.uploadedPictures[4]){
            dispatch({type: 'catchPicture5Change', picture4Chosen: state.uploadedPictures[4],
            });
        }
    }, [state.uploadedPictures[4]]);

    useEffect(()=>{
        if(state.uploadedPictures[5]){
            dispatch({type: 'catchPicture6Change', picture4Chosen: state.uploadedPictures[5],
            });
        }
    }, [state.uploadedPictures[5]]);

    useEffect(()=>{
        if(state.uploadedPictures[6]){
            dispatch({type: 'catchPicture7Change', picture4Chosen: state.uploadedPictures[6],
            });
        }
    }, [state.uploadedPictures[6]]);

    //request to get profile info
    useEffect(() => {
        async function getProfileInfo() {
            try {
                const profileEndpoint = config[environment].getProfileEndpoint(userId); 
                const response = await Axios.get(profileEndpoint);                  
                dispatch({type: 'catchUserProfileInfo', profileObject: response.data});
            } catch (e) {
                console.log(e.response);
            }

        }
        getProfileInfo();
    }, [config, environment, dispatch, userId]);

    function FormSubmit(e){
        e.preventDefault(); 
        if(!state.titleErrors.hasError && !state.listingTypeErrors.hasError && !state.propertyStatusErrors.hasError &&
            !state.priceErrors.hasError && !state.areaErrors.hasError && !state.boroughErrors.hasError 
            && state.latitudeValue  && state.longitudeValue ) 
            {     
            dispatch({type:'changeSendRequest'});
            dispatch({type:'disabledAddPropertyBtn'});
            }
        else if (state.titleValue === ''){
            dispatch({type: 'emptyTitle'});
            window.scrollTo(0, 0);
        }
        else if (state.listingTypeValue === ''){
            dispatch({type: 'emptyListingType'});
            window.scrollTo(0, 0);
        }
        else if (state.propertyStatusValue === ''){
            dispatch({type: 'emptyPropertyStatus'});
            window.scrollTo(0, 0);
        }
        else if (state.priceValue === ''){
            dispatch({type: 'emptyPrice'});
            window.scrollTo(0, 0);
        }   
        else if (state.areaValue === ''){
            dispatch({type: 'emptyArea'});
            window.scrollTo(0, 0);
        }
        else if (state.boroughValue === ''){
            dispatch({type: 'emptyBorough'});
            window.scrollTo(0, 0);
        }
        

    }

    useEffect(() =>{
        if(state.sendRequest){  
            const data = new FormData();
            data.append('title', state.titleValue);
            data.append('listing_type', state.listingTypeValue);
            data.append('description', state.descriptionValue);
            data.append('area', state.areaValue);
            data.append('borough', state.boroughValue);
            data.append('latitude', state.latitudeValue);
            data.append('longitude', state.longitudeValue);
            data.append('property_status', state.propertyStatusValue);
            
            data.append('price', state.priceValue);
            data.append('rent_frequency', state.rentFrequencyValue);
            data.append('rooms', state.roomsValue);
            data.append('furnished', state.furnishedValue);
            data.append('pool', state.poolValue);
            data.append('elevator', state.elevatorValue);
            data.append('airconditioning', state.airconditioningValue);
            data.append('parking', state.parkingValue);
            data.append('wellness', state.wellnessValue);
            data.append('kitchen', state.kitchenValue);
            data.append('breakfast', state.breakfastValue);
            data.append('pets_allowed', state.petsAllowedValue);
            data.append('wifi', state.wifiValue);
            data.append('airport_transfer', state.airportTransferValue);
            data.append('vacation_ticket', state.vacationTicketValue);
            data.append('picture1', state.picture1Value);
            data.append('picture2', state.picture2Value);
            data.append('picture3', state.picture3Value);
            data.append('picture4', state.picture4Value);
            data.append('picture5', state.picture5Value);
            data.append('picture6', state.picture6Value);
            data.append('picture7', state.picture7Value);
            data.append('picture8', state.picture8Value);
            data.append('picture9', state.picture9Value);
            data.append('picture10', state.picture10Value);
            data.append('uploaded_pictures', state.uploadedPictures);
            data.append('seller', GlobalState.userId);
           
            async function AddProperty(){
                try{
                    const createListingEndpoint = config[environment].createListingEndpoint;
                    const response =  await Axios.post(createListingEndpoint, data);                    
                    dispatch({type: 'openTheSnack'});
                    //useEffects for navigate
                } catch (e){
                    dispatch({
                        type: 'allowTheAddPropertyBtn',
                       })
                }
            }
            AddProperty();
        }
    }, [config, environment, state.sendRequest]);

    function PriceDisplay(){
        if(state.propertyStatusValue === "Kiadó" && state.rentFrequencyValue === "Day"){
            return "Napi bér*";
        }
        else if(state.propertyStatusValue === "Kiadó" && state.rentFrequencyValue === "Week"){
            return "Heti bér*";
        }
        else if(state.propertyStatusValue === "Kiadó" && state.rentFrequencyValue === "Month"){
            return "Havi bér*";
        }
        else {
            return "Ár*";
        }
    }

    function SubmitButtonDisplay(){
        if(GlobalState.userIsLogged && state.userProfile.companyName !== null && state.userProfile.companyName !== "" && 
            state.userProfile.phoneNumber !== null && state.userProfile.phoneNumber !== "" ){
            return (<Button variant='contained' fullWidth type='submit' disabled={state.disabledAddPropertyBtn} >BEKÜLDÉS</Button>);
        } 
        else if(GlobalState.userIsLogged && (state.userProfile.companyName === null || state.userProfile.companyName === "" || 
            state.userProfile.phoneNumber === null || state.userProfile.phoneNumber === "")){
            return (<Button variant='outlined' fullWidth onClick={()=> navigate("/profile")}  >TÖLTSD KI A PROFILOD OBJEKTUMOK HOZZAADÁSÁHOZ</Button>);
        }
        else if (!GlobalState.userIsLogged){
            return (<Button variant='outlined' fullWidth onClick={()=> navigate("/login")}  >JELENTKEZZ BE OBJEKTUMOK HOZZAADÁSÁHOZ</Button>);
        }
    }

    useEffect(()=>{
        if (state.openSnack){
          setTimeout(()=>{
            navigate('/listings');
          }, 1500)
        }
      }, [state.openSnack]) //will watch for changes in state.openSnack

    return (
        <div>
            
        <div style={{width:'75%', marginLeft: "auto", marginRight: "auto", marginTop: "3rem", border:"2px solid #105b79", padding: '3rem' }}>
            <form onSubmit={FormSubmit}>
                <Grid item container justifyContent="center">
                    <Typography variant='h4'>TÉRKÉPOBJEKTUM HOZZÁADÁSA</Typography>                   
                </Grid>
                
                <Grid item container xs={4} sx={{marginTop: "1rem", marginLeft: "auto", marginRight: "auto"}}>
                   {SubmitButtonDisplay()}
                </Grid>               
                <Grid item container xs={5} sx={{marginTop: "1rem"}}>
                    <TextField  id="title" label="Név*" variant="standard" fullWidth value={state.titleValue}
                     onChange={(e)=>dispatch({type: 'catchTitleChange', titleChosen: e.target.value})}
                     onBlur={(e)=>dispatch({type: 'catchTitleErrors', titleChosen: e.target.value})}
                     error={state.titleErrors.hasError ? true : false}
                     helperText={state.titleErrors.errorMessage}
                     />
                </Grid>               
                
               
                <Grid item container justifyContent={'space-between'}>
                    <Grid item xs={5} sx={{marginTop: "1rem"}}>
                        <TextField id="area" label="Szék*" variant="standard" fullWidth value={state.areaValue}
                        onChange={(e)=>dispatch({type: 'catchAreaChange', areaChosen: e.target.value})}
                        onBlur={(e)=>dispatch({type: 'catchAreaErrors', areaChosen: e.target.value})}
                        error={state.areaErrors.hasError ? true : false}
                        helperText={state.areaErrors.errorMessage}
                        select SelectProps={{ native: true, }}
                        >  
                        {areaOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}     
                        </TextField>
                    </Grid>
                    <Grid item  xs={5} sx={{marginTop: "1rem"}}>
                        <TextField 
                        id="borough" 
                        label="Település*" 
                        variant="standard" 
                        fullWidth 
                        value={state.boroughValue}
                        onChange={(e)=>dispatch({type: 'catchBoroughChange', boroughChosen: e.target.value})}
                        onBlur={(e)=>dispatch({type: 'catchBoroughErrors', boroughChosen: e.target.value})}
                        error={state.boroughErrors.hasError ? true : false}
                        helperText={state.boroughErrors.errorMessage}
                        select 
                        SelectProps={{ native: true, }}>
                            {state.areaValue === 'Aranyosszék' ? AranyosszekOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                            )) : ""} 
                            {state.areaValue === 'Csíkszék' ? CsikszekOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                {option.label}
                                </option>
                            )) : ""}
                            {state.areaValue === 'Gyergyószék' ? GyergyoszekOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>)) : ""}
                            {state.areaValue === 'Kászonfiúszék' ? KaszonfiuszekOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>)) : ""}
                            {state.areaValue === 'Keresztúrfiúszék' ? KereszturfiuszekOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>)) : ""}
                            {state.areaValue === 'Kézdiszék' ? KezdiszekOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>)) : ""}
                            {state.areaValue === 'Marosszék' ? MarosszekOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>)) : ""}
                            {state.areaValue === 'Miklósvárfiúszék' ? MiklosvarfiuszekOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>)) : ""}
                            {state.areaValue === 'Orbaiszék' ? OrbaiszekOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>)) : ""}                                
                            {state.areaValue === 'Sepsiszék' ? SepsiszekOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>)) : ""}
                            {state.areaValue === 'Udvarhelyszék' ? UdvarhelyszekOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>)) : ""}
                        </TextField>
                    </Grid>
                </Grid>

                {/*Map*/}

                <Grid item sx={{height: '2rem', marginTop: "1rem"}} >
                   {state.latitudeValue && state.longitudeValue ? (<Alert severity="success">A helytű helyzete: Szélesség: {state.latitudeValue.toFixed(6)}, Hosszúság: {state.longitudeValue.toFixed(6)}</Alert> ):(
                    <Alert severity="warning">"Mielőtt az adatlapot beküldenéd  helyezd a helytűt a megfelelő helyre!"</Alert>)}
                </Grid>    

                <Grid item container sx={{height: '35rem', marginTop: "1rem"}}>
                    <MapContainer center={[45.8643, 25.7848]} zoom={16} scrollWheelZoom={true}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <MapComponent dispatch={dispatch}/>   
                        {BoroughDisplay()}
                        <Marker
                            draggable
                            eventHandlers={eventHandlers}
                            position={state.markerPosition || [0, 0]}
                            ref={markerRef}
                            icon={defaultIcon}
                            >                                           
                        </Marker>            
                    </MapContainer>
                </Grid>
                
                <Grid item container justifyContent={'space-between'} sx={{marginBottom: "2rem"}}>
                    <Grid item  xs={5} sx={{marginTop: "1rem"}}>
                        <TextField id="latitude" label="Földrajzi szélesség" variant="standard" fullWidth value={state.latitudeValue}
                        onChange={(e)=>dispatch({type: 'catchLatitudeChange', latitudeChosen: e.target.value})}/>
                    </Grid>
                    <Grid item  xs={5} sx={{marginTop: "1rem"}}>
                        <TextField id="longitude" label="Földrajzi hosszúság" variant="standard" fullWidth value={state.longitudeValue}
                        onChange={(e)=>dispatch({type: 'catchLongitudeChange', longitudeChosen: e.target.value})}/>
                    </Grid>
                </Grid>
                
                <Grid item container justifyContent={'space-between'}>                   
                    <Grid item xs={5} sx={{marginTop: "1rem"}}>
                        <TextField 
                        id="listingType" 
                        label="Az ingatlan típusa*" 
                        variant="standard" 
                        fullWidth 
                        value={state.listingTypeValue}
                        onChange={(e)=>dispatch({type: 'catchListingTypeChange', listingTypeChosen: e.target.value})}
                        onBlur={(e)=>dispatch({type: 'catchListingTypeErrors', listingTypeChosen: e.target.value})}
                        error={state.listingTypeErrors.hasError ? true : false}
                        helperText={state.listingTypeErrors.errorMessage}
                        select 
                        SelectProps={{ native: true, }}>
                            {listingTypeOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                {option.label}
                                </option>
                            ))} 
                        </TextField>
                    </Grid>    
                    <Grid item xs={5} sx={{marginTop: "1rem"}}>
                        <TextField 
                        id="propertyStatus" 
                        label="Az ingatlan státusza*" 
                        variant="standard" 
                        fullWidth 
                        value={state.propertyStatusValue}
                        onChange={(e)=>dispatch({type: 'catchPropertyStatusChange', propertyStatusChosen: e.target.value})}
                        onBlur={(e)=>dispatch({type: 'catchPropertyStatusErrors', propertyStatusChosen: e.target.value})}
                        error={state.propertyStatusErrors.hasError ? true : false}
                        helperText={state.propertyStatusErrors.errorMessage}
                        select 
                        SelectProps={{ native: true, }}>
                            {propertyStatusOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                {option.label}
                                </option>
                            ))} 
                        </TextField>
                    </Grid>
                </Grid>              

                <Grid item container justifyContent={'space-between'}>                    
                    <Grid item xs={5} sx={{marginTop: "1rem"}}>                    
                    <TextField 
                    id="rentFrequency" 
                    label="Bérlési gyakoriság*" 
                    variant="standard"
                    disabled={state.propertyStatusValue === 'Eladó' ? true : false} 
                    fullWidth 
                    value={state.rentFrequencyValue}
                    onChange={(e)=>dispatch({type: 'catchRentFrequencyChange', rentFrequencyChosen: e.target.value})}
                    select 
                    SelectProps={{ native: true, }}>
                        {rentalFrequencyOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                            {option.label}
                            </option>
                        ))} 
                    </TextField>
                    </Grid>
                    <Grid item xs={5} sx={{marginTop: "1rem"}}>
                        <TextField 
                        id="Price*"
                        type="number" 
                        label={PriceDisplay()}  
                        variant="standard" 
                        fullWidth 
                        value={state.priceValue}
                        onChange={(e)=>dispatch({type: 'catchPriceChange', priceChosen: e.target.value})}
                        onBlur={(e)=>dispatch({type: 'catchPriceErrors', priceChosen: e.target.value})}
                        error={state.priceErrors.hasError ? true : false}
                        helperText={state.priceErrors.errorMessage}/>
                    </Grid>
                </Grid>

                
                <Grid item container sx={{marginTop: "1rem"}}>
                    <TextField id="description" label="Leírás" variant="outlined" 
                        multiline rows= {10}
                        fullWidth value={state.descriptionValue}
                        onChange={(e)=>dispatch({type: 'catchDescriptionChange', titleChosen: e.target.value})}/>
                </Grid>

                {state.listingTypeValue === 'Office'  ? ("") : (
                <Grid item container sx={{marginTop: "1rem"}}>
                    <TextField id="rooms" type="number" label="Szobák száma" variant="standard" fullWidth value={state.roomsValue}
                     onChange={(e)=>dispatch({type: 'catchRoomsChange', roomsChosen: e.target.value})}/>
                </Grid>
                )}
                <Grid item container justifyContent={'space-between'}>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.furnishedValue} 
                        onChange={(e)=>dispatch({type: 'catchFurnishedChange', furnishedChosen: e.target.checked})}/>} label="Bútorozott" />
                    </Grid>
                    <Grid item  sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.poolValue} 
                        onChange={(e)=>dispatch({type: 'catchPoolChange', poolChosen: e.target.checked})}/>} label="Medence" />
                    </Grid>
                    <Grid item  sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.elevatorValue} 
                        onChange={(e)=>dispatch({type: 'catchElevatorChange', elevatorChosen: e.target.checked})}/>} label="Felvonó" />
                    </Grid>
                    <Grid item  sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.airconditioningValue} 
                        onChange={(e)=>dispatch({type: 'catchAirconditioningChange', airConditioningChosen: e.target.checked})}/>} label="Légkondicionálás" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.parkingValue} 
                        onChange={(e)=>dispatch({type: 'catchParkingChange', parkingChosen: e.target.checked})}/>} label="Parkírozó" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.wellnessValue} 
                        onChange={(e)=>dispatch({type: 'catchWellnessChange', wellnessChosen: e.target.checked})}/>} label="Wellness" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.kitchenValue} 
                        onChange={(e)=>dispatch({type: 'catchKitchenChange', kitchenChosen: e.target.checked})}/>} label="Konyha" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.breakfastValue} 
                        onChange={(e)=>dispatch({type: 'catchBreakfastChange', breakfastChosen: e.target.checked})}/>} label="Reggeli" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.petsAllowedValue} 
                        onChange={(e)=>dispatch({type: 'catchPetsAllowedChange', petsAllowedChosen: e.target.checked})}/>} label="Háziállatok engedélyezettek" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.wifiValue} 
                        onChange={(e)=>dispatch({type: 'catchWifiChange', wifiChosen: e.target.checked})}/>} label="Wifi" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.airportTransferValue} 
                        onChange={(e)=>dispatch({type: 'catchAirportTransferChange', airportTransferChosen: e.target.checked})}/>} label="Repülőtéri kiszállítás" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.vacationTicketValue} 
                        onChange={(e)=>dispatch({type: 'catchVacationTicketChange', vacationTicketChosen: e.target.checked})}/>} label="Vakációs jegyek" />
                    </Grid>
                </Grid>

                <Grid item container xs={12} sx={{marginTop: "1rem"}}>
                    <ul>
                        {state.picture1Value ? <li>{state.picture1Value.name}</li> : ""}
                        {state.picture2Value ? <li>{state.picture2Value.name}</li> : ""}
                        {state.picture3Value ? <li>{state.picture3Value.name}</li> : ""}
                        {state.picture4Value ? <li>{state.picture4Value.name}</li> : ""}
                        {state.picture5Value ? <li>{state.picture5Value.name}</li> : ""}
                        {state.picture6Value ? <li>{state.picture6Value.name}</li> : ""}
                        {state.picture7Value ? <li>{state.picture7Value.name}</li> : ""}
                        {state.picture8Value ? <li>{state.picture8Value.name}</li> : ""}
                        {state.picture9Value ? <li>{state.picture9Value.name}</li> : ""}
                        {state.picture10Value ? <li>{state.picture10Value.name}</li> : ""}
                    </ul>
                    
                </Grid>
 
                <Grid item container xs={3} sx={{marginTop: "1rem", marginLeft: "auto", marginRight: "auto" }}>
                    <Button 
                        variant='contained'
                        component="label" 
                        fullWidth 
                        sx={{backgroundColor: "green", 
                        color: "white",
                        fontSize: "0.8rem"}} >
                            KÉP FELTÖLTÉSE (MAX 10)
                            <input 
                            type="file" 
                            multiple accept="image/png, image/gif, image/jpg, image/svg" 
                            style={{display: "none"}}
                            hidden
                            onChange = {(e)=>dispatch({type: 'catchUploadedPictures', picturesChosen: e.target.files})}
                            />
                        </Button>
                </Grid>

                <Grid item container xs={4} sx={{marginTop: "1rem", marginLeft: "auto", marginRight: "auto"}}>
                   {SubmitButtonDisplay()}
                </Grid>
            </form>         
            
            <Snackbar
                open={state.openSnack}         
                
                message="Sikeresen hozzáadtad a térképobjektumot!"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}                
                />            
            </div>
        </div>
    )
}   

export default AddProperty;