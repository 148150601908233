import React, {useEffect, useState, useContext} from "react";
import Axios from 'axios';
import {useNavigate} from "react-router-dom";
import { Button, Typography, AppBar, Toolbar, Menu, MenuItem, Snackbar, ThemeProvider,  responsiveFontSizes } from '@mui/material';
import StateContext from '../Contexts/StateContext';
import DispatchContext from '../Contexts/DispatchContext';
import {createTheme} from '@mui/material/styles';
import logo from '../Assets/populiathyleregis.png'
import AddLocationIcon from '@mui/icons-material/AddLocationAlt';
import MapIcon from '@mui/icons-material/Map';


let theme = createTheme();
theme = responsiveFontSizes(theme);

function Header() {
  const { config, environment } = require('../index');
  const navigate = useNavigate();
  const GlobalState = useContext(StateContext);
  const GlobalDispatch = useContext(DispatchContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [mapAnchorEl, setMapAnchorEl] = useState(null);
  const mapOpen = Boolean(mapAnchorEl);

  const [marketAnchorEl, setMarketAnchorEl] = useState(null);
  const marketOpen = Boolean(marketAnchorEl);

  const [accommodationAnchorEl, setAccommodationAnchorEl] = useState(null);
  const accommodationOpen = Boolean(accommodationAnchorEl);

  const [companiesMastersAnchorEl, setCompaniesMastersAnchorEl] = useState(null);
  const companiesMastersOpen = Boolean(companiesMastersAnchorEl);

  const [sightsAnchorEl, setSightsAnchorEl] = useState(null);
  const sightsOpen = Boolean(sightsAnchorEl);

  const [addObjectsAnchorEl, setAddObjectsAnchorEl] = useState(null);
  const addObjectsOpen = Boolean(addObjectsAnchorEl);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMapClick = (event) => {
    setMapAnchorEl(event.currentTarget);
  };

  const handleMarketClick = (event) => {
    setMarketAnchorEl(event.currentTarget);
  };

  const handleAccommodationClick = (event) => {
    setAccommodationAnchorEl(event.currentTarget);
  };

  const handleCompaniesMastersClick = (event) => {
    setCompaniesMastersAnchorEl(event.currentTarget); 
  };

  const handleSightsClick = (event) => {
    setSightsAnchorEl(event.currentTarget);
  };

  const handleAddObjectsClick = (event) => {
    setAddObjectsAnchorEl(event.currentTarget);
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMapClose = () => {
    setMapAnchorEl(null);
  };

  const handleMarketClose = () => {
    setMarketAnchorEl(null);
  };

  const handleAccommodationClose = () => {
    setAccommodationAnchorEl(null);
  };

  const handleCompaniesMastersClose = () => {
    setCompaniesMastersAnchorEl(null);
  };

  const handleSightsClose = () => {
    setSightsAnchorEl(null);
  };

  const handleAddObjectsClose = () => {
    setAddObjectsAnchorEl(null);  
  };

  const [openSnack, setOpenSnack] = useState(false)

  async function handleLogout(){
    setAnchorEl(null);
    const confrimLogout = window.confirm("Are you sure you want to leave?");
    if (confrimLogout){
      try{
        const LogoutEndpoint = config[environment].logoutEndpoint;
        const response = await Axios.post(LogoutEndpoint, GlobalState.userToken, 
      {headers: {Authorization : "Token ".concat(GlobalState.userToken)} }
      );      
      GlobalDispatch({type: "logout"});
      setOpenSnack(true);
     
      } catch(e){
        console.log(e.response);
      }
    }
  }

  useEffect(()=>{
    if (openSnack){
      setTimeout(()=>{
        navigate(0);
      }, 1500)
    }
  }, [openSnack]) //will watch for changes in state.openSnack

  function handleProfile(){
    setAnchorEl(null);
    navigate("/profile");
  } 

  function handleAccount(){
    setAnchorEl(null);
    navigate("/account");
  } 

  function handleTouristicObjects(){
    setMapAnchorEl(null);
    navigate("/touristicobjects");
  }

  function handleGeologicalObjects(){
    setMapAnchorEl(null);
    navigate("/geologicalobjects");
  }

  function handleFlora(){
    setMapAnchorEl(null);
    navigate("/flora");
  }

  function handleMonuments(){
    setMapAnchorEl(null);
    navigate("/monuments");
  }

  function handleMineralWaters(){
    setMapAnchorEl(null);
    navigate("/mineralwaters");
  }

  return (
    
    <AppBar position="static" sx={{backgroundColor: "#0a4056"}}>
  <ThemeProvider theme={theme}>
      <Toolbar sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row', // For screens larger than or equal to small size (sm), switch back to row layout
          justifyContent: 'space-between',
        },
      }}>
      
        
        <div sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start', marginLeft: '4rem' }}>
          <Button color="inherit" onClick={()=>navigate("/news")} sx={{marginRight: '1rem', textAlign: 'left'}}>SZEMLE</Button>
          <Button color="inherit" onClick={()=>navigate("/szotar")} sx={{marginRight: '1rem', textAlign: 'left'}}>Székely Szótár</Button>
          <Button color="inherit" onClick={()=>navigate("/kutfok")} sx={{marginRight: '1rem', textAlign: 'left'}}>KÚTFŐK</Button>
          <Button color="inherit" onClick={()=>navigate("/academy")} sx={{marginRight: '1rem', textAlign: 'left'}}>AKADÉMIA</Button>
        </div>
        

        <div sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <img src={logo} style={{ width: "5rem", marginTop: '0.5rem' }}/>
        </div>
        <div sx={{ marginLeft: '1rem' }}>
          <Button color="inherit">
            <Typography variant='h4' onClick={() => navigate("/")} sx={{ marginRight: '2rem', textAlign: 'left' }}>
              Székely Nemzet Online
            </Typography>
          </Button>
        </div>

        <div>
          <Button color="inherit" onClick={()=>navigate("/listings")}>
            <MapIcon sx={{fontSize: "2rem"}} />  
          </Button>
        </div>
        
        <Typography variant="h6" component="div" sx={{ textAlign: 'center', marginBottom: '0.5rem', display: 'none', [theme.breakpoints.up('sm')]: { display: 'block' } }}> ::: </Typography>      

        <div sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', marginRight: '4rem' }}>             
              <Button 
                color="inherit" 
                id="sights-button"
                aria-controls={sightsOpen ? 'sights-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={sightsOpen ? 'true' : undefined}
                onClick={handleSightsClick}
                sx={{marginRight: '1rem', textAlign: 'left'}}
              >
                Látnivalók
              </Button>
              <Menu
                id="sights-menu"
                anchorEl={sightsAnchorEl}
                keepMounted
                open={Boolean(sightsAnchorEl)}
                onClose={handleSightsClose}
                MenuListProps={{
                  'aria-labelledby': 'sights-button',
                }}
              >
                <MenuItem onClick={() => { handleSightsClose(); navigate("/epitett-orokseg"); }}>Épített örökség</MenuItem>                
                <MenuItem onClick={() => { handleSightsClose(); navigate("/rovasemlek"); }}>Rovásemlék</MenuItem>
                <MenuItem onClick={() => { handleSightsClose(); navigate("/neprajzi"); }}>Néprajzi</MenuItem>
                <MenuItem onClick={() => { handleSightsClose(); navigate("/egyhazi"); }}>Egyházi</MenuItem>
                <MenuItem onClick={() => { handleSightsClose(); navigate("/foldtani"); }}>Földtani</MenuItem>
                <MenuItem onClick={() => { handleSightsClose(); navigate("/novenyvilag"); }}>Növényvilág</MenuItem>
                <MenuItem onClick={() => { handleSightsClose(); navigate("/gyalogtura"); }}>Gyalogtúra</MenuItem>
                <MenuItem onClick={() => { handleSightsClose(); navigate("/lovastura"); }}>Lovastúra</MenuItem>
              </Menu>
              <Button color="inherit" onClick={()=>navigate("/institutions")} sx={{marginRight: '1rem', textAlign: 'left'}}>Intézmények</Button>


              <Button 
                color="inherit" 
                id="companies-masters-button"
                aria-controls={companiesMastersOpen ? 'companies-masters-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={companiesMastersOpen ? 'true' : undefined}
                onClick={handleCompaniesMastersClick}
                sx={{marginRight: '1rem', textAlign: 'left'}}
              >
                Cégek::Mesterek
              </Button>
              <Menu
                id="companies-masters-menu"
                anchorEl={companiesMastersAnchorEl}
                keepMounted
                open={Boolean(companiesMastersAnchorEl)}
                onClose={handleCompaniesMastersClose}
                MenuListProps={{
                  'aria-labelledby': 'companies-masters-button',
                }}
              >
                <MenuItem onClick={() => { handleCompaniesMastersClose(); navigate("/cegek"); }}>Cégek</MenuItem>                
                <MenuItem onClick={() => { handleCompaniesMastersClose(); navigate("/mesterek"); }}>Mesterek</MenuItem>
                <MenuItem onClick={() => { handleCompaniesMastersClose(); navigate("/napszamosok"); }}>Napszámos</MenuItem>                
              </Menu>
              


              <Button 
                color="inherit" 
                id="accommodation-button"
                aria-controls={accommodationOpen ? 'accommodation-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={accommodationOpen ? 'true' : undefined}
                onClick={handleAccommodationClick}
                sx={{marginRight: '1rem', textAlign: 'left'}}
              >
                Szállás
              </Button>
              <Menu
                id="accommodation-menu"
                anchorEl={accommodationAnchorEl}
                keepMounted
                open={Boolean(accommodationAnchorEl)}
                onClose={handleAccommodationClose}
                MenuListProps={{
                  'aria-labelledby': 'accommodation-button',
                }}
              >
                <MenuItem onClick={() => { handleAccommodationClose(); navigate("/panzio"); }}>Panzió</MenuItem>                
                <MenuItem onClick={() => { handleAccommodationClose(); navigate("/hotel"); }}>Hotel</MenuItem>
                <MenuItem onClick={() => { handleAccommodationClose(); navigate("/kulcsos-haz"); }}>Kulcsos ház</MenuItem>
                <MenuItem onClick={() => { handleAccommodationClose(); navigate("/csur"); }}>Csűr</MenuItem>
                <MenuItem onClick={() => { handleAccommodationClose(); navigate("/satorhely"); }}>Sátorhely</MenuItem>                
              </Menu>
              <Button color="inherit" onClick={()=>navigate("/market")} sx={{marginRight: '1rem', textAlign: 'left'}}>Piactér</Button>



              
        </div>
        <Typography variant="h6" component="div" sx={{ textAlign: 'center', marginBottom: '0.5rem', display: 'none', [theme.breakpoints.up('sm')]: { display: 'block' } }}> ::: </Typography>
        

        <div sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', marginRight: '4rem' }}>  
        
              <Button 
                color="inherit" 
                aria-controls="add-objects"
                aria-haspopup="true"
                onClick={handleAddObjectsClick}
                sx={{marginRight: '1rem', textAlign: 'left'}}
              >
                <AddLocationIcon sx={{ fontSize: '2rem' }}/>
              </Button>            
              <Menu 
                id="add-objects"
                anchorEl={addObjectsAnchorEl}
                onClose={handleAddObjectsClose}
                keepMounted
                open={Boolean(addObjectsAnchorEl)}
                MenuListProps={{
                  'aria-labelledby': 'add-objects',
                  sx: { display: 'flex', justifyContent: 'center' }
                }}
                >
                  <Button 
                    color="inherit" 
                    id="map-object-button"
                    aria-controls={mapOpen ? 'map-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={mapOpen ? 'true' : undefined}
                    onClick={handleMapClick}
                    sx={{marginRight: '1rem', textAlign: 'left'}}
                  >
                    Térképobjektum +
                  </Button>
                  <Menu
                    id="map-menu"
                    anchorEl={mapAnchorEl}
                    keepMounted
                    open={Boolean(mapAnchorEl)}
                    onClose={handleMapClose}
                    MenuListProps={{
                      'aria-labelledby': 'map-object-button',
                    }}
                  >
                    <MenuItem onClick={handleTouristicObjects}>Turisztikai</MenuItem>                
                    <MenuItem onClick={handleGeologicalObjects}>Földtani</MenuItem>
                    <MenuItem onClick={handleFlora}>Flóra</MenuItem>
                    <MenuItem onClick={handleMineralWaters}>Borvíz</MenuItem>
                    <MenuItem onClick={() => { handleMapClose(); navigate("/addproperty"); }}>Épített örökség</MenuItem>
                    <MenuItem onClick={handleMonuments}>Egyéb műemlék</MenuItem>                
                  </Menu>
                <Button 
                  color="inherit" 
                  id="market-button"
                  aria-controls={marketOpen ? 'market-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={marketOpen ? 'true' : undefined}
                  onClick={handleMarketClick}
                  sx={{marginRight: '1rem', textAlign: 'left'}}
                >
                  Piaci hírdetés +
                </Button>
                <Menu
                  id="market-menu"
                  anchorEl={marketAnchorEl}
                  keepMounted
                  open={Boolean(marketAnchorEl)}
                  onClose={handleMarketClose}
                  MenuListProps={{
                    'aria-labelledby': 'market-button',
                  }}
                >
                  <MenuItem onClick={() => { handleMarketClose(); navigate("/ingatlan"); }}>Ingatlan eladás, kiadás</MenuItem>                
                  <MenuItem onClick={() => { handleMarketClose(); navigate("/auto"); }}>Autó</MenuItem>
                  <MenuItem onClick={() => { handleMarketClose(); navigate("/teli-sportfelszereles"); }}>Téli sportfelszerelés</MenuItem>
                  <MenuItem onClick={() => { handleMarketClose(); navigate("/nyari-sportfelszereles"); }}>Nyári sportfelszerelés</MenuItem>
                  <MenuItem onClick={() => { handleMarketClose(); navigate("/sajat-termeny"); }}>Saját termény</MenuItem>                
                </Menu>            

                <Button color="inherit" onClick={()=>navigate("/szekelyszo")} sx={{marginRight: '1rem', textAlign: 'left'}}>Székely szó béküldése</Button>
                
              </Menu>
        </div>

        <Typography variant="h6" component="div" sx={{ textAlign: 'center', marginBottom: '0.5rem', display: 'none', [theme.breakpoints.up('sm')]: { display: 'block' } }}> ::: </Typography>

        <div>
          {GlobalState.userIsLogged ? (
            <Button 
              color="inherit" 
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              style={{ marginRight: '2rem' }}
            >
              {GlobalState.userUsername}
            </Button> 
          ) : (
            <Button 
              color="inherit" 
              onClick={() => navigate("/login")} 
              style={{ marginRight: '2rem' }}
            >
              Bejelentkezés
            </Button>
          )}
            
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={handleAccount}>My account</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu> 
          <div style={{ transform: 'translateY(20px, 20px)' }}>
            <Snackbar
              open={openSnack}
              message="Sikeresen kijelentkeztél!"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
            />
          </div>      
        </div>
    </Toolbar>
  </ThemeProvider>
</AppBar>
      
  )
}

export default Header
