import React, {useContext} from 'react';

import {useNavigate} from "react-router-dom";
import { Typography, } from "@mui/material";


//Contexts
import DispatchContext from "../../Contexts/DispatchContext";
import StateContext from "../../Contexts/StateContext";

function AccountCreated() {  

  const GlobalDispatch = useContext(DispatchContext);
  const GlobalState = useContext(StateContext);



  return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>Köszönjük, hogy feliratkoztál! <br/> 
      A fiók aktiváláshoz kattints az emailben elküldött linkre!</Typography>
    </div>
  );
}
export default AccountCreated