import React, { useState, useEffect, useContext } from 'react';
import {useNavigate} from "react-router-dom";
import StateContext from "../../Contexts/StateContext";
import axios from 'axios';
import AddScientist from './AddScientist';
import AddPublication from './AddPublication';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse,
  Box, Typography, IconButton, Button, Grid } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import EditIcon from '@mui/icons-material/Edit';
import EditScientist from './EditScientist'; 
import EditPublication from './EditPublication'; 

function Academy() {
  const {config, environment } = require('../../index');
  const [centuries, setCenturies] = useState([]);
  const [clickedScientist, setClickedScientist] = useState(null);
  const [scientists, setScientists] = useState([]);
  const [publications, setPublications] = useState([]);
  const [openPublications, setOpenPublications] = useState({});  
  const [openCenturies, setOpenCenturies] = useState([]);
  const [openFields, setOpenFields] = useState([]);
  const [openScientists, setOpenScientists] = useState([]); 
  const [isAddScientistModalOpen, setAddScientistModalOpen] = useState(false); 
  const [isAddPublicationModalOpen, setAddPublicationModalOpen] = useState(false);
  const navigate = useNavigate()
  const GlobalState = useContext(StateContext)  
  const currentUser = GlobalState.userId;
  

  // State variables for controlling the visibility of the modals
  const [isEditScientistOpen, setIsEditScientistOpen] = useState(false);
  const [isEditPublicationOpen, setIsEditPublicationOpen] = useState(false);

  // State variables for holding the current scientist and publication being edited
  const [currentScientist, setCurrentScientist] = useState(null);
  const [currentPublication, setCurrentPublication] = useState(null);

  // Handler for opening the scientist modal
  const handleEditScientist = (scientistId) => {
  const scientist = scientists.find(s => s.scientistid === scientistId);
    setCurrentScientist(scientist);
    setIsEditScientistOpen(true);
  };

  // Handler for opening the publication modal
  const handleEditPublication = (publicationId) => {
    const publication = publications.find(p => p.publicationid === publicationId);
    setCurrentPublication(publication);
    setIsEditPublicationOpen(true);
  };


  function AddContentButtonDisplay() {
    const GlobalState = useContext(StateContext);
    const navigate = useNavigate();  
    const handleAddScientistClick = () => {      
      setAddScientistModalOpen(true);
    };
    const handleAddPublicationClick = () => {    
      setAddPublicationModalOpen(true);
    };
    
  
    return (
      <>
        {GlobalState.userIsLogged ? (
          <>
            <Button variant="contained" color="primary" sx={{ margin: '1rem 0' }} onClick={handleAddScientistClick}>
              Új kutató hozzáadása
            </Button>
            <Button variant="contained" color="primary" sx={{ margin: '1rem 0', marginLeft: '1rem' }} onClick={handleAddPublicationClick}>
              Új publikáció hozzáadása
            </Button>
          </>
        ) : (
          <Button variant="outlined" onClick={() => navigate('/login')}>
            JELENTKEZZ BE Kutatók és Publikációk HOZZAADÁSÁHOZ
          </Button>
        )}
        
      </>
    );
  } 

  const [totalScientists, setTotalScientists] = useState(0); 

  useEffect(() => {
    const scientistsEndpoint = config[environment].scientistsEndpoint;
    axios.get(scientistsEndpoint)
      .then(response => {
        // Group the data by century
        const groupedData = groupByCentury(response.data);
        setCenturies(groupedData);
  
        // Calculate the total number of scientists from the response data
        const totalScientists = response.data.length;
        setTotalScientists(totalScientists);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [config, environment]);

  useEffect(() => {
    if (clickedScientist) { 
      const publicationsEndpoint = config[environment].getPublicationsEndpoint(clickedScientist);
      axios.get(publicationsEndpoint)
        .then(response => {
          console.log('Fetched publications:', response.data);
          setPublications(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [config, environment, clickedScientist]); 

  

  

  const toggleOpenCentury = (centuryId) => {
    setOpenCenturies(prevState => ({
      ...prevState,
      [centuryId]: !prevState[centuryId]
    }));
  };

  const toggleOpenField = (fieldId) => {
    setOpenFields(prevState => ({
      ...prevState,
      [fieldId]: !prevState[fieldId]
    }));
  };

  const toggleOpenScientist = (scientistId) => {
    setOpenScientists(prevState => ({
      ...prevState,
      [scientistId]: !prevState[scientistId]
    }));
  }; 

  // Function to group data by century
  const groupByCentury = (data) => {
    const groupedData = {};
    data.forEach(item => {
      const centuryId = item.centuryid;
      if (!groupedData[centuryId]) {
        groupedData[centuryId] = [];
      }
      groupedData[centuryId].push(item);
    });
    return groupedData;
  };
  
  //Table row rendering function
  const RenderCentury = (props) => {
    
    const { centuryId,centuryData,openCenturies,openFields,openScientists,toggleOpenCentury,
      toggleOpenField,toggleOpenScientist,publications} = props;
    
    const toggleCenturyCollapse = () => toggleOpenCentury(centuryId);    
  
    const toggleFieldCollapse = (fieldId) => {
      setOpenFields(prevState => ({
        ...prevState,
        [fieldId]: !prevState[fieldId]
      }));
    };

    const isFieldOpen = (fieldId) => {
      return !!openFields[fieldId];
    };

    const isScientistOpen = (scientistId) => {
      return !!openPublications[scientistId];
    };

    const toggleScientistCollapse = (event, scientistId) => {
      // Stop event propagation immediately
      event.stopPropagation();    
    
      // Check if the click occurred directly on the scientist row or its children
      if (!event.target.closest('td')) {
        return;
      }
    
      // Toggle the open state of the clicked scientist
      setOpenPublications(prevState => {
        const newState = {
          ...prevState,
          [scientistId]: !prevState[scientistId]
        };
        // Set the clicked scientist
        setClickedScientist(scientistId);
    
        return newState;
      });
    };
    
      
   // Function to group fields by name
    const groupFieldsByName = (fields) => {
      const groupedFields = {};
      fields.forEach(field => {
        const fieldId = field.fieldofscienceid;
        if (!groupedFields[fieldId]) {
          groupedFields[fieldId] = field;
          groupedFields[fieldId].hasScientists = false; 
        }
        if (field.scientistid) {
          groupedFields[fieldId].hasScientists = true; 
        }
      });
      return groupedFields;
    };

    // Function to group scientists by id
    const groupScientistsById = (scientists) => {
      const groupedScientists = {};
      scientists.forEach(scientist => {
        const scientistId = scientist.scientistid;
        if (!groupedScientists[scientistId]) {
          groupedScientists[scientistId] = scientist;
          groupedScientists[scientistId].hasDetails = false; 
        }
        if (scientist.detail) {
          groupedScientists[scientistId].hasDetails = true; 
        }
      });
      return groupedScientists;
    };   
  
    // Sort fields of science alphabetically
    const sortedFields = Object.values(groupFieldsByName(centuryData)).sort((a, b) => {
      return a.fieldofsciencename.localeCompare(b.fieldofsciencename);
    });

    
    

    const handleDeleteScientist = async (scientistId) => {
      try {
        const deleteScientistEndpoint = config[environment].deleteScientistEndpoint(scientistId);
        await axios.delete(deleteScientistEndpoint);
        setScientists(scientists.filter(scientist => scientist.scientistid !== scientistId));
      } catch (error) {
        console.error('Failed to delete scientist', error);
      }
    };
    
    const handleDeletePublication = async (publicationId) => {
      try {
        const deletePublicationEndpoint = config[environment].deletePublicationEndpoint(publicationId);
        await axios.delete(deletePublicationEndpoint);
        setPublications(publications.filter(publication => publication.publicationid !== publicationId));
      } catch (error) {
        console.error('Failed to delete publication', error);
      }
    };


    const scientistMap = new Map();
    centuryData.forEach(scientist => {
      if (!scientistMap.has(scientist.scientistid)) {
        // This is a new scientist, so create a new object for them
        scientistMap.set(scientist.scientistid, {
          ...scientist,
          publications: scientist.publications ? [scientist.publications] : []
        });
      } else {
        // This scientist already exists, so add the publication to their list
        const existingScientist = scientistMap.get(scientist.scientistid);
        existingScientist.publications.push(scientist.publications);
      }
    });
  
    return (     
      <React.Fragment key={centuryId}>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={toggleCenturyCollapse}>
              {openCenturies[centuryId]? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
          <TableCell sx={{ fontSize: "1.8rem" }}>{centuryData && centuryData.length > 0 ? centuryData[0].centuryname : 'No century data'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={openCenturies[centuryId]} timeout="auto" unmountOnExit>
              <Box margin={1.5} >

                {/* Content to display when century row is expanded */}
                {sortedFields.map(item => (
                  <React.Fragment key={item.fieldofscienceid}>
                    <TableRow onClick={() => toggleFieldCollapse(item.fieldofscienceid)}>
                    <TableCell sx={{ display: "flex", alignItems: "center", fontWeight: "bold", color: "#032b3b"}}>
                      {item.hasScientists && ( // Check if there are scientists
                        isFieldOpen(item.fieldofscienceid) ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />
                      )}
                      {item.fieldofsciencename}
                    </TableCell>
                  </TableRow>

                    <TableRow>                      
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={openFields[item.fieldofscienceid]} timeout="auto" unmountOnExit>

                          {/* Content to display when field of science row is expanded */}
                          <Box margin={1.5}  alignItems="center">                            
                            <Typography sx={{ fontSize: "1rem" }} gutterBottom component="div">
                              Kutatók
                            </Typography>
                            <Table size="small" aria-label="scientists">
                              <TableHead>
                                <TableRow  sx={{ fontStyle: "italic", color: "#2492be" }}>
                                  <TableCell/>
                                  <TableCell>Név</TableCell>
                                  <TableCell>A tézis címe</TableCell>
                                  <TableCell>Születés helye</TableCell>
                                  <TableCell>Születés ideje</TableCell>
                                  <TableCell>Elhalálozás helye</TableCell>
                                  <TableCell>Elhalálozás ideje</TableCell>
                                  <TableCell>Kötődés Székelyföldhöz</TableCell>
                                </TableRow>
                              </TableHead>

                              <TableBody>
                                {Array.from(scientistMap.values())
                                    .filter(scientist => scientist.fieldofscienceid === item.fieldofscienceid)
                                    .sort((a, b) => {
                                      // Sort scientists by last name and then by first name
                                      const lastNameComparison = a.scientistlastname.localeCompare(b.scientistlastname);
                                      if (lastNameComparison !== 0) {
                                        return lastNameComparison;
                                      }
                                      return a.scientistfirstname.localeCompare(b.scientistfirstname);
                                    })
                                    .map(scientist => (
                                      <React.Fragment key={scientist.scientistid}>
                                        <TableRow key={scientist.id} onClick={(event) => toggleScientistCollapse(event, scientist.scientistid)}>
                                          <TableCell sx={{  alignItems: "center", fontWeight: "bold", color: "#032b3b"}}>
                                            {isScientistOpen(scientist.scientistid) ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                                          </TableCell>
                                          <TableCell>{`${scientist.scientistlastname} ${scientist.scientistfirstname}`}</TableCell>
                                          <TableCell>{scientist.scientistthesistitle}</TableCell>
                                          <TableCell>{scientist.scientistbirthplace}</TableCell>
                                          <TableCell>{scientist.scientistbirthdate}</TableCell> 
                                          <TableCell>{scientist.scientistdeathplace}</TableCell>                                         
                                          <TableCell>{scientist.scientistdeathdate}</TableCell>                                          
                                          <TableCell>{scientist.scientistszeklertie}</TableCell>
                                          {GlobalState.userIsLogged && currentUser==scientist.scientistcontributorid &&
                                            <React.Fragment>
                                              <TableCell style={{ padding: '0px' }}>
                                                <IconButton onClick={() => handleEditScientist(scientist.scientistid)}>
                                                  <EditIcon />
                                                </IconButton>                                          
                                              </TableCell>
                                              <TableCell style={{ padding: '0px' }}>
                                                <IconButton onClick={() => handleDeleteScientist(scientist.scientistid)}>
                                                  <DeleteIcon />
                                                </IconButton>
                                              </TableCell>
                                            </React.Fragment>                                            
                                          }
                                        </TableRow>
                                        {scientist.publications && (
                                          <TableRow>
                                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                              <Collapse in={openPublications[scientist.scientistid]} timeout="auto" unmountOnExit>

                                                {/* Content to display when scientist row is expanded */}
                                                <Box margin={2}>
                                                  <Typography sx={{ fontSize: "1rem" }} gutterBottom component="div">
                                                    Publikációk
                                                  </Typography>
                                                  <Table size="small" aria-label="publications">
                                                    <TableHead>
                                                      <TableRow sx={{ fontStyle: "italic", color: "#2492be" }}>
                                                        <TableCell>Cím</TableCell>
                                                        <TableCell>Kiadás dátuma</TableCell>
                                                        <TableCell>Helye</TableCell>
                                                        <TableCell>Kiadó</TableCell>
                                                        <TableCell>Teljes hivatkozás</TableCell>
                                                        
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {publications
                                                        .filter(publication => publication) 
                                                        .sort((a, b) => new Date(b.publicationdate) - new Date(a.publicationdate))
                                                        .map(publication => (
                                                          <TableRow key={publication.publicationid}>
                                                            <TableCell>{publication.publicationtitle}</TableCell>
                                                            <TableCell>{publication.publicationdate}</TableCell>
                                                            <TableCell>{publication.publicationplace}</TableCell>
                                                            <TableCell>{publication.publicationpublisher}</TableCell>
                                                            <TableCell>{publication.publicationfullreference}</TableCell>
                                                            {GlobalState.userIsLogged && currentUser==scientist.scientistcontributorid &&
                                                              <React.Fragment>
                                                                <TableCell style={{ padding: '0px' }} >
                                                                  <IconButton onClick={() => handleEditPublication(scientist.scientistid)}>
                                                                    <EditIcon />
                                                                  </IconButton>                                          
                                                                </TableCell>
                                                                <TableCell style={{ padding: '0px' }}>
                                                                  <IconButton onClick={() => handleDeletePublication(scientist.scientistid)}>
                                                                    <DeleteIcon />
                                                                  </IconButton>
                                                                </TableCell>
                                                              </React.Fragment>                                            
                                                            }                                                         
                                                          </TableRow>
                                                        ))}
                                                    </TableBody>
                                                  </Table>
                                                </Box>
                                              </Collapse>
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      </React.Fragment>
                                    ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
                {!centuryData || centuryData.length === 0 && (
                  <Typography>No data available</Typography>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>      
    );
  };

  return (
    <>   
      <Typography variant="h4" sx={{textAlign: "center", color: "#105b79", fontWeight: "bold", margin: "2rem 0"}}>Virtuális Székely Nemzeti Akadémia</Typography> 
      <Typography variant="h6" sx={{textAlign: "center", color: "#105b79", fontStyle:"italic", margin: "2rem 0"}}>A jegyzett kutatók száma: {totalScientists}</Typography>     
       
      <Grid container spacing={2} justifyContent="center">
        {AddContentButtonDisplay()}
      </Grid>

      <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>
        <WarningIcon color="error" />
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h6" sx={{textAlign: "left", color: "#105b79", fontSize:"1.1rem", fontStyle:"italic", margin: "2rem 0"}}>
          Csak a saját adattételek szerkeszthetők! <br/>
          Azonos nevű szerzők esetén használjon megkülönböztető iniciálékat, vagy indokolt esetben adja hozzá saját monogramját (variáns, felülbírálat hozzáadásához)!<br/>
          Egy kutató különböző tudományterületekhez rendelve többször is megjelenhet, ha több tudományterületen is tevékenykedik.   
        </Typography>
      </Grid>
    </Grid>
      <TableContainer component={Paper}>
        
        <Table sx={{minWidth: 650}} aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell sx={{fontWeight :"bold", color:"105b79"}}>Évszázad</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(centuries).map((centuryId) => (
              <RenderCentury
              key={centuryId}
              centuryId={centuryId}
              centuryData={centuries[centuryId]}
              openCenturies={openCenturies}
              openFields={openFields}
              openScientists={openPublications}
              toggleOpenCentury={toggleOpenCentury}
              toggleOpenField={toggleOpenField}
              toggleOpenScientist={toggleOpenScientist}
              publications={publications} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddScientist open={isAddScientistModalOpen} onClose={() => setAddScientistModalOpen(false)} />
      <AddPublication open={isAddPublicationModalOpen} onClose={() => setAddPublicationModalOpen(false)} />
      <EditScientist isOpen={isEditScientistOpen} onClose={() => setIsEditScientistOpen(false)} scientist={currentScientist} />
      <EditPublication isOpen={isEditPublicationOpen} onClose={() => setIsEditPublicationOpen(false)} publication={currentPublication} />
    </> 
  );
}

export default Academy;