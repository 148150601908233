import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import Axios from "axios"
import { useImmerReducer } from 'use-immer';
import {Grid,  Typography, Button, TextField, Snackbar, Alert} from "@mui/material";



function Register() {
    const {config, environment } = require('../../index');
    const navigate = useNavigate();

    const initialState = {
        usernameValue: '',
        emailValue: '',
        passwordValue: '',
        password2Value: '',
        sendRequest: 0,
        openSnack: false,
        disabledRegisterBtn: false,
        usernameErrors: {
            hasErrors: false,
            errorMessage: '',
        },
        emailErrors: {
            hasErrors: false,
            errorMessage: '',
        },
        passwordErrors: {
            hasErrors: false,
            errorMessage: '',
        },
        password2HelperText: '',
        serverMessageUsername: '',
        serverMessageEmail: '',
        serverMessageSimilarPassword: '',
        serverMessageCommonPassword: '',
        serverMessageNumericPassword: '',

    };

    function ReducerFunction(draft, action){
        switch(action.type){
            case 'catchUsernameChange':
               draft.usernameValue = action.usernameChosen;
               draft.usernameErrors.hasErrors = false;
               draft.usernameErrors.errorMessage = '';
               draft.serverMessageUsername = '';
               break;
            case 'catchEmailChange':
               draft.emailValue = action.emailChosen;
               //hogy a hibak eltunjenek javitaskor
               draft.emailErrors.hasErrors = false;
               draft.emailErrors.errorMessage = '';
               draft.serverMessageEmail ='';
               break;
            case 'catchPasswordChange':
               draft.passwordValue = action.passwordChosen;
               draft.passwordErrors.hasErrors = false;
               draft.passwordErrors.errorMessage = '';
               draft.serverMessageSimilarPassword = '';
               draft.serverMessageCommonPassword = '';
               draft.serverMessageNumericPassword = '';

               break;
            case 'catchPassword2Change':
               draft.password2Value = action.password2Chosen;
               if(action.password2Chosen !== draft.passwordValue){
                draft.password2HelperText = 'A jelszóknak egyezniük kell!'
               }
               else if(action.password2Chosen === draft.passwordValue){
                draft.password2HelperText = ''
               }
               break; 
            case 'changeSendRequest':
               draft.sendRequest = draft.sendRequest +1;
               break; 
            case 'openTheSnack':
                draft.openSnack = true;
                break;
            case 'disabledRegisterBtn':
                draft.disabledLoginBtn = true;
                break;
            case 'allowTheRegisterBtn':
                draft.disabledLoginBtn = false;
                break;  
            case 'catchUsernameErrors':
                if(action.usernameChosen.length === 0)  {
                    draft.usernameErrors.hasErrors = true;
                    draft.usernameErrors.errorMessage = "A mező nem maradhat üresen!";
                } 
                else if(action.usernameChosen.length < 6){
                    draft.usernameErrors.hasErrors = true;
                    draft.usernameErrors.errorMessage = "A felhasználónévnek legalább hat karaktert kell tartalmaznia!";
                }
                else if (!/^([a-zA-Z0-9]+)$/.test(action.usernameChosen)){
                    draft.usernameErrors.hasErrors = true;
                    draft.usernameErrors.errorMessage = "A mező nem tartalmazhat különleges karaktereket!";
                }
                break; 
                
            case 'catchEmailErrors':                
                if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(action.emailChosen)){
                    draft.emailErrors.hasErrors = true;
                    draft.emailErrors.errorMessage = "A megadott emailcím nem érvényes!";
                }
                break;
                
            case 'catchPasswordErrors':
                if(action.passwordChosen.length < 8){
                    draft.passwordErrors.hasErrors = true;
                    draft.passwordErrors.errorMessage = "A jelszónak legalább nyolc karaktert kell tartalmaznia!";
                }
                break;

            case 'usernameExists':
                draft.serverMessageUsername = 'Ez a felhasználó már létezik!'
                break;
            case 'emailExists':
                draft.serverMessageEmail = 'Ez az email már foglalt!'
                break;
            case 'similarPassword':
                draft.serverMessageSimilarPassword = 'A jelszó túl hasonlít a többi mezőhöz!';
                break;
            case 'commonPassword':
                draft.serverMessageCommonPassword = 'A jelszó túl közönséges!';
                break;
            case 'numericPassword':
                draft.serverMessageNumericPassword = 'A jelszó nem tartalmazhat csupán számokat!';
                break;            

        }
    }

    const [state, dispatch] = useImmerReducer(ReducerFunction, initialState)

   
    function FormSubmit(e){
        e.preventDefault(); 
        if(!state.usernameErrors.hasErrors && !state.emailErrors.hasErrors && !state.passwordErrors.hasErrors 
            && state.password2HelperText === '') {
                dispatch({type:'changeSendRequest'});
                dispatch({type: 'disabledRegisterBtn'})
            }     
    }

    useEffect(() =>{
       if(state.sendRequest){
        const source= Axios.CancelToken.source();
        async function SignUp(){      
          try{   
            const registerEndpoint = config[environment].registerEndpoint;        
            const response =  await Axios.post(registerEndpoint, {
                username: state.usernameValue,
                email: state.emailValue,
                password: state.passwordValue,
                re_password: state.password2Value,
            },
            {
                cancelToken: source.token,
            });    
            dispatch({type: 'openTheSnack'});
            //useEffecte for navigate
            } catch (error){
                dispatch({ type: 'allowTheRegisterBtn', })
                if(error.response.data.username){
                    dispatch({type: 'usernameExists'})
                }
                else if (error.response.data.email){
                    dispatch({type: 'emailExists'})
                }
                else if(error.response.data.password[0] === 'This password is too similar to the username.'){
                    dispatch({type: 'similarPassword'})
                }
                else if(error.response.data.password[0] === 'This password is too common.'){
                    dispatch({type: 'commonPassword'})
                }
                else if(error.response.data.password[0] === 'This password is entirely numeric.'){
                    dispatch({type: 'numericPassword'})
                }
            }
        }  
        SignUp();
        return()=>{
            source.cancel();
        };
      }
    }, [config, environment, state.sendRequest]);


    useEffect(()=>{
        if (state.openSnack){
          setTimeout(()=>{
            navigate('/created');
          }, 1500)
        }
    }, [state.openSnack]) //will watch for changes in state.openSnack

    return (
        <div>
        <div style={{width:'50%', marginLeft: "auto", marginRight: "auto", marginTop: "3rem", border:"2px solid black", padding: '3rem' }}>
            <form onSubmit={FormSubmit}>
                <Grid item container justifyContent="center">
                    <Typography variant='h4'>REGISZTRÁLÁS</Typography>
                </Grid>
              
                {state.serverMessageUsername ?  <Alert severity ='error'>{state.serverMessageUsername }</Alert> : ''}
                {state.serverMessageEmail ?  <Alert severity ='error'>{state.serverMessageEmail }</Alert> : ''}
                {state.serverMessageSimilarPassword ?  <Alert severity ='error'>{state.serverMessageSimilarPassword }</Alert> : ''}
                {state.serverMessageCommonPassword ?  <Alert severity ='error'>{state.serverMessageCommonPassword }</Alert> : ''}
                {state.serverMessageNumericPassword ?  <Alert severity ='error'>{state.serverMessageNumericPassword }</Alert> : ''}


                <Grid item container sx={{marginTop: "1rem"}}>
                    <TextField id="username" label="Username" variant="outlined" fullWidth value={state.usernameValue}
                     onChange={(e)=>dispatch({type: 'catchUsernameChange', usernameChosen: e.target.value})}
                     onBlur={(e)=>dispatch({type: 'catchUsernameErrors', usernameChosen: e.target.value})}
                     error = {state.usernameErrors.hasErrors ? true : false}
                     helperText = {state.usernameErrors.errorMessage}/>
                </Grid>

                <Grid item container sx={{marginTop: "1rem"}}>
                    <TextField id="email" label="Email" variant="outlined" fullWidth value={state.emailValue} 
                    onChange={(e)=>dispatch({type: 'catchEmailChange', emailChosen: e.target.value})}
                    onBlur={(e)=>dispatch({type: 'catchEmailErrors', emailChosen: e.target.value})}
                    error = {state.emailErrors.hasErrors ? true : false}
                    helperText = {state.emailErrors.errorMessage}/>
                </Grid>

                <Grid item container sx={{marginTop: "1rem"}}>
                    <TextField id="password" label="Password" variant="outlined" fullWidth value={state.passwordValue} 
                    onChange={(e)=>dispatch({type: 'catchPasswordChange', passwordChosen: e.target.value})} 
                    type="Password"
                    onBlur={(e)=>dispatch({type: 'catchPasswordErrors', passwordChosen: e.target.value})}
                    error = {state.passwordErrors.hasErrors ? true : false}
                    helperText = {state.passwordErrors.errorMessage}/>
                </Grid>

                <Grid item container sx={{marginTop: "1rem"}}>
                    <TextField id="password2" label="Confirm Password" variant="outlined" fullWidth  value={state.password2Value} 
                    onChange={(e)=>dispatch({type: 'catchPassword2Change', password2Chosen: e.target.value})} type="Password"
                    helperText= {state.password2HelperText}/>
                </Grid>

                <Grid item container xs={8} sx={{marginTop: "1rem", marginLeft: "auto", marginRight: "auto"}}>
                    <Button variant='contained' fullWidth type='submit'disabled={state.disabledRegisterBtn} >FELIRATKOZÁS</Button>
                </Grid>
            </form>
            <Grid item container justifyContent="center" sx={{marginTop: "1rem"}}>
                    <Typography variant='small'>Van már Székely Nemzet Online fiókod? <span onClick={()=>navigate("/login")} style={{cursor: "pointer", color:"green"}}>LÉPJ BE</span> </Typography>
            </Grid>
            <Snackbar
                open={state.openSnack}         
                
                message="Sikeresen feliratkoztál!"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                
                />
        </div>
        </div>
    );
}
export default Register