import React from 'react'

function EditScientist() {
  return (
    <div>
      
    </div>
  )
}

export default EditScientist
