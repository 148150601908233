import L from 'leaflet';
import Icons from '../Assets/SZNOIcons.js';
import RoomIcon from '@mui/icons-material/Room';
import { styled } from '@mui/system';

const defaultIcon = new L.Icon({
    iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png',  
    iconSize: [25, 41],  
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  const {
    apartmentIcon, bedandbreakfastIcon, bedandbreakfastfoodIcon, hostelIcon, udvarhazIcon, campingIcon, tentandcampingIcon,
    chapelIcon, churchIcon, varIcon, borvizIcon, forrasIcon, hotspringIcon, hotelIcon, hotel3starIcon, hotel4starIcon, mineIcon,
    mineclosedIcon, oilandgaswellIcon, oilpumpjackIcon, panoramicviewIcon, sizesIcon, siberlesIcon, sifelvonoIcon, iszapvulkanIcon,
    strandIcon, castleIcon, uszodaIcon, uszasIcon, satrazasIcon,  motelIcon, peakIcon,
  } = Icons;

  const RoomIcons = {
    RoomIcon,
    RedRoomIcon: styled(RoomIcon)({ color: 'red' }),
    GreenRoomIcon: styled(RoomIcon)({ color: 'green' }),
    YellowRoomIcon: styled(RoomIcon)({ color: 'yellow' }),
    OrangeRoomIcon: styled(RoomIcon)({ color: 'orange' }),
    defaultIcon,
  };
  

export function IconDisplay(iconType) {
    switch (iconType) {
        case 'szanto':
            return hostelIcon;
        case 'beltelek':
        case 'kaszalo':
        case 'erdo':
        case 'garzon':
        case 'csaladihaz':
        case 'hetvegihaz':
        case 'kulcsoshaz':      
        case 'iroda':
        case 'ipari epulet':
        case 'kereskedelmi ingatlan':
        case 'ingatlan':
        case 'egyeb':
            return RoomIcon;
        case 'auto':
            return RoomIcons.RedRoomIcon;
        case 'telisportfelszereles':
            return RoomIcons.GreenRoomIcon;
        case 'nyarisportfelszereles':
            return RoomIcons.YellowRoomIcon;
        case 'sajattermek':
            return RoomIcons.OrangeRoomIcon;
        case 'hosztel':
            return hostelIcon;
        case 'udvarhaz':
            return udvarhazIcon;
        case 'kastely':
            return castleIcon;
        case 'hotel':
            return hotelIcon;
        case 'panzio':
            return bedandbreakfastIcon;
        case 'kiadoszoba':
            return bedandbreakfastfoodIcon;
        case 'tombhazlakas':
            return apartmentIcon;
        case 'szallasreggelivel':
            return bedandbreakfastfoodIcon;
        case 'kemping':
            return campingIcon;
        case 'satorozas':
            return tentandcampingIcon;
        case 'kapolna':
            return chapelIcon;
        case 'templom':
            return churchIcon;
        case 'var':
            return varIcon;
        case 'borviz':
            return borvizIcon;
        case 'forras':
            return forrasIcon;
        case 'thermalforras':
            return hotspringIcon;
        case 'hotel***':
            return hotel3starIcon;
        case 'hotel****':
            return hotel4starIcon;
        case 'banya':
            return mineIcon;
        case 'zartbanya':
            return mineclosedIcon;
        case 'olajesgazkut':
            return oilandgaswellIcon;
        case 'olajpumpa':
            return oilpumpjackIcon;
        case 'panorama':
            return panoramicviewIcon;
        case 'sizes':
            return sizesIcon;
        case 'sifelvono':
            return sifelvonoIcon;
        case 'siberles':
            return siberlesIcon;
        case 'iszapvulkan':
            return iszapvulkanIcon;
        case 'strand':
            return strandIcon;
        case 'uszoda':
            return uszodaIcon;
        case 'uszas':
            return uszasIcon;
        case 'satortabor':
            return satrazasIcon;
        case 'motel':
            return motelIcon;
        case 'csucs':
            return peakIcon;
        default:
            return null; 
    }
}

export { Icons, RoomIcons };