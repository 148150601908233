import React, { useEffect,  useContext } from 'react';  
import {useNavigate, useParams} from "react-router-dom";
import Axios from "axios"
import { useImmerReducer } from 'use-immer';
import {Grid, Typography, IconButton,  Card, CardMedia, CardContent, CircularProgress, CardActions} from "@mui/material";

import StateContext from "../../Contexts/StateContext";
//Assets
import defaultProfilePicture from "../../Assets/defaultProfilePicture.jpg";
import PhoneIcon from '@mui/icons-material/Phone';





function CompanyDetail() {
    const navigate = useNavigate()
    const GlobalState = useContext(StateContext)  
    const {config, environment } = require('../../index');

    const params = useParams();
    
    const initialState = {       
        userProfile:{
            companyName: '',
            phoneNumber: '',
            profilePic: '',
            bio: '',
            sellerListings: [],
        }, 
        dataIsLoading: true,       
    };

    function ReducerFunction(draft, action){
        switch(action.type){
            case 'catchUserProfileInfo':
                draft.userProfile.companyName = action.profileObject.company_name; 
                draft.userProfile.phoneNumber = action.profileObject.phone_number;
                draft.userProfile.profilePic = action.profileObject.profile_picture;
                draft.userProfile.bio = action.profileObject.bio;
                draft.userProfile.sellerListings = action.profileObject.seller_listings;
                 break;
            case 'loadingDone': 
                draft.dataIsLoading = false;
                break;           
        }
    }

    const [state, dispatch] = useImmerReducer(ReducerFunction, initialState)  

    //request to get profie info
    useEffect(() => {
        async function getProfileInfo() {
            try {   
                const { config, environment } = require('../../index');   
                const profileInfoEndpoint = config[environment].getProfileEndpoint(params.id);          
                const response = await Axios.get(profileInfoEndpoint);
                
                dispatch({type: 'catchUserProfileInfo', profileObject: response.data});
                dispatch({type: 'loadingDone'});
            } catch (e) {
                console.log(e.response);
            }

        }
        getProfileInfo();
    }, [config, environment]);

    if(state.dataIsLoading === true){
        return (<Grid container justifyContent="center" alignItems="center" style={{height:"100vh"}}>
          <CircularProgress/>
        </Grid>);
    }

    return (
        <div>
            <Grid container style={{width: '50%', marginLeft: 'auto', marginRight: 'auto', border:'3px solid grey', 
                            marginTop:"1rem", padding: '5px'}}>
                        <Grid item xs={6} sx={{marginTop: "1rem"}}>
                            <img src={state.userProfile.profilePic !== null ? state.userProfile.profilePic : defaultProfilePicture } alt="Profile picture" style={{width: "10rem", height: "10rem"}}/>
                        </Grid>
                        <Grid item container direction='column' justifyContent="center" xs={6}>
                            <Grid item>
                                <Typography variant="h5" 
                                    style={{textAlign:'center', marginTop:'1rem'}} > 
                                    <span style={{color: 'green', fontWeight: 'bolder'}}> {state.userProfile.companyName}</span>                            
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" style={{textAlign:'center', marginTop:'1rem'}} > 
                                    <IconButton><PhoneIcon/>   {state.userProfile.phoneNumber} </IconButton>                                            
                                </Typography>
                            </Grid> 
                                            
                            
                        </Grid>
                        <Grid item>
                                <Typography variant="h5" style={{textAlign:'center', marginTop:'1rem', padding:'5px'}} > 
                                    {state.userProfile.bio}                                            
                                </Typography>
                        </Grid> 
            </Grid>

            <Grid container justifyContent='flex-start' spacing={2} style={{padding:"10px"}}>
                {state.userProfile.sellerListings.map((listing) => {
                    return (
                        <Grid key={listing.id} item style={{marginTop:"1rem", maxWidth:'20rem'}}>
                            <Card sx={{ maxWidth: 345 }} key={listing.id}>
                            <CardMedia
                                sx={{ height: 140 , cursor: 'pointer'}}
                                image={
                                    listing.picture1 ? listing.picture1 : defaultProfilePicture
                                }
                                title="Listing picture"
                                onClick={()=>navigate(`/listings/${listing.id}`)} 
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                {listing.title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                {listing.description.substring(0, 100)}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                {listing.property_status === 'Eladó' 
                                ? `${listing.listing_type} : $${listing.price}`
                                : `${listing.listing_type} : $${listing.price.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / ${listing.rental_frequency}`}
                            </CardActions>
                            </Card>
                        </Grid>
                    )            
                })}
            </Grid>
        </div>
  )
}

export default CompanyDetail
