import L from 'leaflet';

//Map icons
import apartmentIconPng from './MapIcons/apartment-3.png';
import bedandbreakfastIconPng from './MapIcons/bed_breakfast1-2.png';
import bedandbreakfastfoodIconPng from './MapIcons/bed_breakfast_food.png';
import udvarhazIconPng from './MapIcons/bighouse.png';
import campingIconPng from './MapIcons/camping-2.png';
import tentandcampingIconPng from './MapIcons/campingtents.png';
import castleIconPng from './MapIcons/castle-2.png';
import chapelIconPng from './MapIcons/chapel-2.png';
import churchIconPng from './MapIcons/church-2.png';
import varIconPng from './MapIcons/citywalls.png';
import borvizIconPng from './MapIcons/fountain-2.png';
import forrasIconPng from './MapIcons/fountain-3.png';
import hostelIconPng from './MapIcons/hostel_0star.png';
import hotelIconPng from './MapIcons/hostel_0star.png';
import hotel3starIconPng from './MapIcons/hotel_3stars.png';
import hotel4starIconPng from './MapIcons/hotel_4stars.png';
import hotspringIconPng from './MapIcons/hotspring.png';
import houseIconPng from './MapIcons/house.png';
import mineIconPng from './MapIcons/mine.png';
import mineclosedIconPng from './MapIcons/mine_closed.png';
import oilandgaswellIconPng from './MapIcons/oil-and-gas-well.png';
import oilpumpjackIconPng from './MapIcons/oilpumpjack.png';
import panoramicviewIconPng from './MapIcons/panoramicview.png';
import siberlesIconPng from './MapIcons/ski_shoe1.png';
import sizesIconPng from './MapIcons/skiing.png';
import sifelvonoIconPng from './MapIcons/skilifting.png';
import iszapvulkanIconPng from './MapIcons/spring.png';
import strandIconPng from './MapIcons/swimming.png';
import uszodaIconPng from './MapIcons/swimming_indoor.png';
import uszasIconPng from './MapIcons/swimming2.png';
import satrazasIconPng from './MapIcons/tents.png';
import zomtoronyIconPng from './MapIcons/tower.png';
import motelIconPng from './MapIcons/motel-2.png';
import peakIcon from './MapIcons/csucs.png';

const Icons = {
    apartmentIcon : L.icon({
    iconUrl: apartmentIconPng,
    iconSize: [40, 40]
  }),

   bedandbreakfastIcon : L.icon({
    iconUrl: bedandbreakfastIconPng,
    iconSize: [40, 40]
  }),

   bedandbreakfastfoodIcon : L.icon({
    iconUrl: bedandbreakfastfoodIconPng,
    iconSize: [40, 40]
  }),

   udvarhazIcon : L.icon({
    iconUrl: udvarhazIconPng,
    iconSize: [40, 40]
  }),

  campingIcon : L.icon({
    iconUrl: campingIconPng,
    iconSize: [40, 40]
  }),

   tentandcampingIcon : L.icon({
    iconUrl: tentandcampingIconPng,
    iconSize: [40, 40]
  }),

   castleIcon : L.icon({
    iconUrl: castleIconPng,
    iconSize: [40, 40]
  }),

   chapelIcon : L.icon({
    iconUrl: chapelIconPng,
    iconSize: [40, 40]
  }),
   churchIcon : L.icon({
    iconUrl: churchIconPng,
    iconSize: [40, 40]
  }),

   varIcon : L.icon({
    iconUrl: varIconPng,
    iconSize: [40, 40]
  }),

   borvizIcon : L.icon({
    iconUrl: borvizIconPng,
    iconSize: [40, 40]
  }),

   forrasIcon : L.icon({
    iconUrl: forrasIconPng,
    iconSize: [40, 40]
  }),

   hostelIcon : L.icon({
    iconUrl: hostelIconPng,
    iconSize: [40, 40]
  }),

   hotelIcon : L.icon({
    iconUrl: hotelIconPng,
    iconSize: [40, 40]
  }),

   hotel3starIcon : L.icon({
    iconUrl: hotel3starIconPng,
    iconSize: [40, 40]
  }),
   hotel4starIcon : L.icon({
    iconUrl: hotel4starIconPng,
    iconSize: [40, 40]
  }),

  hotspringIcon : L.icon({
    iconUrl: hotspringIconPng,
    iconSize: [40, 40]
  }),

   houseIcon : L.icon({
    iconUrl: houseIconPng,
    iconSize: [40, 40]
  }),

   mineIcon : L.icon({
    iconUrl: mineIconPng,
    iconSize: [40, 40]
  }),

   mineclosedIcon : L.icon({
    iconUrl: mineclosedIconPng,
    iconSize: [40, 40]
  }),

   oilandgaswellIcon : L.icon({
    iconUrl: oilandgaswellIconPng,
    iconSize: [40, 40]
  }),

  oilpumpjackIcon : L.icon({
    iconUrl: oilpumpjackIconPng,
    iconSize: [40, 40]
  }),

   panoramicviewIcon : L.icon({
    iconUrl: panoramicviewIconPng,
    iconSize: [40, 40]
  }),

   siberlesIcon : L.icon({
    iconUrl: siberlesIconPng,
    iconSize: [40, 40]
  }),

   sizesIcon : L.icon({
    iconUrl: sizesIconPng,
    iconSize: [40, 40]
  }),

   sifelvonoIcon : L.icon({
    iconUrl: sifelvonoIconPng,
    iconSize: [40, 40]
  }),

   iszapvulkanIcon : L.icon({
    iconUrl: iszapvulkanIconPng,
    iconSize: [40, 40]
  }),

   strandIcon : L.icon({
    iconUrl: strandIconPng,
    iconSize: [40, 40]
  }),

   uszodaIcon : L.icon({
    iconUrl: uszodaIconPng,
    iconSize: [40, 40]
  }),

   uszasIcon : L.icon({
    iconUrl: uszasIconPng,
    iconSize: [40, 40]
  }),

   satrazasIcon : L.icon({
    iconUrl: satrazasIconPng,
    iconSize: [40, 40]
  }),

   zomtoronyIcon : L.icon({
    iconUrl: zomtoronyIconPng,
    iconSize: [40, 40]
  }),
  
   motelIcon : L.icon({
    iconUrl: motelIconPng,
    iconSize: [40, 40]
  }),

  peakIcon : L.icon({
    iconUrl: peakIcon,
    iconSize: [15, 15]
  
  })

};

export default Icons;