import React, { useEffect,  useContext } from 'react';  
import {useNavigate} from "react-router-dom";
import Axios from "axios"
import { useImmerReducer } from 'use-immer';
import {Grid, Typography, Button, TextField, Snackbar} from "@mui/material";

import StateContext from "../../Contexts/StateContext";


function ProfileUpdate(props) {
    const {config, environment } = require('../../index');

    const navigate = useNavigate()
    const GlobalState = useContext(StateContext) 
    const userId = GlobalState.userId;
 
    const initialState = {   
        companyNameValue: props.userProfile.companyName,
        phoneNumberValue: props.userProfile.phoneNumber,
        bioValue: props.userProfile.bio,
        uploadedPicture:  [],       
        profilePictureValue: props.userProfile.profilePic,
        sendRequest: 0,
        openSnack: false,
        disabledUpdateBtn: false,
    };

    function ReducerFunction(draft, action){
        switch(action.type){
            case 'catchCompanyNameChange':
                draft.companyNameValue = action.companyNameChosen;
                break;
            case 'catchPhoneNumberChange':
                draft.phoneNumberValue = action.phoneNumberChosen;
                break;
            case 'catchBioChange':
                draft.bioValue = action.bioChosen;
                break;
            case 'catchUploadedPicture':
                draft.uploadedPicture = action.pictureChosen;
                break;
            case 'catchProfilePictureChange':
                draft.profilePictureValue = action.profilePictureChosen;
                break;
            case 'changeSendRequest':
                draft.sendRequest = draft.sendRequest + 1;
                break;
            case 'openTheSnack':
                draft.openSnack = true;
                break;
            case 'disabledUpdateBtn':
                draft.disabledUpdateBtn = true;
                break;
            case 'allowTheUpdateBtn':
                draft.disabledUpdateBtn = false;
                break;
        }
    }

    const [state, dispatch] = useImmerReducer(ReducerFunction, initialState)  

    //use effect to catch uploaded picture
    useEffect(() => {
        if (state.uploadedPicture && state.uploadedPicture.length > 0){
            dispatch({type: 'catchProfilePictureChange', profilePictureChosen: state.uploadedPicture[0]});
        }
    }, [state.uploadedPicture]);

  
    //
    useEffect(() =>{
        if(state.sendRequest){  
            const formData = new FormData();
            if(typeof state.profilePictureValue === 'string' || state.profilePictureValue === null){
                formData.append('company_name', state.companyNameValue);
                formData.append('phone_number', state.phoneNumberValue);
                formData.append('bio', state.bioValue);                
                formData.append('seller', GlobalState.userId);  
            } else{

                formData.append('company_name', state.companyNameValue);
                formData.append('phone_number', state.phoneNumberValue);
                formData.append('bio', state.bioValue);
                formData.append('profile_picture', state.profilePictureValue);
                formData.append('seller', GlobalState.userId);  
            }
            async function UpdateProfile(){
                try{    
                    const profileUpdateEndpoint = config[environment].getProfileUpdateEndpoint(userId);                
                    const response =  await Axios.patch(profileUpdateEndpoint, formData);
                    dispatch({type: 'openTheSnack'});
                    //useEffects for navigate
                } catch (e){
                    dispatch({
                        type: 'allowTheUpdateBtn',
                       })
                }
            }
            UpdateProfile();
        }
    }, [config, environment, state.sendRequest]);

    function FormSubmit(e){
        e.preventDefault();        
        dispatch({type:'changeSendRequest'});
        dispatch({type: 'disabledUpdateBtn'})
    };

    function ProfilePictureDisplay(){
        if(typeof state.profilePictureValue !== 'string'){
            return (
                <ul>
                    {state.profilePictureValue ? <li>{state.profilePictureValue.name}</li> : ""}                        
                </ul> 
            )
        }
        else if(typeof state.profilePictureValue === 'object'){
            return (
                <Grid item style={{marginTop:"0.5rem", marginLeft:"auto", marginRight:"auto"}}>
                    <img src={state.profilePictureValue} alt="profile picture" style={{width: "5rem", height: "5rem"}}/>    
                </Grid>
            )           
            
        }   
    }

    useEffect(() => {
        let timeoutId;
    
        if (state.openSnack) {
            timeoutId = setTimeout(() => {
                navigate(0);
            }, 1500);
        }
    
        return () => {
            clearTimeout(timeoutId);
        };
    }, [state.openSnack, navigate]);
    

  return (
    <>
               
        <div style={{width:'50%', marginLeft: "auto", marginRight: "auto", marginTop: "3rem", border:"2px solid black", padding: '3rem' }}>
            <form onSubmit={FormSubmit}>
                <Grid item container justifyContent="center">
                    <Typography variant='h4'>PROFILADATOK</Typography>
                </Grid>

                <Grid item container sx={{marginTop: "1rem"}}>
                    <TextField id="companyName" label="Intézménynév*" variant="outlined" fullWidth
                    value={state.companyNameValue}
                    onChange={(e)=>dispatch({type: 'catchCompanyNameChange', companyNameChosen: e.target.value})}/>
                </Grid>
                
                <Grid item container sx={{marginTop: "1rem"}}>
                    <TextField id="phoneNumber" label="Telefonszám*" variant="outlined" fullWidth 
                    value={state.phoneNumberValue} 
                    onChange={(e)=>dispatch({type: 'catchPhoneNumberChange', phoneNumberChosen: e.target.value})} />
                </Grid>

                <Grid item container sx={{marginTop: "1rem"}}>
                    <TextField id="bio" label="Bemutatkozás" 
                    variant="outlined" 
                    fullWidth
                    multiline 
                    rows={7}
                    value={state.bioValue} 
                    onChange={(e)=>dispatch({type: 'catchBioChange', bioChosen: e.target.value})} />
                </Grid>

                <Grid item container xs={12} sx={{marginTop: "1rem"}}>
                         {ProfilePictureDisplay()  }              
                </Grid>
                
                <Grid item container xs={6} sx={{marginTop: "1rem", marginLeft: "auto", marginRight: "auto" }}>
                    <Button 
                        variant='contained'
                        component="label" 
                        fullWidth 
                        sx={{backgroundColor: "green", 
                        color: "white",
                        fontSize: "0.8rem"}} >
                            PROFILKÉP
                            <input 
                            type="file" 
                            accept="image/png, image/gif, image/jpg, image/svg"                            
                            style={{display: "none"}}
                            hidden
                            onChange = {(e)=>dispatch({type: 'catchUploadedPicture', pictureChosen: e.target.files})}
                            />
                        </Button>
                </Grid>                

                <Grid item container xs={8} sx={{marginTop: "1rem", marginLeft: "auto", marginRight: "auto"}}>
                    <Button variant='contained' fullWidth type='submit' disabled={state.disabledUpdateBtn} >FRISSÍTÉS</Button>
                </Grid>
            </form>
            <Snackbar
                open={state.openSnack}     
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <Typography variant="body1">
                    Sikeresen frissítetted az adatlapot!
                </Typography>
            </Snackbar>                   
        </div>    
    </>
  );
}

export default ProfileUpdate;
