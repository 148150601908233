import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes, Outlet} from "react-router-dom";
import { useImmerReducer } from "use-immer";

//MUI
import { CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';

//Components



/* Szemle*/
import News from "./Components/Szemle/News";
/* Székely Szótár*/
import SzekelySzotar from "./Components/SzekelySzotar/SzekelySzotar";
/* Kútfők*/
import KnowledgeBase from "./Components/Kutfok/KnowledgeBase";
/* Akademia*/
import Academy from "./Components/Akademia/Academy";
/* Látnivalók*/
import BuiltHeritage from "./Components/Latnivalok/BuiltHeritage";
import Bothanical from "./Components/Latnivalok/Bothanical";
import Ethnographic from "./Components/Latnivalok/Ethnographic";
import Tracking from "./Components/Latnivalok/Tracking";
import Spiritual from "./Components/Latnivalok/Spiritual";
import Geologic from "./Components/Latnivalok/Geologic";
import Equestrian from "./Components/Latnivalok/Equestrian";
import RunicScripts from "./Components/Latnivalok/RunicScripts";
/* Intézmények*/
import Institutions from "./Components/Intezmenyek/Institutions"; 
import InstitutionDetail from "./Components/Business/CompanyDetail"; 
/* Cégek::Mesterek*/
import Companies from "./Components/Business/Companies";
import CompanyDetail from "./Components/Business/CompanyDetail";
import Masters from "./Components/Business/Masters";
import MasterDetail from "./Components/Business/MasterDetail";
import DayLabourers from "./Components/Business/DayLabourers";
import DayLabourerDetail from "./Components/Business/DayLabourerDetail";
/* Szállás*/

/* Piactér*/
import Market from "./Components/Piac/Market";
import Listings from "./Components/Piac/Listings";

import ListingDetail from "./Components/Piac/ListingDetail";
/* Térképobjektum hozzaadás*/
import AddProperty from "./Components/Piac/AddProperty"; //ingatlan hozzáadása

/* Adminisztráció*/
import AccountCreated from "./Components/Administration/AccountCreated";
import Activation from "./Components/Administration/Activation";
import Login from "./Components/Administration/Login";
import Register from "./Components/Administration/Register";
import Profile from "./Components/Administration/Profile";

import ErrorBoundary from "./Components/ErrorBoundary"; 
import Home from "./Components/Home";
import Header from "./Components/Header";

import './App.css';
import './Styles/sznostyles.css';

//Contexts
import DispatchContext from "./Contexts/DispatchContext";
import StateContext from "./Contexts/StateContext";

const theme = createTheme();
function App() {

  const initialState = {
    userUsername: localStorage.getItem("theUserUsername"),
    userEmail: localStorage.getItem("theUserEmail"),
    userId: localStorage.getItem("theUserId"),
    userToken: localStorage.getItem("theUserToken"),
    userIsLogged: localStorage.getItem("theUserUsername") ? true : false,
   };

   function ReducerFunction(draft, action){
       switch(action.type){
           case "catchToken":
              draft.userToken = action.tokenValue;
              break;
            case "userSignsIn":
              draft.userUsername = action.usernameInfo;
              draft.userEmail = action.emailInfo;
              draft.userId = action.IdInfo; 
              draft.userIsLogged = true;              
              break;   
            case "logout": 
              draft.userIsLogged = false;
            break;

       }
   };
  const [state, dispatch] = useImmerReducer(ReducerFunction, initialState);

  useEffect(()=>{
    if(state.userIsLogged && state.userUsername && state.userEmail && state.userId && state.userToken){
      localStorage.setItem('theUserUsername', state.userUsername);
      localStorage.setItem('theUserEmail', state.userEmail);
      localStorage.setItem('theUserId', state.userId);
      localStorage.setItem('theUserToken', state.userToken);      
    }
    else {
      localStorage.removeItem('theUserUsername');
      localStorage.removeItem('theUserEmail');
      localStorage.removeItem('theUserId');
      localStorage.removeItem('theUserToken');
    }
  }, [state.userIsLogged]);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <BrowserRouter>
          <CssBaseline />
          <Header/>
          <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/login' element={<Login/>}/>
            <Route path='/register' element={<Register/>}/>
            <Route path="/listings" element={<ErrorBoundary><Listings /></ErrorBoundary>} />           
            <Route path='/addproperty' element={<AddProperty/>}/> 
            <Route path='/profile' element={<Profile/>}/> 
            <Route path='/market' element={<Market/>}/> 
            <Route path='/news' element={<News/>}/>   
            <Route path='/szotar' element={<SzekelySzotar/>}/>
            <Route path='/kutfok' element={<KnowledgeBase/>}/>
            <Route path='/academy' element={<Academy/>}/>
            <Route path='/epitett-orokseg' element={<BuiltHeritage/>}/>
            <Route path='/novenyvilag' element={<Bothanical/>}/>
            <Route path='/neprajzi' element={<Ethnographic/>}/>
            <Route path='/rovasemlek' element={<RunicScripts/>}/>
            <Route path='/gyalogtura' element={<Tracking/>}/>
          
            <Route path='/lovastura' element={<Equestrian/>}/>
            <Route path='/egyhazi' element={<Spiritual/>}/>
            <Route path='/foldtani' element={<Geologic/>}/>
            <Route path='/institutions' element={<Institutions/>}/>   
            <Route path='/institutions/:id' element={<InstitutionDetail/>}/>

            <Route path='/cegek' element={<Companies/>}/>
            <Route path='/ceg/:id' element={<CompanyDetail/>}/>
            <Route path='/mesterek' element={<Masters/>}/>
            <Route path='/mester/:id' element={<MasterDetail/>}/>
            <Route path='/napszamosok' element={<DayLabourers/>}/>
            <Route path='/napszamos/:id' element={<DayLabourerDetail/>}/>
            <Route path='/listings/:id' element={<ListingDetail/>}/>  
            <Route path='/created' element={<AccountCreated/>}/>
            <Route path='/activate/:uid/:token' element={<Activation/>}/>   
          </Routes>
        </BrowserRouter>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export default App;