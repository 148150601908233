import React, { useEffect,   useContext } from 'react';  
import {useNavigate} from "react-router-dom";
import Axios from "axios"
import { useImmerReducer } from 'use-immer';
import {Grid, Typography, Button, CircularProgress} from "@mui/material";

import StateContext from "../../Contexts/StateContext";
//Assets
import defaultProfilePicture from "../../Assets/defaultProfilePicture.jpg";

//components
import ProfileUpdate from "./ProfileUpdate";


function Profile() {
    const {config, environment } = require('../../index');
    const navigate = useNavigate()
    const GlobalState = useContext(StateContext)
    const userId = GlobalState.userId;

    const initialState = {       
        userProfile:{
            companyName: '',
            phoneNumber: '',
            profilePic: '',
            bio: '',
            sellerId: '',
            sellerListings: [],
        }, 
        dataIsLoading: true,       
    };

    function ReducerFunction(draft, action){
        switch(action.type){
            case 'catchUserProfileInfo':
                draft.userProfile.companyName = action.profileObject.company_name; 
                draft.userProfile.phoneNumber = action.profileObject.phone_number;
                draft.userProfile.profilePic = action.profileObject.profile_picture;
                draft.userProfile.bio = action.profileObject.bio;
                draft.userProfile.sellerListings = action.profileObject.seller_listings;
                draft.userProfile.sellerId = action.profileObject.seller;
                 break;
            case 'loadingDone': 
                draft.dataIsLoading = false;
                break;           
        }
    }

    const [state, dispatch] = useImmerReducer(ReducerFunction, initialState)  

    //request to get profie info
    useEffect(() => {
        async function getProfileInfo() {
            try {
                const profileEndpoint = config[environment].getProfileEndpoint(userId); 
                const response = await Axios.get(profileEndpoint);
                
                dispatch({type: 'catchUserProfileInfo', profileObject: response.data});
                dispatch({type: 'loadingDone'});
            } catch (e) {
                console.log(e.response);
            }

        }
        getProfileInfo();
    }, [config, environment,]);

    function PropertiesDisplay(){
        if(state.userProfile.sellerListings.length === 0){ 
            return (                           
            <Button disabled size="small">Nincs közzétett objektum</Button>
                   );                     
        }else{ 
            return (                         
            <Button size="small" onClick={()=>navigate(`/institutions/${state.userProfile.sellerId}`)}>{state.userProfile.sellerListings.length} közzétett objektum</Button>   
            );                           
        }
    }

    function WelcomeDisplay(){        
        if(state.userProfile.companyName === null || state.userProfile.companyName === '' ||state.userProfile.phoneNumber === null ||
         state.userProfile.phoneNumber === ''){
            console.log('nincs adat');
            return (
                <Typography variant="h5" 
                    style={{textAlign:'center', marginTop:'1rem'}} > Isten hozott <span style={{color: 'green', fontWeight: 'bolder'}}> {GlobalState.userUsername}</span>, 
                    <br/> Kérünk töltsd ki az alábbi ívet a profilod kiegészítéséhez! 
                </Typography>
            )
        }
        else {              
            return (
                <Grid container style={{width: '50%', marginLeft: 'auto', marginRight: 'auto', border:'3px solid grey', 
                        marginTop:"1rem", padding: '5px'}}>
                    <Grid item xs={6} sx={{marginTop: "1rem"}}>
                        <img src={state.userProfile.profilePic !== null ? state.userProfile.profilePic : defaultProfilePicture } alt="Profile picture" style={{width: "10rem", height: "10rem"}}/>
                    </Grid>
                    <Grid item container direction='column' justifyContent="center" xs={6}>
                        <Grid item>
                            <Typography variant="h5" 
                                style={{textAlign:'center', marginTop:'1rem'}} > Isten hozott 
                                <span style={{color: 'green', fontWeight: 'bolder'}}> {GlobalState.userUsername}</span>                            
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" 
                                style={{textAlign:'center', marginTop:'1rem'}} > Az Ön által beküldött ingatlanok, térképobjektumok, adatbázis cikkek:{PropertiesDisplay()}                                                           
                            </Typography>
                        </Grid>                  
                        
                    </Grid>
                </Grid>
            )
        }
    }

    if(state.dataIsLoading === true){
        return (<Grid container justifyContent="center" alignItems="center" style={{height:"100vh"}}>
          <CircularProgress/>
        </Grid>);
      }

  return (
    <>  
        <div>
        {WelcomeDisplay()}
        </div>        
        <ProfileUpdate userProfile={state.userProfile}/>
    </>
  );
}

export default Profile;
