import React, { useState, useEffect } from 'react';
import Axios from "axios";
import { useImmerReducer } from "use-immer";
import { useNavigate } from 'react-router-dom';

import {Grid, AppBar, Typography,  Card, CircularProgress,
    Box, Switch, ThemeProvider,  responsiveFontSizes, Checkbox, FormControlLabel} from "@mui/material";

import {createTheme} from '@mui/material/styles';
import "leaflet/dist/leaflet.css";
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import CardComponent from './PiacComponents/CardComponent';
import MapComponent from './PiacComponents/MapComponent';
import {Icons, RoomIcons} from '../../Utils/IconDisplay.js'; 
import { CategoryLabels, KeyLabels } from '../../Constants/IngatlanOptions.js';

let theme = createTheme();
theme = responsiveFontSizes(theme);

const CategoryTooltips = {
  'Ingatlan': 'Piacihirdetesek',
  'Auto': 'Piacihirdetesek',
  'Telisportfelszereles': 'Piacihirdetesek',
  'Nyarisportfelszereles': 'Piacihirdetesek',
  'Sajattermek': 'Piacihirdetesek',
  'udvarhaz': 'Epitettorokseg',
  'kastely': 'Epitettorokseg',  
  'kapolna': 'Epitettorokseg',
  'templom': 'Epitettorokseg',
  'var': 'Epitettorokseg',
  'motel': 'Szallas',
  'hosztel': 'Szallas',
  'hotel': 'Szallas',
  'hotel***': 'Szallas',
  'hotel****': 'Szallas',
  'satortabor': 'Szallas',
  'apartman': 'Szallas',
  'Szallasreggelivel': 'Szallas',
  'Szallásgyorsreggelivel': 'Szallas',  
  'Kemping': 'Szallas',
  'Satoreskemping': 'Szallas',  
  'Borviz': 'Asvanyviz',
  'Forras': 'Asvanyviz',
  'Termalforras': 'Asvanyviz',
  'Sizes': 'Sport',
  'Siberles': 'Sport',
  'Sifelvono': 'Sport',
  'Uszas': 'Sport',
  'Strand': 'Sport',
  'Uszoda': 'Sport',
  'Banya': 'Foldtaniobjektum',
  'Bezartbanya': 'Foldtaniobjektum',
  'Olajesgaz': 'Foldtaniobjektum',
  'Kilato': 'Foldtaniobjektum',
  'Iszapvulkan': 'Foldtaniobjektum',
  
};

const AdmLabels = {
  'kozsegvaros': 'Község, város',
  'telepules': 'Település',
  'szek': 'Szék',
  'megye': 'Megye',
  'helynev': 'Helynév',  
}

function Listings() { 
  const { config, environment } = require('../../index');
  const navigate = useNavigate();
  
  const iconMapping = {
    'Hosztel': Icons.hostelIcon,
    'Tombhazlakas': Icons.apartmentIcon,
    'Szallasreggelivel': Icons.bedandbreakfastIcon,
    'udvarhaz': Icons.udvarhazIcon,
    'motel': Icons.bedandbreakfastIcon,
    'Kemping': Icons.campingIcon,
    'Satoreskemping': Icons.tentandcampingIcon,
    'kapolna': Icons.chapelIcon,
    'templom': Icons.churchIcon,
    'var': Icons.varIcon,
    'Borviz': Icons.borvizIcon,
    'Forras': Icons.forrasIcon,
    'termalforras': Icons.hotspringIcon,
    'Hotel': Icons.hotelIcon,
    'Hotel***': Icons.hotel3starIcon,
    'Hotel****': Icons.hotel4starIcon,
    'Banya': Icons.mineIcon,
    'Bezartbanya': Icons.mineclosedIcon,
    'Olajesgaz': Icons.oilandgaswellIcon,
    'Kilato': Icons.panoramicviewIcon,
    'Sizes': Icons.sizesIcon,
    'Siberles': Icons.siberlesIcon,
    'Sifelvono': Icons.sifelvonoIcon,
    'Iszapvulkan': Icons.iszapvulkanIcon,
    'Strand': Icons.strandIcon,
    'Kastely': Icons.castleIcon,
    'Uszoda': Icons.uszodaIcon,
    'Uszas': Icons.uszasIcon,
    'Satortabor': Icons.satrazasIcon,
    'Ingatlan': RoomIcons.RoomIcon,
    'Auto': RoomIcons.RedRoomIcon,
    'Telisportfelszereles': RoomIcons.GreenRoomIcon,
    'Nyarisportfelszereles': RoomIcons.YellowRoomIcon,
    'Sajattermek': RoomIcons.OrangeRoomIcon,
  };
  
  const categories = {
    'Piacihirdetesek': ['Ingatlan', 'Auto', 'Telisportfelszereles', 'Nyarisportfelszereles', 'Sajattermek'],
    'Epitettorokseg': ['kapolna', 'templom', 'var', 'kastely','udvarhaz',],
    'Asvanyviz': ['Borviz', 'Forras', 'termalforras'],
    'Szallas': ['motel', 'Hosztel', 'Apartman', 'Szallasreggelivel',  'Kemping', 'Satoreskemping', 'Satortabor', 'Hotel', 'Hotel***', 'Hotel****'],
    'Sport': ['Sizes', 'Siberles', 'Sifelvono', 'Uszas', 'Strand', 'Uszoda'],
    'Foldtaniobjektum': ['Banya', 'Bezartbanya', 'Olajesgaz', 'Kilato', 'Iszapvulkan'],    
  };

  const admlayers = { 
    'helynev': 'helynev', 
    'megye': 'megye',
    'szek': 'szek',    
    'kozsegvaros': 'kozsegvaros',
    'telepules': 'telepules',    
  };
  
  const [selectedCategories, setSelectedCategories] = useState(['Piacihirdetesek']);
  const [selectedAdmLayers, setSelectedAdmLayers] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState(() => {
    const initialSelectedKeys = {};
    Object.keys(categories).forEach(category => {
      initialSelectedKeys[category] = {};
      categories[category].forEach(key => {
        initialSelectedKeys[category][key] = category === 'Piacihirdetesek'; // Set to true only for Piaci hirdetések category
      });
    });
    return initialSelectedKeys;
  });  

  const[latitude, setLatitude] = useState(45.8643);
  const[longitude, setLongitude] = useState(25.7848);  

  const initialState = {
      mapInstance: null,
      };
      function ReducerFunction(draft, action){
          switch(action.type){
              case 'getMap':
                  draft.mapInstance = action.mapData;
                  break;
          }
      }

  const [state, dispatch] = useImmerReducer(ReducerFunction, initialState)
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [flyToLocation, setFlyToLocation] = useState(null);

  const [allListings, setAllListings] = useState([]);  
  const [allBuiltHeritage, setAllBuiltHeritage] = useState([]);
  const [allAccommodation, setAllAccommodation] = useState([]);
  const [allParishes, setAllParishes] = useState([]);
  const [allTelepulesek, setAllTelepulesek] = useState([]);
  const [allSzekek, setAllSzekek] = useState([]);
  const [allMegyek, setAllMegyek] = useState([]);
  const [allHelynevek, setAllHelynevek] = useState([]);
  
 
 
  // Define state variables to store cleared data
  const [storedListings, setStoredListings] = useState({});
  const [storedHeritage, setStoredHeritage] = useState({});
  const [storedAccommodation, setStoredAccommodation] = useState({});
  const [storedParishes, setStoredParishes] = useState({});
  const [storedTelepulesek, setStoredTelepulesek] = useState({});
  const [storedSzekek, setStoredSzekek] = useState({});
  const [storedMegyek, setStoredMegyek] = useState({});
  const [storedHelynevek, setStoredHelynevek] = useState({});


  // Use useEffect to listen for changes in storedParishes
  useEffect(() => {
    // Restore cleared data
    setAllParishes(Object.values(storedParishes));
  }, [storedParishes]);

  useEffect(() => {
    setAllTelepulesek(Object.values(storedTelepulesek));
  }, [storedTelepulesek]);

  useEffect(() => {
    setAllSzekek(Object.values(storedSzekek));
  }, [storedSzekek]);

  useEffect(() => {
    setAllMegyek(Object.values(storedMegyek));
  }, [storedMegyek]);

  useEffect(() => {
    setAllHelynevek(Object.values(storedHelynevek));
  }, [storedHelynevek]);
 

  useEffect(() => {
    const source = Axios.CancelToken.source();
  
    async function GetAllListings() {
      if (selectedKeys['Piacihirdetesek']['Ingatlan']) { 
        try { 
          const ListingsEndpoint = config[environment].listingsEndpoint;       
          const response = await Axios.get(ListingsEndpoint, { cancelToken: source.token });
          setAllListings(response.data);
          setDataIsLoading(false);
  
          if (flyToLocation && state.mapInstance) {
            state.mapInstance.flyTo(flyToLocation, 16);
            setFlyToLocation(null); // Reset the flag after handling the deferred action.
          }
        } catch (error) {
          console.log(error.response);
        }
      } else {
        // If the Ingatlan checkbox is not checked, clear the listings data
        setAllListings([]);
      }
    }
    GetAllListings();
  
    return () => {
      source.cancel();
    };
  }, [config, environment, flyToLocation, state.mapInstance, selectedKeys['Piacihirdetesek']]);
  
  useEffect(() => {
    const source = Axios.CancelToken.source();
  
    async function GetAllBuiltHeritage() {
      if (selectedCategories.includes('Epitettorokseg')) { 
        try {
          const BuiltHeritageEndpoint = config[environment].builtHeritageEndpoint;
          const response = await Axios.get(BuiltHeritageEndpoint, { cancelToken: source.token });
          setAllBuiltHeritage(response.data);
          setDataIsLoading(false);
          
          if (flyToLocation && state.mapInstance) {
            state.mapInstance.flyTo(flyToLocation, 16);
            setFlyToLocation(null);
          }
        } catch (error) {
          console.log(error.response);
        }
      }
    }
    GetAllBuiltHeritage();
    return () => {
      source.cancel();
    };
  }, [config, environment,flyToLocation, state.mapInstance, selectedCategories]);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    async function GetAllAccommodation() {
      if (selectedCategories.includes('Szallas')) { // Check if Accommodation category is selected
        try {
          const AccommodationEndpoint = config[environment].accommodationEndpoint; 
          const response = await Axios.get(AccommodationEndpoint, { cancelToken: source.token });
          setAllAccommodation(response.data);
          setDataIsLoading(false);
          
          if (flyToLocation && state.mapInstance) {
            state.mapInstance.flyTo(flyToLocation, 16);
            setFlyToLocation(null);
          }
        } catch (error) {
          console.log(error.response);
        }
      }
    }
    GetAllAccommodation();
    return () => {
      source.cancel();
    };
  }, [config, environment,flyToLocation, state.mapInstance, selectedCategories]);  


  useEffect(() => {
    const source = Axios.CancelToken.source();
    async function getAllTelepulesek(page = 1) {
      if (selectedAdmLayers.some(admlayer => ['telepules'].includes(admlayer))) {
        const storedData = selectedAdmLayers.map(admlayer => storedParishes[admlayer]).filter(Boolean);
        if (storedData.length ===  selectedAdmLayers.length) {
          setAllTelepulesek(storedData);
        } else {
          try {
            const TelepulesEndpoint = config[environment].telepulesEndpoint(page);
            const response = await Axios.get(TelepulesEndpoint, { cancelToken: source.token });
            if (Array.isArray(response.data.results)) {
              setAllTelepulesek(prevTelepulesek => [...prevTelepulesek, ...response.data.results]);
            }
            setDataIsLoading(false);
            if (flyToLocation && state.mapInstance) {
              state.mapInstance.flyTo(flyToLocation, 16);
              setFlyToLocation(null);
            }
            if (response.data.next) {
              getAllTelepulesek(page + 1);
            }
          } catch (error) {
            if (Axios.isCancel(error)) {
              console.log('Request cancelled', error.message);
            } else {
              console.log(error.response);
            }
          }
        }
      } else {
        setAllTelepulesek([]);
      }
    }
    getAllTelepulesek();
    return () => {
      source.cancel();
    };
  }, [config, environment, flyToLocation, state.mapInstance, selectedAdmLayers, storedParishes]);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    async function GetAllParishes(page = 1) {
      if (selectedAdmLayers.some(parish => ['kozsegvaros'].includes(parish))) {
        // Check if data for the selected parishes already exists in storedParishes
        const storedData = selectedAdmLayers.map(parish => storedParishes[parish]).filter(Boolean);
        if (storedData.length ===  selectedAdmLayers.length) {
          // If all data exists, use the stored data
          setAllParishes(storedData);
        } else {
          // If not all data exists, fetch new data
          try {
            const ParishEndpoint = config[environment].parishEndpoint(page);
            const response = await Axios.get(ParishEndpoint, { cancelToken: source.token });
            if (Array.isArray(response.data.results)) {
              setAllParishes(prevParishes => [...prevParishes, ...response.data.results]);
            }
            setDataIsLoading(false);
            if (flyToLocation && state.mapInstance) {
              state.mapInstance.flyTo(flyToLocation, 16);
              setFlyToLocation(null);
            }
            // If there's a next page, fetch it
            if (response.data.next) {
              GetAllParishes(page + 1);
            }
          } catch (error) {
            if (Axios.isCancel(error)) {
              console.log('Request cancelled', error.message);
            } else {
              console.log(error.response);
            }
          }
        }
      } else {
        setAllParishes([]);
      }
    }
    GetAllParishes();
    return () => {
      source.cancel();
    };
  }, [config, environment, flyToLocation, state.mapInstance, selectedAdmLayers, storedParishes]);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    async function getAllSzekek(page = 1) {
      if (selectedAdmLayers.some(szek => ['szek'].includes(szek))) {
        const storedData = selectedAdmLayers.map(szek => storedSzekek[szek]).filter(Boolean);
        if (storedData.length ===  selectedAdmLayers.length) {
          setAllSzekek(storedData);
        } else {
          try {
            const SzekEndpoint = config[environment].szekEndpoint(page);
            const response = await Axios.get(SzekEndpoint, { cancelToken: source.token });
            if (Array.isArray(response.data.results)) {
              setAllSzekek(prevSzekek => [...prevSzekek, ...response.data.results]);
            }
            setDataIsLoading(false);
            if (flyToLocation && state.mapInstance) {
              state.mapInstance.flyTo(flyToLocation, 16);
              setFlyToLocation(null);
            }
            if (response.data.next) {
              getAllSzekek(page + 1);
            }
          } catch (error) {
            if (Axios.isCancel(error)) {
              console.log('Request cancelled', error.message);
            } else {
              console.log(error.response);
            }
          }
        }
      } else {
        setAllSzekek([]);
      }
    }
    getAllSzekek();
    return () => {
      source.cancel();
    };
  }, [config, environment, flyToLocation, state.mapInstance, selectedAdmLayers, storedSzekek]);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    async function getAllMegyek(page = 1) {
      if (selectedAdmLayers.some(megye => ['megye'].includes(megye))) {
        const storedData = selectedAdmLayers.map(megye => storedMegyek[megye]).filter(Boolean);
        if (storedData.length ===  selectedAdmLayers.length) {
          setAllMegyek(storedData);
        } else {
          try {
            const MegyeEndpoint = config[environment].megyeEndpoint(page);
            const response = await Axios.get(MegyeEndpoint, { cancelToken: source.token });
            if (Array.isArray(response.data.results)) {
              setAllMegyek(prevMegyek => [...prevMegyek, ...response.data.results]);
            }
            setDataIsLoading(false);
            if (flyToLocation && state.mapInstance) {
              state.mapInstance.flyTo(flyToLocation, 16);
              setFlyToLocation(null);
            }
            if (response.data.next) {
              getAllMegyek(page + 1);
            }
          } catch (error) {

            if (Axios.isCancel(error)) {
              console.log('Request cancelled', error.message);
            } else {
              console.log(error.response);
            }
          }
        }
      } else {
        setAllMegyek([]);
      }
    }
    getAllMegyek();
    return () => {
      source.cancel();
    };
  }, [config, environment, flyToLocation, state.mapInstance, selectedAdmLayers, storedMegyek]);

  useEffect(() => {
    const source = Axios.CancelToken.source();
    async function getAllHelynevek(page = 1) {
      if (selectedAdmLayers.some(helynev => ['helynev'].includes(helynev))) {
        const storedData = selectedAdmLayers.map(helynev => storedHelynevek[helynev]).filter(Boolean);
        if (storedData.length ===  selectedAdmLayers.length) {
          setAllHelynevek(storedData);
        } else {
          try {
            const HelynevEndpoint = config[environment].helynevEndpoint(page);
            const response = await Axios.get(HelynevEndpoint, { cancelToken: source.token });
            if (Array.isArray(response.data.results)) {
              setAllHelynevek(prevHelynevek => [...prevHelynevek, ...response.data.results]);
            }
            setDataIsLoading(false);
            if (flyToLocation && state.mapInstance) {
              state.mapInstance.flyTo(flyToLocation, 16);
              setFlyToLocation(null);
            }
            if (response.data.next) {
              getAllHelynevek(page + 1);
            }
          } catch (error) {
            if (Axios.isCancel(error)) {
              console.log('Request cancelled', error.message);
            } else {
              console.log(error.response);
            }
          }
        }
      } else {
        setAllHelynevek([]);
      }
    }
    getAllHelynevek();
    return () => {
      source.cancel();
    };
  }, [config, environment, flyToLocation, state.mapInstance, selectedAdmLayers, storedHelynevek]);

  if(dataIsLoading === true){
    return (<Grid container justifyContent="center" alignItems="center" sx={{height:"100vh"}}>
      <CircularProgress/>
    </Grid>);
  }

  const handleCategoryToggle = (category) => {
    const isSelected = selectedCategories.includes(category);    
    setSelectedCategories(prevSelected => {
      if (isSelected) {
        // Remove all layers belonging to the category
        if (category === 'Piacihirdetesek') {
          setAllListings([]);
        } else if (category === 'Epitettorokseg') {
          setAllBuiltHeritage([]);
        } else if (category === 'Szallas') {
          setAllAccommodation([]);
        }
      }
      return isSelected ? prevSelected.filter(cat => cat !== category) : [...prevSelected, category];
    });
    // Toggle all keys of the category
    setSelectedKeys(prevSelected => {
      const updatedSelectedKeys = { ...prevSelected };
      Object.keys(prevSelected[category]).forEach(key => {
        updatedSelectedKeys[category][key] = !isSelected;
      });
      return updatedSelectedKeys;
    });
  }; 

  // Update handleKeyToggle to store cleared data
  const handleKeyToggle = (category) => (key) => {
    setSelectedKeys(prevSelected => {
      const isSelected = prevSelected[category][key];
      const updatedSelectedKeys = {
        ...prevSelected,
        [category]: {
          ...prevSelected[category],
          [key]: !isSelected
        }
      };
      // If the key is currently selected, clear the related data
      if (isSelected) {
        if (category === 'Piacihirdetesek') {
          setAllListings(prevListings => {
            // Store cleared data
            setStoredListings({ ...storedListings, [key]: prevListings.filter(listing => listing.key === key) });
            return prevListings.filter(listing => listing.key !== key);
          });
        } else if (category === 'Epitettorokseg') {
          setAllBuiltHeritage(prevHeritage => {
            // Store cleared data
            setStoredHeritage({ ...storedHeritage, [key]: prevHeritage[key] });
            const updatedHeritage = { ...prevHeritage };
            updatedHeritage[key] = [];
            return updatedHeritage;
          });
        } else if (category === 'Szallas') {
          setAllAccommodation(prevAccommodation => {
            // Store cleared data
            setStoredAccommodation({ ...storedAccommodation, [key]: prevAccommodation[key] });
            const updatedAccommodation = { ...prevAccommodation };
            updatedAccommodation[key] = [];
            return updatedAccommodation;
          });
        }
      } else {
        // If the key is not currently selected, repopulate the related data
        if (category === 'Piacihirdetesek') {
          setAllListings(prevListings => [...prevListings, ...storedListings[key]]);
        } else if (category === 'Epitettorokseg') {
          setAllBuiltHeritage(prevHeritage => {
            const updatedHeritage = { ...prevHeritage };
            updatedHeritage[key] = storedHeritage[key];
            return updatedHeritage;
          });
        } else  if (category === 'Szallas') {
          setAllAccommodation(prevAccommodation => {
            const updatedAccommodation = { ...prevAccommodation };
            updatedAccommodation[key] = storedAccommodation[key];
            return updatedAccommodation;
          });
        }
      }
      return updatedSelectedKeys;
    });
  };

   
  const handleAdmLayerToggle = (admlayer) => {
    setSelectedAdmLayers(prevSelectedAdmLayers => {
      if (prevSelectedAdmLayers.includes(admlayer)) {
        // If the admlayer is already selected, remove it from the array
        // Store cleared data
        setStoredParishes(prevParishes => {
          const updatedParishes = { ...prevParishes };
          allParishes.forEach(parish => {
            updatedParishes[parish.uniqueid] = parish;
          });
          return updatedParishes;
        });
        setStoredTelepulesek(prevTelepulesek => {
          const updatedTelepulesek = { ...prevTelepulesek };
          allTelepulesek.forEach(telepules => {
            updatedTelepulesek[telepules.id] = telepules;
          });
          return updatedTelepulesek;
        });
        setStoredSzekek(prevSzekek => {
          const updatedSzekek = { ...prevSzekek };
          allSzekek.forEach(szek => {
            updatedSzekek[szek.uniqueid] = szek;
          });
          return updatedSzekek;
        });
        setStoredMegyek(prevMegyek => {
          const updatedMegyek = { ...prevMegyek };
          allMegyek.forEach(megye => {
            updatedMegyek[megye.id] = megye;
          });
          return updatedMegyek;
        });
        setStoredHelynevek(prevHelynevek => {
          const updatedHelynevek = { ...prevHelynevek };
          allHelynevek.forEach(helynev => {
            updatedHelynevek[helynev.id] = helynev;
          });
          return updatedHelynevek;
        });
        return prevSelectedAdmLayers.filter(parish => parish !== admlayer);
      } else {
        // If the admlayer is not selected, add it to the array
        return [...prevSelectedAdmLayers, admlayer];
      }
    });    
  };
  
  return (
    /* Cards*/ 
    <ThemeProvider theme={theme}>
  <Grid container>
    <Grid item xs={4}>
      <Grid container sx={{ display: 'flex', marginTop: '0.5rem',  overflow: 'auto' }} alignItems="center" justifyContent={'space-around'} flexWrap={'wrap'}>
        {allListings.map((listing) => {
          return (
            <Grid item xs={12} sm={12} md={6} lg={4} key={listing.id}>
               <CardComponent listing={listing} state={state} setFlyToLocation={setFlyToLocation} navigate={navigate} />
            </Grid>
          );
        })}
        {/* Add 3 invisible cards */}
        {[...Array(3)].map((_, i) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={i} style={{ visibility: 'hidden' }}>
            <Card />
          </Grid>
        ))}
      </Grid>
    </Grid>
    {/* Map */}
    <Grid item xs={8}>
      {/* Map content */}
      <AppBar position="sticky">
          <div id="map" style={{height: "100vh"}}>
            {/* Categories*/ }
            <Box
              style={{
                position: 'absolute',
                top: '50%',
                left: 10,
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                padding: 10,
                borderRadius: 5,
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                zIndex: 999,
                width: "10rem",
                maxHeight: 'calc(100vh - 200px)', 
                overflowY: 'auto',
              }}
            >
              {Object.entries(categories).map(([category, keys]) => (
                <div key={category}>
                  <Typography variant="body2" sx={{ color: 'black', textAlign: 'left' }}>{CategoryLabels[category]}</Typography>
                  {keys.map((key) => (
                    <div key={key}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedKeys[category][key]}
                            onChange={() => handleKeyToggle(category)(key)}
                            color="primary"
                            disabled={!selectedCategories.includes(category)}
                          />
                        }
                        label={<Typography variant="body2" sx={{ color: 'black' }}>{KeyLabels[key]}</Typography>} 
                      />
                    </div>
                  ))}
                </div>
              ))}
            </Box>
            <Box
              style={{
                position: 'absolute',
                top: '30%',
                right: 10,
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                padding: 10,
                borderRadius: 5,
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                zIndex: 999,
                width: "5rem",
              }}
            >
              {Object.keys(categories).slice(0, 5).map((category) => (
                <div key={category}>
                  <Typography variant="body2" sx={{ color: 'black', textAlign: 'left' }}>{CategoryLabels[category]}</Typography>
                  <Switch
                    checked={selectedCategories.includes(category)}
                    onChange={() => handleCategoryToggle(category)}
                    color="primary"
                  />
                </div>
              ))}
            </Box>

            <Box
              style={{
                position: 'absolute',
                top: '60%',
                right: 10,
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(255, 255, 255, 0.75)',
                padding: 10,
                borderRadius: 5,
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                zIndex: 999,
                width: "5rem",
              }}
            >
              {Object.keys(admlayers).slice(0, 5).map((admlayer) => (
                <div key={admlayer}>
                  <Typography variant="body2" sx={{ color: 'black', textAlign: 'left' }}>{AdmLabels[admlayer]}</Typography>
                  <Switch
                    checked={selectedAdmLayers.includes(admlayer)}
                    onChange={() => handleAdmLayerToggle(admlayer)}
                    color="primary"
                  />
                </div>
              ))}
            </Box>
            <MapComponent allListings={allListings} allAccommodation={allAccommodation} allBuiltHeritage={allBuiltHeritage} 
            allParishes={allParishes} allTelepulesek={allTelepulesek} 
            allMegyek={allMegyek} allSzekek={allSzekek} allHelynevek={allHelynevek}
            navigate={navigate} dispatch={dispatch} />
            
          </div>
        </AppBar>
    </Grid>
  </Grid>
</ThemeProvider>
  );}

export default Listings;