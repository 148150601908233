import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polygon, Tooltip, useMap } from 'react-leaflet';
import {Typography, Button } from "@mui/material"
import {  IconDisplay } from '../../../Utils/IconDisplay.js'; 
import L, { CRS } from 'leaflet';
import  {convertMultiPolygon, calculateCentroid} from '../../../Utils/ConvertGeom.js';
import { all } from 'axios';



const defaultIcon = new L.Icon({
    iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png',  
    iconSize: [25, 41],  
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });



 

  function MapComponentFunc({ dispatch }) {
    const map = useMap();      
    useEffect(() => {         
      dispatch({ type: 'getMap', mapData: map });      
      return () => {
      };
    }, [dispatch, map]);  
    return null;
}

const KozsegVarosComponent = ({parish, index}) => {
  const map = useMap();
  let positions;
  let centroid;
  if (parish.geom.type === 'Polygon') {
      positions = parish.geom.coordinates[0].map(coord => [coord[1], coord[0]]); // swap lat and lon
      centroid = calculateCentroid(positions); // calculate centroid
  } else if (parish.geom.type === 'MultiPolygon') {
      // For MultiPolygon geometry, use the convertMultiPolygon function
      positions = convertMultiPolygon(parish.geom);
      centroid = calculateCentroid(positions); // calculate centroid
  } else {
      console.error(`Unsupported geometry type: ${parish.geom.type}`);
      return null;
  }
  const zoomLevelToShowTooltip = 11;
  return (
      <Polygon
          key={index}
          positions={positions}
          color="red"
          fillColor="#f03"
          fillOpacity={0}
          weight={1}
      >
          {map.getZoom() > zoomLevelToShowTooltip && (
                          <Tooltip permanent direction="center" opacity={1} className="polygon-label" offset={[0, 0]}>
                              <span className="polygon-sic-label">{parish.nevsic}</span><br />
                              <span className="polygon-hu-label">{parish.nev}</span><br />
                              <span className="polygon-ro-label">{parish.name}</span>
                          </Tooltip>
                      )}
      </Polygon>
  );
}

const TelepulesComponent = ({telepules, index}) => {
  const map = useMap();
  let positions;
  let centroid;
  if (telepules.geom.type === 'Polygon') {
      positions = telepules.geom.coordinates[0].map(coord => [coord[1], coord[0]]); // swap lat and lon
      centroid = calculateCentroid(positions); // calculate centroid
  } else if (telepules.geom.type === 'MultiPolygon') {
      positions = convertMultiPolygon(telepules.geom);
      centroid = calculateCentroid(positions); // calculate centroid
  } else {
      console.error(`Unsupported geometry type: ${telepules.geom.type}`);
      return null;
  }
  const zoomLevelToShowTooltip = 11;
  return (
      <Polygon
          key={index}
          positions={positions}
          color="grey"          
          fillOpacity={0.2}
      >
          {map.getZoom() > zoomLevelToShowTooltip && (
              <Tooltip permanent direction="right" opacity={0.7} className="telepules-label" offset={[0, 0]}>                  
                  <span className="telepules-hu-label">{telepules.nev}</span><br />
                  <span className="alapitva-label">{telepules.alapitva}</span>
              </Tooltip>
          )}
      </Polygon>
  );
}

const SzekComponent = ({szek, index}) => {
  const map = useMap();
  let positions;
  let centroid;
  if (szek.geom.type === 'Polygon') {
      positions = szek.geom.coordinates[0].map(coord => [coord[1], coord[0]]); // swap lat and lon
      centroid = calculateCentroid(positions); // calculate centroid
  } else if (szek.geom.type === 'MultiPolygon') {
      positions = convertMultiPolygon(szek.geom);
      centroid = calculateCentroid(positions); // calculate centroid
  } else {
      console.error(`Unsupported geometry type: ${szek.geom.type}`);
      return null;
  }
  const zoomLevelToShowTooltip = 8;
  return (
      <Polygon
          key={index}
          positions={positions}
          color="#FFD700"
          fillColor="#2492be"
          fillOpacity={0}
          weight={3}
      > 
          {map.getZoom() > zoomLevelToShowTooltip && (
              <Tooltip permanent direction="center" opacity={0.8} className="szek-label" offset={[0, 0]}>
                  <span className="szek-hu-label">{szek.nev}</span><br />
                  <span className="szek-lat-label">{szek.name}</span>
              </Tooltip>
          )}
      </Polygon>
  );
}

const MegyeComponent = ({megye, index}) => {
  const map = useMap();
  let positions;
  let centroid;
  if (megye.geom.type === 'Polygon') {
      positions = megye.geom.coordinates[0].map(coord => [coord[1], coord[0]]); // swap lat and lon
      centroid = calculateCentroid(positions); // calculate centroid
  } else if (megye.geom.type === 'MultiPolygon') {
      positions = convertMultiPolygon(megye.geom);
      centroid = calculateCentroid(positions); // calculate centroid
  } else {
      console.error(`Unsupported geometry type: ${megye.geom.type}`);
      return null;
  }
  const zoomLevelToShowTooltip = 8;
  return (
      <Polygon
          key={index}
          positions={positions}
          color="#012837" 
          fillOpacity={0}
          weight={3} 
          pathOptions={{dashArray: '5, 10'}}        
      >
          {map.getZoom() > zoomLevelToShowTooltip && (
              <Tooltip permanent direction="center" opacity={0.8} className="megye-label" offset={[0, 0]}>
                  
                  <span className="megye-ro-label">{megye.name}</span>
              </Tooltip>
          )}
      </Polygon>
  );
}

const HelynevComponent = ({helynev}) => {
  const map = useMap();
  let position;
  if (helynev.geom.type === 'Point') {
      position = [helynev.geom.coordinates[1], helynev.geom.coordinates[0]]; // swap lat and lon
  } else {
      console.error(`Unsupported geometry type: ${helynev.geom.type}`);
      return null;
  }
  const icon = IconDisplay('csucs');
  return (
      <Marker
          key={helynev.id}
          position={position} 
          icon={icon ? icon : defaultIcon }
          opacity={1}       
      >
          <Tooltip  direction="top" opacity={0.7} className="helynev-label" offset={[0, 0]}>
              <span className="helynev-hu-label">{helynev.nev}</span>
          </Tooltip>
      </Marker>
  );
}

const MapComponent = React.memo(({ allListings, allBuiltHeritage, allAccommodation, allParishes, allTelepulesek,
  allSzekek, allMegyek, allHelynevek,  navigate, dispatch }) => {
    
    return (
    <MapContainer center={[45.8643, 25.7848]} zoom={14} scrollWheelZoom={true} className='leaflet-container'>
    <TileLayer                    
      attribution = 'Map style: <a href="https://github.com/cyclosm/cyclosm-cartocss-style/releases" title="CyclOSM - Open Bicycle render">CyclOSM</a> | Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url = 'https://{s}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png'

    />
    <MapComponentFunc dispatch={dispatch}/>
    
    {allListings.map((listing) => {
        const icon = IconDisplay(listing.listing_type);
        return(
            <Marker 
                key={listing.id}
                icon ={icon ? icon : defaultIcon }
                position={[listing.latitude, listing.longitude ]}>
                <Popup>
                    <Typography variant="h5">{listing.title}</Typography>
                    <div style={{ maxHeight: "14rem", maxWidth: "20rem", overflow: "hidden" }}>
                        <img 
                            src={listing.picture1} 
                            alt={listing.title} 
                            style={{ width: "100%", height: "auto", cursor: 'pointer' }}
                            onClick={() => navigate(`/listings/${listing.id}`)} 
                        />
                    </div>
                    <Typography variant="body1">{listing.description.substring(0, 150)} ...</Typography>
                    <Button variant="contained" fullWidth onClick={() => navigate(`/listings/${listing.id}`)}>Részletek</Button>
                </Popup>                       
            </Marker>
        )
    })} 

    {Object.entries(allBuiltHeritage).map(([category, items]) => (
      items.map((bh) => {
        const icon = IconDisplay(category);
        let position;
        
        if (bh.geom.type === 'Point') {                    
          position = [bh.geom.coordinates[1], bh.geom.coordinates[0]];
        } else if (bh.geom.type === 'MultiPolygon') {
          // For MultiPolygon geometry, find the centroid
          const coordinates = bh.geom.coordinates[0][0]; // Extract coordinates of the first polygon
          const sum = coordinates.reduce((acc, coord) => [acc[0] + coord[0], acc[1] + coord[1]], [0, 0]);
          const centroid = [sum[0] / coordinates.length, sum[1] / coordinates.length];
          position = centroid;          
        } else {
          console.error(`Unsupported geometry type: ${bh.geom.type}`);
          return null;
        }
        return (
          <Marker
            key={bh.id}
            icon={icon ? icon : defaultIcon}
            position={position}
          >
            <Popup>
              <Typography variant="h5">{bh.nev}</Typography>
              <div style={{ maxHeight: "14rem", maxWidth: "20rem", overflow: "hidden" }}>
                <img
                  src={bh.picture1}
                  alt={bh.nev}
                  style={{ width: "100%", height: "auto", cursor: 'pointer' }}
                  onClick={() => navigate(`/built_heritage/${bh.id}`)}
                />
              </div>
              <Button variant="contained" fullWidth onClick={() => navigate(`/built_heritage/${bh.id}`)}>Részletek</Button>
            </Popup>
          </Marker>
        );
      })
    ))}  

    {Object.entries(allAccommodation).map(([category, items]) => (
      items.map((acc) => {
        const icon = IconDisplay(category);
        let geometries = [];
        if (acc.geom.type === 'Point') {
          geometries.push(
            <Marker
              key={acc.id}
              icon={icon ? icon : defaultIcon}
              position={[acc.geom.coordinates[1], acc.geom.coordinates[0]]}
            >
              <Popup>
                <Typography variant="h5">{acc.name}</Typography>
                <div style={{ maxHeight: "14rem", maxWidth: "20rem", overflow: "hidden" }}>
                  <img
                    src={acc.picture1}
                    alt={acc.nev}
                    style={{ width: "100%", height: "auto", cursor: 'pointer' }}
                    onClick={() => navigate(`/accommodation/${acc.id}`)}
                  />
                </div>
                <Button variant="contained" fullWidth onClick={() => navigate(`/accommodation/${acc.id}`)}>Részletek</Button>
              </Popup>
            </Marker>
          );
        } else if (acc.geom.type === 'MultiPolygon') {
          acc.geom.coordinates.forEach((polygon, index) => {
            // Calculate centroid of polygon
            let centroidX = 0;
            let centroidY = 0;
            for (let i = 0; i < polygon[0].length; i++) {
              centroidX += polygon[0][i][0];
              centroidY += polygon[0][i][1];
            }
            centroidX /= polygon[0].length;
            centroidY /= polygon[0].length;

            // Create marker for centroid
            geometries.push(
              <Marker
                key={`${acc.id}-${index}`}
                position={[centroidY, centroidX]}
                icon={icon ? icon : defaultIcon}
              >
                <Popup>
                  <Typography variant="h5">{acc.name}</Typography>
                  <div style={{ maxHeight: "14rem", maxWidth: "20rem", overflow: "hidden" }}>
                    <img
                      src={acc.picture1}
                      alt={acc.nev}
                      style={{ width: "100%", height: "auto", cursor: 'pointer' }}
                      onClick={() => navigate(`/accommodation/${acc.id}`)}
                    />
                  </div>
                  <Button variant="contained" fullWidth onClick={() => navigate(`/accommodation/${acc.id}`)}>Részletek</Button>
                </Popup>
              </Marker>
            );

            // Create polygon
            geometries.push(
              <Polygon
                key={`${acc.id}-polygon-${index}`}
                positions={polygon[0].map(coords => [coords[1], coords[0]])}
              />
            );
          });
        } else {
          console.error(`Unsupported geometry type: ${acc.geom.type}`);
        }
        return geometries;
      }).flat() 
    ))}

    


{allParishes.map((parish, index) => (
    <KozsegVarosComponent parish={parish} index={index} />
))}
{allTelepulesek.map((telepules, index) => (
    <TelepulesComponent telepules={telepules} index={index} />
))}
{allSzekek.map((szek, index) => (
    <SzekComponent szek={szek} index={index} />
))}
{allMegyek.map((megye, index) => (
    <MegyeComponent megye={megye} index={index} />
))}
{allHelynevek.map((helynev, index) => (
    <HelynevComponent helynev={helynev} index={index} />
))}
   
                  
  </MapContainer>
    );
});


export default MapComponent;