export const listingTypeOptions=[
    {value: '', label: '',},
    {value: 'Szanto', label: 'Szántó',},
    {value: 'Beltelek', label: 'Beltelek',},
    {value: 'Kaszalo', label: 'Kaszáló',},
    {value: 'Erdo', label: 'Erdő',},
    {value: 'Tombhazlakas', label: 'Tömbházlakás',},
    {value: 'Garzon', label: 'Garzon',},
    {value: 'Csaladihaz', label: 'Családiház',},
    {value: 'Hetvegihaz', label: 'Hétvégiház',},
    {value: 'Kulcsoshaz', label: 'Kulcsosház',}, 
    {value: 'Udvarhaz', label: 'Udvarház',},
    {value: 'Kastely', label: 'Kastély',},
    {value: 'Hotel', label: 'Hotel',},
    {value: 'Panzio', label: 'Panzió',},
    {value: 'Kiadoszoba', label: 'Kiadó szoba',},
    {value: 'Iroda', label: 'Iroda',},   
    {value: 'Ipariepulet', label: 'Ipari épület',},
    {value: 'Kereskedelmiingatlan', label: 'Kereskedelmi ingatlan',},    
    {value: 'Egyeb', label: 'Egyéb',},
]

export const propertyStatusOptions=[
    {value: '', label: '',},
    {value: 'Elado', label: 'Eladó',},
    {value: 'Kiado', label: 'Kiadó',}, 
    {value: 'Hosszutavonberelheto', label: 'Hosszútávon bérelhető',},
    {value: 'Zarva', label: 'Zárva',},
    {value: 'Tervezett', label: 'Tervezett',},
    {value: 'Epulofelben', label: 'Épülőfélben',},    
]

export const rentalFrequencyOptions=[
    {value: '', label: '',},
    {value: 'Month', label: 'Havi',},
    {value: 'Week', label: 'Heti',},
    {value: 'Day', label: 'Napi',}, 
]

export const CategoryLabels = {
    'Piacihirdetesek': 'Piaci hirdetések',
    'Epitettorokseg': 'Épített örökség',
    'Asvanyviz': 'Ásványvíz',
    'Szallas': 'Szállás',
    'Sport': 'Sport',
    'Foldtaniobjektum': 'Földtani objektum',
};

export const KeyLabels = {
    'Ingatlan': 'Ingatlan',
    'Auto': 'Autó',
    'Telisportfelszereles': 'Téli sportfelszerelés',
    'Nyarisportfelszereles': 'Nyári sportfelszerelés',
    'Sajattermek': 'Saját termék',
    'Hosztel': 'Hosztel',
    'kastely': 'Kastély',
    'Hotel': 'Hotel',
    'Panzio': 'Panzió',
    'Kiadoszoba': 'Kiadó szoba',
    'Tombhazlakas': 'Tömbházlakás',
    'Szallasreggelivel': 'Szállás reggelivel',
    'Kemping': 'Kemping',
    'Satortabor': 'Sátor tábor',
    'kapolna': 'Kápolna',
    'templom': 'Templomerőd',
    'var': 'Vár',
    'motel': 'Panzió',
    'udvarhaz': 'Udvarház',
    'Satoreskemping': 'Sátor és kemping',
    'Apartman': 'Apartman',
    'Hotel***': 'Hotel***',
    'Hotel****': 'Hotel****',
    'Sizes': 'Sízés',
    'Siberles': 'Síbérles',
    'Sifelvono': 'Sífelvonó',
    'Uszas': 'Úszás',
    'Strand': 'Strand',
    'Uszoda': 'Úszóda',
    'Banya': 'Bánya',
    'Bezartbanya': 'Bezárt bánya',
    'Olajesgaz': 'Olaj és gáz',
    'Kilato': 'Kilátó',
    'Iszapvulkan': 'Iszapvulkán',
    'Forras': 'Forrás',
    'termalforras': 'Termálforrás',
    'Borviz': 'Borvíz',
};


