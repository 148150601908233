import React from 'react';
import { Card, CardHeader, CardMedia, CardContent, Typography, CardActions, IconButton } from '@mui/material';
import RoomIcon from '@mui/icons-material/Room';

const CardComponent = ({ listing, state, setFlyToLocation, navigate }) => {
    return (
    <Card key={listing.id} sx={{ margin: "0.5rem", border: '1px solid black', position: 'relative', height: '25%', 
    flex: '1 0 250px', boxSizing: 'border-box' }}>
      {/* Card content */}
      <CardHeader            
        action={
          <IconButton
              aria-label="settings"
              onClick={() => {
                if (state.mapInstance) {
                  state.mapInstance.flyTo([listing.latitude, listing.longitude], 16);
                } else {                        
                  setFlyToLocation([listing.latitude, listing.longitude]);
                }
              }}
            >
            <RoomIcon />
          </IconButton>
        } 
        title={listing.title}              
      />
      <CardMedia
        sx={{paddingRight: "1rem",
                paddingLeft: "1rem",
                objectFit: "cover", height: 140, width: "100%",
                cursor: 'pointer'}}
                component="img"                      
                image={listing.picture1}
                alt={listing.title}
                onClick={() => navigate(`/listings/${listing.id}`)}
      />
      <CardContent>
        <Typography variant="body2" >
          {listing.description.substring(0, 200)}...
        </Typography>
      </CardContent>

      {listing.property_status === "Sale" ? (
        <Typography sx={{position: 'absolute',
                          backgroundColor: "green",
                          zIndex: "1000",
                          color: 'white',
                          top: '100px',
                          left: '20px',
                          padding: '5px'
      }}>{listing.listing_type}: {listing.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} RON</Typography>
      ) : (
        <Typography sx={{position: 'absolute',
                      backgroundColor: "green",
                      zIndex: "1000",
                      color: 'white',
                      top: '100px',
                      left: '20px',
                      padding: '5px'
      }}>{listing.listing_type}: {listing.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} RON / {listing.rental_frquency}</Typography>
      )
      }
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          {listing.seller_company_name}
        </IconButton>                    
      </CardActions>
    </Card>
    );
};

export default CardComponent;