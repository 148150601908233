import React from 'react'
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from 'react-ui'
import { tokens, components } from 'react-ui/themes/light'
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './config';

const environment = 'production';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider tokens={tokens} components={components}>
      
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();

export { config, environment };