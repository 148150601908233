import React, { useState, useRef, useEffect } from 'react'
import { Card, CardContent, CardMedia, Typography, Grid, Paper, Slider } from '@mui/material';

import gestaHungarorum from '../../Assets/Kutfok/gestahungarorum.png'
import nicholsburgi from '../../Assets/Kutfok/nikolsburgi.jpg'
import nemesSzekelyNemzetnekConstitutiói from '../../Assets/Kutfok/nemesszekelyconstitucioi.png'
import csikiSzekelyKronika from '../../Assets/Kutfok/csikiszekely.jpg'
import kajoniKodex from '../../Assets/Kutfok/kajonicodex.jpg'
import josephinischeLandesaufnahme from '../../Assets/Kutfok/josephinische.jpg'
import annalesVeteresHunnorum from '../../Assets/Kutfok/guignes.jpg'
import erdelyiHaromNemzetekbokAllRendeknekJegyzokonyve from '../../Assets/Kutfok/haromnemzetk.jpg'
import historiErtekezesANemesSzekelyNemzetEredeterol from '../../Assets/Kutfok/kallay.jpg'
import imagoNationisSiculicaeHistoricoPolitica from '../../Assets/Kutfok/benkoimago.jpg'
import zandirham from '../../Assets/Kutfok/zandirham.jpg'
import szekelyNepmesek from '../../Assets/Kutfok/szekelynepmesek.jpg'
import szekelyfoldLeirasa from '../../Assets/Kutfok/orbanbalazs.jpg'
import parhuzamAMagyarEsMongolNyelvTerén from '../../Assets/Kutfok/balintgabor.jpg'
import szekelyfoldFoldtaniTerkepe from '../../Assets/Kutfok/herbich.jpg'
import szekelyekScythaHunEredete from '../../Assets/Kutfok/nagyjanos.png'
import szekelyekErdelyben from '../../Assets/Kutfok/szekelysandor.jpg'
import szekelyekNepballadak from '../../Assets/Kutfok/ortutay.png'
import szekelyOsvarakTorteneteMondajaEsLegenda from '../../Assets/Kutfok/szekelyosvarak.jpg'
import aNemesSzekelyNemzetKepe from '../../Assets/Kutfok/rugonfalvi.png'
import adatokASzekelyfoldNepvandorlaskorahoz from '../../Assets/Kutfok/epresteto.jpg'
import szekelyekUnneploben from '../../Assets/Kutfok/szekelyekunneploben.jpg'
import szekelyNemessagKialakulasanakFolyamata from '../../Assets/Kutfok/szekelynemesseg.png'
import hunokASelyemuton from '../../Assets/Kutfok/hunokaselyemuton.jpg'
import jelkepek from '../../Assets/Kutfok/mihalyjanos.jpg'
import temetok from '../../Assets/Kutfok/galerwin.jpg'
import szinesszottes from '../../Assets/Kutfok/konyaadam.png'
import budzsak from '../../Assets/Kutfok/budzsak.png'
import attila from '../../Assets/Kutfok/attila.jpg'
import buhnagy from '../../Assets/Kutfok/buhnagy.jpg'
import albertballadak from '../../Assets/Kutfok/albertballadak.png'
import rovasiras from '../../Assets/Kutfok/feherbence.jpg'
import eszkilek from '../../Assets/Kutfok/sudar.png'
import placeholder from '../../Assets/Kutfok/placeholder-kutfo.png'



const kutfok = [
    {  title: 'Gesta Hungarorum', author: 'Anonymus' , content: '“Et inde equitantes iuxta fluuium Couroug (Kórógy) castra metati sunt, et omnes qui primo erant <<populi Atthyle Regis>> audita fama usubuu obuiam pacifici uenerunt, et sua sponte filios suos cum diuersis muneribus in obsides dederunt.”', 
        year: "~1283",
        relatedPub: '', coverimage: gestaHungarorum,
        link: 'http://mek.niif.hu/19800/19808/'},

    {  title: 'Nikolsburgi Székely Ábécé', author: '' , content: 'Az első módszeresen lejegyzett székely rovásírás ábécé. [pergamen]', year: 1483, 
        coverimage: nicholsburgi, relatedPub: '', 
        link: ''},

    {  title: 'A Nemes Székely Nemzetnek Constitutiói', author: '' , content: '"Mi az egész Székelly Földének lakossi, és minden Székeknek akár mitsoda renden lévői, adjuk emlékezetekre mindeneknek, akiknek illik..."', year: 1505,
        link: 'https://books.google.ro/books?id=oQdUAAAAcAAJ&pg=PA121&lpg=PA121&dq=szekely%20constitutio&source=bl&ots=_QrW0YtsSg&sig=7VbWsJ9RQsT0KtJ_A7OXOOk4klk&hl=hu&sa=X&ved=0ahUKEwi5rM3E7JXXAhXDZVAKHfk7BuwQ6AEIPjAF&fbclid=IwAR3Y4iLbFwxq644vYAwRUU4bXpf1GGk12cSJ0XtbMSv-KKwevO-jPNwmajo#v=onepage&q=szekely%20constitutio&f=true', 
        relatedPub: '', coverimage: nemesSzekelyNemzetnekConstitutiói},

    {  title: 'Csíki Székely Krónika', author: '' , content: 'kelt Sándor Menyhért várában, Csíkszentmihály', year: 1533,
        relatedPub: 'https://www.academia.edu/42774725/Hamis%C3%ADtv%C3%A1ny_e_a_Cs%C3%ADki_sz%C3%A9kely_kr%C3%B3nika?f_ri=746961', coverimage: csikiSzekelyKronika, 
        link: 'https://dspace.bcucluj.ro/handle/123456789/47798?fbclid=IwAR2J9jqx-cW5xKXqxA3Ve0fOPqfol46LHWrp4YK7jXlWMaxVFVgCsJxt6i4'},

    {title: 'Kájoni-kódex', author: '' , content: '', year: 1671,
        relatedPub: '', coverimage: kajoniKodex,
        link: ''},

    {  title: 'Székelyföld - Josephinische Landesaufnahme', author: '' , content: 'Székelyföld első ismert térképe', year: 1773,
        relatedPub: '', coverimage: josephinischeLandesaufnahme,
        link: ''},

    {  title: 'Annales veteres Hunnorum, Avarum et Hungarorum', author: 'Joseph de Guignes' , content: 'A hunok keresésére indítja Kőrösi Csoma Sándort [Obrusánszky, 2016]', year: 1775,
        relatedPub: '', coverimage: annalesVeteresHunnorum,
        link: ''},

    {title: 'A moldovai magyarokról', author: 'Zöld Péter' , content: '', year: 1783,
        relatedPub: '', coverimage: null,
        link: ''
        },

    {  title: 'Az erdélyi három nemzetekbők álló rendeknek jegyzőkönyve', author: '' , content: 'rendi gyűlési jegyzőkönyvek', year: 1790,
        relatedPub: '', coverimage: erdelyiHaromNemzetekbokAllRendeknekJegyzokonyve, 
        link: ''},

    {title: 'Históriai értekezés a nemes székely nemzet eredetéről', author: 'Kállay Ferentz' , content: '', year: 1829,
        relatedPub: '', coverimage: historiErtekezesANemesSzekelyNemzetEredeterol, 
        link: 'https://mek.oszk.hu/03100/03187/03187.htm?fbclid=IwAR0mBJ48pdRUa7jPyfOMybi4PNRVo8RruBqJ6sPYCx4sbPTr6F9hbFMU1AI#4'}, 

    {  title: 'Imago Nationis Siculicae historico-politica', author: 'Benkő József' , content: '', year: 1837,
        relatedPub: '', coverimage: imagoNationisSiculicaeHistoricoPolitica,
        link: 'https://mek.oszk.hu/12600/12609/12609.pdf?fbclid=IwAR2DRLraL8pjpjwzmDuojilBieTEFS9cEPkfCEnZwkWxFNUayKBmHov4jpc'},

    {  title: 'Zandirhám', author: 'Dózsa Dániel' , content: 'Hősköltemény a kilencedik századról', year: 1858,
        relatedPub: '', coverimage: zandirham, 
        link: 'https://mek.oszk.hu/22800/22827/?fbclid=IwAR2sfodhr01v2m-X9DSApcPh_eqHrRKVDCr7nlLyl6M99tkG00aZgluRYak'},

    {  title: 'Székely népmesék ', author: 'Kriza János' , content: '', year: 1863,
        relatedPub: '', coverimage: szekelyNepmesek,
        link: ''},

    {  title: 'A Székelyföld leírása ', author: 'Orbán Balázs' , content: '', year: 1868,
        relatedPub: '', coverimage: szekelyfoldLeirasa,
        link: 'https://archive.org/details/szkelyfldle0506orbuoft'},

    {  title: 'Párhuzam a magyar és mongol nyelv terén', author: 'Bálint Gábor' , content: '', year: 1877,
        relatedPub: 'https://m.nyest.hu/hirek/meltatlan-nyelvek-csiri-biri-nepecskek?fbclid=IwAR2UMG6y8upTjsbu-ZJbwGMYTk1Fnmbdmu51J3P8RjWM3xfOZ47RWStfTN4', coverimage: parhuzamAMagyarEsMongolNyelvTerén, 
        link: ''},

    {title: 'A Székelyföld földtani térképe/ Geologische Karte des Széklerlandes', author: 'Herbich Ferencz' , content: '', year: 1878,
        relatedPub: '', coverimage: szekelyfoldFoldtaniTerkepe, 
        link: ''},

    {  title: 'A székelyek Scytha-Hún eredetűsége és az ellenvélemények', author: 'Nagy János' , content: '', year: 1879,
        relatedPub: '', coverimage: szekelyekScythaHunEredete,
        link: ''},

    {  title: 'A székelyek Erdélyben', author: 'Kaáli (Aranyos-rákosi) Székely Sándor ', content: 'A Hadúr isten mítosz megteremtője (vagy gyűjtője és közlője), Tolnai szerint, tőle veszi át Vörösmarty is.', year: 1881,
        relatedPub: '', coverimage: szekelyekErdelyben,
        link:''},

    {  title: 'Székely népballadák', author: 'Ortutay Gyula' , content: '', year: 1935,
        relatedPub: '', coverimage: szekelyekNepballadak,
        link: ''},

    {  title: 'A székely ősvárak története, mondája és legendája', author: 'Gaáli Zoltán' , content: '', year: 1938,
        relatedPub: '', coverimage: szekelyOsvarakTorteneteMondajaEsLegenda,
        link: ''},

    {  title: 'A Nemes Székely Nemzet képe', author: 'Rugonfalvi Kis István' , content: '', year: 1939, 
        relatedPub: 'https://adatbank.ro/html/cim_pdf2440.pdf', coverimage: aNemesSzekelyNemzetKepe,
        link: 'https://adatbank.ro/html/cim_pdf2440.pdf'},

    {  title: 'Adatok a Székelyföld népvándorláskorához', author: 'Székely Zoltán' , content: '', year: 1945,
        relatedPub: '', coverimage: adatokASzekelyfoldNepvandorlaskorahoz,
        link: ''},

    {title: 'Székelyek ünneplőben (színek és formák a székely népviseletben)', author: 'Gazda Klára és Haáz Sándor' , content: '', year: 1998,
        relatedPub: '', coverimage: szekelyekUnneploben,
        link: ''},

    {  title: 'A székely nemesség kialakulásának folyamata a 17. század első felében', author: 'Balogh Judit' , content: '', year: 2005,
        relatedPub: '', coverimage: szekelyNemessagKialakulasanakFolyamata, 
        link:'https://mek.oszk.hu/03100/03187/03187.htm?fbclid=IwAR0mBJ48pdRUa7jPyfOMybi4PNRVo8RruBqJ6sPYCx4sbPTr6F9hbFMU1AI#4'},

    {  title: 'Hunok a selyemúton', author: 'Obrusánszky Borbála' , content: '', year: 2008,
        relatedPub: '', coverimage: hunokASelyemuton,
        link: ''},

    {  title: 'Jelképek a Székelyföldön: címerek, pecsétek, zászlók', author: 'Mihály János' , content: '', year: 2011,
        relatedPub: '', coverimage: jelkepek,
        link: ''},

    {  title: 'Az Erdélyi-medence, a Partium és a Bánság 10-11. századi temetői', author: 'Gáll Erwin' , content: '', year: 2013,
        relatedPub: '', coverimage: temetok,
        link: 'https://real.mtak.hu/34590/'},

    {  title: 'Háromszéki színes szőttes', author: 'Kónya Ádám' , content: '', year: 2014,
        relatedPub: '', coverimage: szinesszottes,
        link: ''},

    {  title: 'Székelyek az 5-11. században', author: 'Sántha Attila' , content: '', year: 2016,
        relatedPub: '', coverimage: budzsak,
        link: 'https://www.academia.edu/41431327/Sz%C3%A9kelyek_az_5_11_sz%C3%A1zadban'},

    {  title: 'Európa ura, Attila', author: 'Obrusánszky Borbála' , content: '', year: 2016,
        relatedPub: '', coverimage: attila,
        link: ''},

    {  title: 'Bühnagy Székely Szótár', author: 'Sántha Attila' , content: '', year: 2018,
        relatedPub: '', coverimage: buhnagy,
        link: ''},

    {  title: 'Háromszéki népballadák', author: 'Albert Ernő' , content: '', year: 1973,
        relatedPub: '', coverimage: albertballadak,
        link: ''},

    {  title: 'A Kárpát-medencei rovásírásos emlékek gyűjteménye', author: 'Fehér Bence' , content: '', year: 2020,
        relatedPub: '', coverimage: rovasiras,
        link: 'https://mki.gov.hu/hu/e-konyvtar-hu/kiadvanyok-hu/karpat-medencei-rovasirasos-emlekek-gyujtemenye-feher-bence' },

    {  title: 'Székelyek és eszekelek. Megfontolások a székelyek őstörténet kapcsán', author: 'Sudár Balázs' , content: '', year: 2022,
        relatedPub: '', coverimage: eszkilek,
        link: '' },

];

    function KnowledgeBase() {

        

       
        // Initial selected year
        const [selectedYear, setSelectedYear] = useState(
            kutfok.length > 0 && kutfok.every(item => !isNaN(item.year)) 
            ? Math.min(...kutfok.filter(item => !isNaN(item.year)).map((item) => item.year)) 
            : 0 // default value
        );
        const scrollRef = useRef(null);
        const itemRefs = useRef([]); 

        const [itemsWithRefs, setItemsWithRefs] = useState([]);

        const refs = kutfok.map(() => React.createRef());


        useEffect(() => {
            setItemsWithRefs(kutfok.map(item => ({ ...item, ref: React.createRef() })));
        }, []);
        
    
        // Function to handle slider value change   
        const handleSliderChange = (event, newValue) => {
            if (typeof newValue === 'number') {
                setSelectedYear(newValue);
                scrollToNearestItem(newValue);
            } else {
                console.error('Slider value is not a number:', newValue);
            }
        };
    
        // Function to find and scroll to the nearest item in time       
        const scrollToNearestItem = (year) => {
            if (scrollRef.current) {
                const nearestIndex = kutfok.reduce((prevIndex, curr, currIndex) => {
                    const prevDiff = Math.abs(kutfok[prevIndex].year - year);
                    const currDiff = Math.abs(curr.year - year);
                    return prevDiff < currDiff ? prevIndex : currIndex;
                }, 0);
                if (refs[nearestIndex].current) {
                    refs[nearestIndex].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                } else {
                    console.log("No ref:", refs[nearestIndex])
                }                
            } else {
                console.log("No scrollRef:", scrollRef.current)
            }
        }
        
        return (
            <>
                <Typography variant="h4" style={{ textAlign: "center", paddingTop: "1rem", paddingBottom: "1rem",  background: "#032b3b" }}>Székely kútfők</Typography>
                <Typography variant="h6" style={{ textAlign: "center", paddingTop: ".5rem", paddingBottom: ".5rem", marginBottom: "0rem", background: "#032b3b" }}>Hivatkozások száma ({kutfok.length})</Typography>

                {/* MUI Slider */}
                <Grid container justifyContent='center' style={{background: "#032b3b"}}>
                <Slider
                    value={selectedYear}
                    onChange={handleSliderChange}
                    min={1283}
                    max={2022}
                    step={1}
                    style={{ height: '0.3rem', width: '80%', margin: '2rem', background: "#fff"}}
                    marks={Array.from({ length: Math.ceil((2022 - 1283) / 50) + 1 }, (_, index) => ({
                        value: 1283 + index * 50,
                        label: (
                            <Typography style={{ color: '#fff', fontSize: '1rem' }}>
                                {1250 + index * 50}
                            </Typography>
                        ),
                    }))}
                />
                </Grid>
              
                {/* Scrollable container */}
                <div style={{ maxHeight: '70vh', overflowY: 'auto' }} ref={scrollRef}>
                    <Grid container spacing={2} style={{ background: "#032b3b", padding: '1rem' }}>
                        {kutfok.map((item, index) => {
                            // Assign the ref to the item
                            item.ref = itemRefs.current[index];                    
                        return (
                            
                            <Grid container spacing={2} key={index} ref={refs[index]}>
                                {/* Date */}
                                <Grid item xs={12} md={3} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", borderRight: "2px solid white" }}>
                                    <Typography variant="body1" gutterBottom style={{ textAlign: "right", color: "white" }}>
                                        {item.year}
                                        <span style={{ marginLeft: "5px", fontSize: '1.5rem' }}>-</span> {/* Bullet point */}
                                    </Typography>
                                </Grid>
                                {/* Card */}
                                <Grid item xs={12} md={9} xl={4} style={{ margin: ".7rem" }}>
                                    <Paper elevation={3} style={{ padding: '10px', height: '100%' }}>
                                        <Card style={{ display: 'flex', height: '100%' }}>
                                            <CardMedia
                                                component="img"
                                                image={item.coverimage || placeholder}
                                                alt={item.title}
                                                style={{ maxWidth: '100px', maxHeight: '20vh', marginRight: '10px' }}
                                            />
                                            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                                <CardContent>
                                                    <Typography variant="h5" component="div" style={{ fontWeight: 'bold' }}>
                                                        {item.title}
                                                    </Typography>
                                                    <Typography color="textSecondary" gutterBottom>
                                                        {item.author}
                                                    </Typography>
                                                    <Typography variant="body2" component="p">
                                                        {item.content}
                                                    </Typography>
                                                </CardContent>
                                                <div style={{ marginTop: 'auto' }}>
                                                    {item.link && <a href={item.link} target="_blank" rel="noopener noreferrer">Bővebben...</a>}
                                                </div>
                                            </div>
                                        </Card>
                                    </Paper>
                                </Grid>
                            </Grid>
                        );
                    })}
                    </Grid>
                </div>
            </>
        );
        }
        
    export default KnowledgeBase;
