// Function to convert multipolygon coordinates
export  function convertMultiPolygon(multiPolygon) {
    const coordinates = multiPolygon.coordinates;
    const positions = coordinates.map(polygon => {
        return polygon[0].map(coord => [coord[1], coord[0]]); // swap lat and lon
    });
    return positions;
}

// Function to calculate centroid
export function calculateCentroid(positions) {
  let x = 0, y = 0, len = positions.length;
  for (let i = 0; i < len; i++) {
      x += positions[i][0];
      y += positions[i][1];
  }
  return [x / len, y / len];
}