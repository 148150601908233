import React from 'react';
import { Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

function Masters() {
  return (
    <>  
    <div>
        <Typography variant="h3" align="center" gutterBottom gutterTop>
            Székelyföldi mesterek
        </Typography>
  
    </div>
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      textAlign:'center',
    }}>
      <div>
        <ConstructionIcon color="primary" style={{ fontSize: 60, marginBottom: 20 }} />
        <Typography variant="h3" color="primary">Fejlesztés alatt</Typography>
      </div>
    </div>
    </>
  )
}

export default Masters;