import React, { useEffect, useState,  useContext } from 'react';  
import {useNavigate, useParams} from "react-router-dom";
import Axios from "axios"
import { useImmerReducer } from 'use-immer';
import {Grid, Typography, IconButton, Button,  CircularProgress,  Breadcrumbs, Link, Dialog, Snackbar} from "@mui/material";
import ListingUpdate from './ListingUpdate';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet';
import "leaflet/dist/leaflet.css";
import StateContext from "../../Contexts/StateContext";

//Assets
import defaultProfilePicture from "../../Assets/defaultProfilePicture.jpg";
import PhoneIcon from '@mui/icons-material/Phone';
import cafe from '../../Assets/MapIcons/coffee.png';
import atm from '../../Assets/MapIcons/atm_euro.png';
import restaurant from '../../Assets/MapIcons/restaurant.png';

import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import RoomIcon from '@mui/icons-material/Room';
import CheckBoxIcon from '@mui/icons-material/CheckBox';



function ListingDetail() {
    const {config, environment } = require('../../index');
    const navigate = useNavigate()
    const GlobalState = useContext(StateContext);
    const params = useParams();
    
        

    const customIcon = new L.Icon({
        iconUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png',  
        iconSize: [25, 41],  
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
     });
    const cafeIcon = new L.Icon(
        {
            iconUrl: cafe,
            iconSize: [40, 40],
        });
    const atmIcon = new L.Icon(
        {
            iconUrl: atm,
            iconSize: [40, 40],
        });
    const restaurantIcon = new L.Icon(
        {
            iconUrl: restaurant,
            iconSize: [40, 40],
        });

    const initialState = {   
        dataIsLoading: true,  
        listingInfo: "" ,
        sellerProfileInfo: "",
        openSnack: false,
        disabledLoginBtn: false,  
    };

    function ReducerFunction(draft, action){
        switch(action.type){
            case 'catchListingInfo':
                draft.listingInfo = action.listingObject;
                 break;
            case 'loadingDone': 
                draft.dataIsLoading = false;
                break; 
            case 'catchSellerProfileInfo':  
                draft.sellerProfileInfo = action.profileObject;
                break;                 
            case 'openTheSnack':
                draft.openSnack = true;
                break;
            case 'disabledDeleteBtn':
                draft.disabledDeleteBtn = true;
                break;
            case 'allowTheDeleteBtn':
                draft.disabledDeleteBtn = false;
                break;
    }
    }

    const [state, dispatch] = useImmerReducer(ReducerFunction, initialState)  
    const [open, setOpen] = React.useState(false);

    const ProfileInfoEndpoint = config[environment].getProfileEndpoint(state.listingInfo.seller);
    const DeleteListingEndpoint = config[environment].deleteListingEndpoint(params.id);

    //request to get listing info
    useEffect(() => {
        async function getListingInfo() {
            try {
                const ListingInfoEndpoint = config[environment].getListingEndpoint(params.id);  
                const response = await Axios.get(ListingInfoEndpoint);
                
                dispatch({type: 'catchListingInfo', listingObject: response.data});               
            } catch (e) {
                console.log(e.response);
            }

        }
        getListingInfo();
    }, [config, environment]);

    //request to get profile info
    useEffect(() => {
        if(state.listingInfo){
            async function getProfileInfo() {
                try {
                    const response = await Axios.get(ProfileInfoEndpoint);
                    
                    dispatch({type: 'catchSellerProfileInfo', profileObject: response.data});
                    dispatch({type: 'loadingDone'});
                } catch (e) {
                    console.log(e.response);
                }
    
            }
            getProfileInfo();
        }
    }, [config, environment, state.listingInfo]); //watching for changes in state.listingInfo

    useEffect(()=>{
        if (state.openSnack){
          setTimeout(()=>{
            navigate('/listings');
          }, 1500)
        }
      }, [state.openSnack]) //will watch for changes in state.openSnack

    const listingPictures =[state.listingInfo.picture1, state.listingInfo.picture2, state.listingInfo.picture3, state.listingInfo.picture4, state.listingInfo.picture5,
        state.listingInfo.picture6, state.listingInfo.picture7, state.listingInfo.picture8, state.listingInfo.picture9, state.listingInfo.picture10]
        .filter(picture => picture !== null);

    const [currentPicture, setCurrentPicture] = useState(0); //0 initial state

    function NextPicture(){
        if(currentPicture === listingPictures.length - 1){
            return setCurrentPicture(0);
        }else{
            return setCurrentPicture(currentPicture + 1);
        }
    }

    function PreviousPicture(){
        if(currentPicture === 0){
            return setCurrentPicture(listingPictures.length - 1);
        }else{
            return setCurrentPicture(currentPicture - 1);
        }
    }

    const date = new Date(state.listingInfo.date_posted)
    const formattedDate = `${date.getFullYear()} - ${date.getMonth() + 1} - ${date.getDate()}`

    
    //loading spinner
    if(state.dataIsLoading === true){
        return (<Grid container justifyContent="center" alignItems="center" style={{height:"100vh"}}>
          <CircularProgress/>
        </Grid>);
    }   

    //handlers
    async function DeleteHandler(){
        const confirmDelete = window.confirm("Biztosan törölni akarod az objektumot?");
        if(confirmDelete){
            try {                
                const response = await Axios.delete(DeleteListingEndpoint);
                dispatch({type: 'openTheSnack'});
                dispatch({type: 'disabledDeleteBtn'})
            } catch (e) {
                dispatch({
                    type: 'allowTheDeleteBtn',
                   })
            }
        }
    }    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

  return (
    <div style={{marginLeft: '2rem', marginRight: '2rem', marginBottom: '2rem'}} >
      <Grid item style={{marginTop: '1rem'}}>
      <Breadcrumbs aria-label="breadcrumb">        
        <Link
            underline="hover"
            color="inherit"
            onClick={()=> navigate(`/listings/`)}
            style={{cursor: 'pointer'}}
        >
            Listings
        </Link>
        <Typography color="text.primary">
            {state.listingInfo.title}
        </Typography>
        </Breadcrumbs>
      </Grid>

      {/* Image slider*/ }
      {listingPictures.length > 0 ? (<Grid item container justifyContent='center' 
        sx={{position: 'relative', marginTop: '1rem', marginBottom: '1rem'}}
        >
        {listingPictures.map((picture, index) => {
            return <div key={index}>
                {index === currentPicture ? (<img src={picture} style={{width: '45rem', height:'35rem'}}/> ): ('')}
            </div>
        })}
        <ArrowCircleLeftIcon onClick={PreviousPicture} sx={{cursor: 'pointer', position: 'absolute', fontsize: '5rem', top:'50%', left:'27%',
         }}/>
        <ArrowCircleRightIcon onClick={NextPicture} sx={{cursor: 'pointer', position: 'absolute', fontsize: '5rem', top:'50%', right:'27%',
         }}/>
      </Grid>)
      :('')}

      {/* More info*/ }
        <Grid item container style={{padding: '1rem', border:'1px solid #105b79', marginTop:'1rem'}} >
            <Grid item container direction="column"  xs={7} spacing={1}>
                <Grid item>
                    <Typography variant="h5" style={{ marginTop:'1rem'}} > {state.listingInfo.title} </Typography>
                </Grid>
                <Grid item>
                    <RoomIcon/>{" "}
                    <Typography variant="h6" > {state.listingInfo.borough} </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" style={{ marginTop:'1rem'}} > {formattedDate} </Typography>
                </Grid>
            </Grid>
            <Grid item container  alignItems='center' xs={5}>
                <Typography variant="h6" style={{ fontWeight:'bolder', color: 'green', marginTop:'1rem'}} > {state.listingInfo.listing_type} | {state.listingInfo.property_status === 'Eladó' 
                ? `$${state.listingInfo.price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` 
                : `$${state.listingInfo.price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} / ${state.listingInfo.rental_frequency}`} </Typography>
            </Grid>
        </Grid>

        <Grid item container justifyContent="flex-start" style={{padding: '1rem', border:'1px solid #105b79', marginTop:'1rem'}}>
            {state.listingInfo.rooms ? (
            <Grid item xs={2} style={{display: 'flex'}}>                
                <Typography variant='h6'>{state.listingInfo.rooms} Szobák </Typography>
            </Grid>
            ): (
            ""
            )}

            {state.listingInfo.airconditioning ? (
            <Grid item xs={2} style={{display: 'flex'}}>
                <CheckBoxIcon style={{color: 'blue', fontSize:'2rem'}}/>
                <Typography variant='h6'>Légkondicionálás</Typography>
            </Grid>
            ): (
            ""
            )}

            {state.listingInfo.parking ? (
            <Grid item xs={2} style={{display: 'flex'}}>
                <CheckBoxIcon style={{color: 'blue', fontSize:'2rem'}}/>
                <Typography variant='h6'>Parkoló</Typography>
            </Grid>
            ): (
            ""
            )}

            {state.listingInfo.pool ? (
            <Grid item xs={2} style={{display: 'flex'}}>
                <CheckBoxIcon style={{color: 'blue', fontSize:'2rem'}}/>
                <Typography variant='h6'>Medence</Typography>
            </Grid>
            ): (
            ""
            )}

            {state.listingInfo.wellness ? (
            <Grid item xs={2} style={{display: 'flex'}}>
                <CheckBoxIcon style={{color: 'blue', fontSize:'2rem'}}/>
                <Typography variant='h6'>Wellness</Typography>
            </Grid>
            ): (
            ""
            )}

            {state.listingInfo.breakfast ? (
            <Grid item xs={2} style={{display: 'flex'}}>
                <CheckBoxIcon style={{color: 'blue', fontSize:'2rem'}}/>
                <Typography variant='h6'>Reggeli</Typography>
            </Grid>
            ): (
            ""
            )}

            {state.listingInfo.elevator ? (
            <Grid item xs={2} style={{display: 'flex'}}>
                <CheckBoxIcon style={{color: 'blue', fontSize:'2rem'}}/>
                <Typography variant='h6'>Felvonór</Typography>
            </Grid>
            ): (
            ""
            )}

            {state.listingInfo.petsallowed ? (
            <Grid item xs={2} style={{display: 'flex'}}>
                <CheckBoxIcon style={{color: 'blue', fontSize:'2rem'}}/>
                <Typography variant='h6'>Háziállatok engedélyezettek</Typography>
            </Grid>
            ): (
            ""
            )}

            {state.listingInfo.wifi ? (
            <Grid item xs={2} style={{display: 'flex'}}>
                <CheckBoxIcon style={{color: 'blue', fontSize:'2rem'}}/>
                <Typography variant='h6'>Wifi</Typography>
            </Grid>
            ): (
            ""
            )}

            {state.listingInfo.airporttransfer ? (
            <Grid item xs={2} style={{display: 'flex'}}>
                <CheckBoxIcon style={{color: 'blue', fontSize:'2rem'}}/>
                <Typography variant='h6'>Reptéri transzfer</Typography>
            </Grid>
            ): (
            ""
            )}

            {state.listingInfo.vacationticket ? (
            <Grid item xs={2} style={{display: 'flex'}}>
                <CheckBoxIcon style={{color: 'blue', fontSize:'2rem'}}/>
                <Typography variant='h6'>Üdülési csekk</Typography>
            </Grid>
            ): (
            ""
            )}           
        </Grid>
        
        {/* Description*/ }
        {state.listingInfo.description ? (
        <Grid item container style={{padding: '1rem', border:'1px solid #105b79', marginTop:'1rem'}} >
            <Grid item>
                <Typography variant="h5" style={{ marginTop:'1rem'}} > Leírás </Typography>
                <Typography variant="h6" style={{ marginTop:'1rem'}} > {state.listingInfo.description} </Typography>
            </Grid>              
        </Grid>
        ) : ('')}

        {/* Seller info*/ }
        <Grid container style={{width: '50%', marginLeft: 'auto', marginRight: 'auto', border:'3px solid grey', 
                            marginTop:"1rem", padding: '5px'}}>
            <Grid item xs={6} sx={{marginTop: "1rem"}}>
                <img src={state.sellerProfileInfo.profile_picture !== null ? state.sellerProfileInfo.profile_picture : defaultProfilePicture } 
                alt="Profile picture" 
                style={{width: "10rem", height: "10rem"}}
                onClick={()=> navigate(`/institutions/${state.sellerProfileInfo.seller}`)}/>
            </Grid>
            <Grid item container direction='column' justifyContent="center" xs={6}>
                <Grid item>
                    <Typography variant="h5" 
                        style={{textAlign:'center', marginTop:'1rem'}} > 
                        <span style={{color: 'green', fontWeight: 'bolder'}}> {state.sellerProfileInfo.company_name}</span>                            
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5" style={{textAlign:'center', marginTop:'1rem'}} > 
                        <IconButton><PhoneIcon/>   {state.sellerProfileInfo.phone_number} </IconButton>                                            
                    </Typography>
                </Grid>                                           
                
            </Grid>
            {GlobalState.userId == state.listingInfo.seller ? (
                <Grid item container justifyContent={'space-around'}>
                    <Button variant="contained" color="primary" onClick={handleClickOpen}>
                        Update
                    </Button>
                    <Button variant="contained" color="error" onClick={DeleteHandler} disabled={state.disabledDeleteBtn}>
                        Delete
                    </Button>
                    <div>
                    <Dialog open={open} onClose={handleClose} PaperProps={{ style: { width: '100%' } }} >
                        <ListingUpdate listingData={state.listingInfo} closeDialog={handleClose} />
                    </Dialog>
                    </div>
                </Grid>) : ("")}
        </Grid>

        {/* Map*/ }
        <Grid item container style={{  marginTop:'1rem'}} spacing={1} justifyContent="space-between">
            <Grid item xs={3} style={{overflow:"auto", height: "35rem"}}>
                {state.listingInfo.listing_pois_within_10km.map(poi => {
                    function DegreeToRadian(coordinate){
                        return coordinate * Math.PI/180;
                    }

                    function CalculateDistance() {
                        const latitude1 = DegreeToRadian(state.listingInfo.latitude);
                        const longitude1 = DegreeToRadian(state.listingInfo.longitude);
                        const latitude2 = DegreeToRadian(poi.location.coordinates[1]);
                        const longitude2 = DegreeToRadian(poi.location.coordinates[0]);  

                        // The formula
                        const latDiff = latitude2 - latitude1;
                        const lonDiff = longitude2 - longitude1;
                        const R = 6371000 / 1000;

                        const a =
                            Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
                            Math.cos(latitude1) *
                                Math.cos(latitude2) *
                                Math.sin(lonDiff / 2) *
                                Math.sin(lonDiff / 2);
                        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

                        const d = R * c;

                        const dist =
                            Math.acos(
                                Math.sin(latitude1) * Math.sin(latitude2) +
                                    Math.cos(latitude1) *
                                        Math.cos(latitude2) *
                                        Math.cos(lonDiff)
                            ) * R;
                        return dist.toFixed(2);
                    }
                    
                    return (
                        <div key={poi.id} style={{marginBottom: '0.5rem', border:'1px solid grey', padding: '0.5rem'}}>
                            <Typography variant="h6" style={{ marginTop:'1rem'}} > {poi.name} </Typography>
                            <Typography variant="subtitle1" style={{ marginTop:'1rem'}} > {poi.type} | {' '}
                            <span style={{ fontWeight:"bolder", color: "green"}}> {CalculateDistance()} </span> km </Typography>
                        </div>
                    )
                })}   
            </Grid>
            <Grid item xs={9} style={{ height: '35rem'}}>                
                <MapContainer center={[state.listingInfo.latitude, state.listingInfo.longitude]} zoom={14} scrollWheelZoom={true}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[state.listingInfo.latitude, state.listingInfo.longitude]} icon={customIcon}>
                        <Popup>
                            {state.listingInfo.title}
                        </Popup>
                    </Marker>  
                    {state.listingInfo.listing_pois_within_10km.map(poi => {
                        function PoiIcon(){
                            if(poi.type === 'Kávézó'){
                                return cafeIcon
                            }else if(poi.type === 'Atm'){
                                return atmIcon
                            }else if(poi.type === 'Étterem'){
                                return restaurantIcon
                            }
                        }
                        return (
                            <Marker 
                                key={poi.id} 
                                position={[poi.location.coordinates[1],poi.location.coordinates[0]]}
                                icon={PoiIcon()} >
                                <Popup>
                                    {poi.name}
                                </Popup>
                            </Marker>
                        )
                    })}                                       
                </MapContainer>
            </Grid>                       
        </Grid>
        <Snackbar
          open={state.openSnack}     
          message="Sikeresen törölted a térképobjektumot!"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}         
        />

    </div>
  );
}

export default ListingDetail
