import React from 'react';
import {useNavigate} from "react-router-dom";
//MUI
import { Button, Typography, ThemeProvider,  responsiveFontSizes} from '@mui/material';
import {createTheme} from '@mui/material/styles';

//Assets
import sznszt from '../Assets/sznszt.png'


let theme = createTheme();
theme = responsiveFontSizes(theme);


function Home() {
 
  const navigate = useNavigate();
  
  return (
    <>
      <div style={{ position: 'relative' }}>      
        <img src={sznszt} style={{ width: "100%", opacity: '0.7' }}/>
        <div style ={{
          position: 'absolute',
          top: '35vh',  // 20% of the viewport height
          left: '15vw', // 20% of the viewport width 
          right: '15vw', 
          zIndex: '100', 
          borderRadius: "15px", 
          backgroundColor: '#032b3b', 
          padding:'20px', 
          fontWeight: 'bolder',
          textAlign: 'center',
          maxWidth: '60%', // Set maximum width for responsiveness
          margin: '0 auto', // Center horizontally 
        }}>
          <ThemeProvider theme={theme}>
              <Typography variant='h2' style={{ color: '#2492be', fontSize: '2rem', [theme.breakpoints.down('sm')]: { fontSize: '1.5rem' } }}>
                FEDEZD FEL <span style={{ color: 'green', fontSize: '2rem',  wordWrap: 'break-word' }}> SZÉKELYFÖLDET </span>                 
              </Typography>
              
            <Typography variant='h5' style={{ color: '#b0bcc0', fontSize: '1.5rem', [theme.breakpoints.down('sm')]: { fontSize: '1rem' } }}>Bővítsd a székely szótárt!</Typography>
            <Typography variant='h5' style={{ color: '#b0bcc0', fontSize: '1.5rem', [theme.breakpoints.down('sm')]: { fontSize: '1rem' } }}>Regisztrálj a Virtuális Székely Nemzeti Akadémiába!</Typography>
            <Typography variant='h5' style={{ color: '#b0bcc0', fontSize: '1.5rem', [theme.breakpoints.down('sm')]: { fontSize: '1rem' } }}>Tedd fel a céged a térképre!</Typography>
            <Typography variant='h5' style={{ color: '#b0bcc0', fontSize: '1.5rem', [theme.breakpoints.down('sm')]: { fontSize: '1rem' } }}>Szegődj mesterembert!</Typography>
            <Typography variant='h5' style={{ color: '#b0bcc0', fontSize: '1.5rem', [theme.breakpoints.down('sm')]: { fontSize: '1rem' } }}>Add tovább ami felesleges!</Typography>
            <Button 
              variant="contained" 
              onClick={()=>navigate("/listings")}  
              sx={{ fontSize: '3rem', 
              borderRadius: "15px", 
              backgroundColor: '#2492be', 
              marginTop: '2rem', 
              boxShadow: '1px 1px 1px white',
              [theme.breakpoints.down('sm')]: { fontSize: '1.2rem' } // Adjust button font size for smaller screens
              }}
            >
              &gt;&gt; INTERAKTÍV TÉRKÉP
            </Button>
          </ThemeProvider>
        </div>
      </div>
    </>
  );
}

export default Home;