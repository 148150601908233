export const centuryOptions = [
    {value: '', label: '',},
    {value: '3', label: 'III. sz.',},
    {value: '4', label: 'IV. sz.',},
    {value: '5', label: 'V. sz.',},
    {value: '6', label: 'VI. sz.',},
    {value: '7', label: 'VII. sz.',},
    {value: '8', label: 'VIII. sz.',},
    {value: '9', label: 'IX. sz.',},
    {value: '10', label: 'X. sz.',},
    {value: '11', label: 'XI. sz.',},
    {value: '12', label: 'XII. sz.',},
    {value: '13', label: 'XIII. sz.',},
    {value: '14', label: 'XIV. sz.',},
    {value: '15', label: 'XV. sz.',},
    {value: '16', label: 'XVI. sz.',},
    {value: '17', label: 'XVII. sz.',},
    {value: '18', label: 'XVIII. sz.',},
    {value: '19', label: 'XIX. sz.',},
    {value: '20', label: 'XX. sz.',},
    {value: '21', label: 'XXI. sz.',},
]
