import React, {useEffect, useContext} from 'react';
import Axios from 'axios'
import {useNavigate} from "react-router-dom";
import {Grid, Typography, Button,  TextField, Snackbar, Alert} from "@mui/material";
import { useImmerReducer } from 'use-immer';

//Contexts
import DispatchContext from "../../Contexts/DispatchContext";
import StateContext from "../../Contexts/StateContext";


function Login() {
  const { config, environment } = require('../../index');
  const navigate = useNavigate();

  const GlobalDispatch = useContext(DispatchContext);
  const GlobalState = useContext(StateContext);

  //immerReducer related
  const initialState = {
      usernameValue: '',
      passwordValue: '',
      sendRequest: 0,
      token: '',
      openSnack: false,
      disabledLoginBtn: false,
      serverError: false

   };

function ReducerFunction(draft, action){
      switch(action.type){
          case 'catchUsernameChange':
            draft.usernameValue = action.usernameChosen;
            draft.serverError = false;
            break;
          case 'catchPasswordChange':
            draft.passwordValue = action.passwordChosen;
            draft.serverError = false;
            break;
          case 'changeSendRequest':
            draft.sendRequest = draft.sendRequest +1;
            break;  
          case 'catchToken':  
            draft.token = action.tokenValue;
            break; 
          case 'openTheSnack':
            draft.openSnack = true;
            break;
          case 'disabledLoginBtn':
            draft.disabledLoginBtn = true;
            break;
          case 'allowTheLoginBtn':
            draft.disabledLoginBtn = false;
            break;
          case 'catchServerError':
              draft.serverError = true;
            break;  
            

       }
  }
  const [state, dispatch] = useImmerReducer(ReducerFunction, initialState)

  function FormSubmit(e){
    e.preventDefault();    
    dispatch({type:'changeSendRequest'});
    dispatch({type: 'disabledLoginBtn'})
  }


  useEffect(() =>{      
    if(state.sendRequest){
     const source= Axios.CancelToken.source();
     async function SignIn(){ 
         
       try{
        const loginEndpoint = config[environment].loginEndpoint;
         const response =  await Axios.post(loginEndpoint, {
             username: state.usernameValue,
             password: state.passwordValue
          },
          {
              cancelToken: source.token,
          }
          );       
          dispatch({
          type: 'catchToken', 
          tokenValue: response.data.auth_token,
        });
         GlobalDispatch({
          type: 'catchToken', 
          tokenValue: response.data.auth_token,
        });         
         } catch (error){           
             dispatch({ type: 'allowTheLoginBtn',})
             dispatch({type: 'catchServerError'})
         }
     }  
     SignIn();
     return()=>{
         source.cancel();
     };
   }
  }, [config, environment, state.sendRequest,  GlobalDispatch, dispatch, navigate, state.passwordValue, state.usernameValue]);

  //get user info
  useEffect(() =>{    
    if(state.token !== ''){ ///
     const source= Axios.CancelToken.source();
     async function GetUserInfo(){
         
      try{
        const myUserEndpoint = config[environment].myUserEndpoint;
         const response =  await Axios.get(myUserEndpoint,
         
          {
             headers: {Authorization: 'Token '.concat(state.token)},
          },
          {
              cancelToken: source.token,
          }
          );          
       
          GlobalDispatch({
          type: "userSignsIn", 
          usernameInfo: response.data.username, 
          emailInfo: response.data.email, 
          IdInfo: response.data.id
        });
        dispatch({type: 'openTheSnack'});
         //useEffects for navigate
         } catch (error){
             
         }
     }  
     GetUserInfo();
     return()=>{
         source.cancel();
     };
   }
  }, [config, environment, state.token, GlobalDispatch, navigate]);

  useEffect(()=>{
    if (state.openSnack){
      setTimeout(()=>{
        navigate('/');
      }, 1500)
    }
  }, [state.openSnack]) //will watch for changes in state.openSnack

  return (
    <div>
      <div style={{width:'50%', marginLeft: "auto", marginRight: "auto", marginTop: "3rem", border:"2px solid black", padding: '3rem' }}>
        <form onSubmit={FormSubmit}>
            <Grid item container justifyContent="center">
                <Typography variant='h4'>BEJELENTKEZÉS</Typography>
            </Grid>
            {state.serverError ? <Alert severity="error">Hibás felhasználónév vagy jelszó!</Alert> : ''}
            
            <Grid item container sx={{marginTop: "1rem"}}>
                <TextField id="username" label="Username" variant="outlined" fullWidth
                  value={state.usernameValue}
                  onChange={(e)=>dispatch({type: 'catchUsernameChange', usernameChosen: e.target.value})}
                  error={state.serverError ? true : false}
                  //helperText="Hibás adatbevitel!"
                  />
            </Grid>
            
            <Grid item container sx={{marginTop: "1rem"}}>
                <TextField id="password" label="Password" variant="outlined" fullWidth 
                value={state.passwordValue} 
                onChange={(e)=>dispatch({type: 'catchPasswordChange', passwordChosen: e.target.value})} type="Password"
                error={state.serverError ? true : false}
                />
            </Grid>
              
            <Grid item container xs={8} sx={{marginTop: "1rem", marginLeft: "auto", marginRight: "auto"}}>
                <Button variant='contained' fullWidth type='submit'
                disabled={state.disabledLoginBtn} >BEJELENTKEZÉS</Button>
            </Grid>
        </form>
        
        
        
        <Grid item container justifyContent="center" sx={{marginTop: "1rem"}}>
                <Typography variant='small'>Nincs még Székely Nemzet Online fiókod? <span onClick={()=>navigate("/register")} style={{cursor: "pointer", color:"green"}}>IRATKOZZ FEL</span> </Typography>
        </Grid>
        <Snackbar
          open={state.openSnack}     
          message="Sikeresen bejelentkeztél!"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}         
        />
      </div>
    </div>
  );
}
export default Login