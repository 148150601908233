
export const areaOptions = [
    {value: '', label: ''},
    {value: 'Aranyosszék', label: 'Aranyosszék'},
    {value: 'Csíkszék', label: 'Csíkszék'},
    {value: 'Gyergyószék', label: 'Gyergyószék'},
    {value: 'Kászonfiúszék', label: 'Kászonfiúszék'},
    {value: 'Keresztúrfiúszék', label: 'Keresztúrfiúszék'},
    {value: 'Kézdiszék', label: 'Kézdiszék'},
    {value: 'Marosszék', label: 'Marosszék'},
    {value: 'Miklósvárfiúszék', label: 'Miklósvárfiúszék'},
    {value: 'Orbaiszék', label: 'Orbaiszék'},
    {value: 'Sepsiszék', label: 'Sepsiszék'},  
    {value: 'Udvarhelyszék', label: 'Udvarhelyszék'}, 
]

export const AranyosszekOptions = [
    {value: '', label: ''},
    {value: 'Felvinc', label: 'Felvinc'},
    {value: 'Várfalva', label: 'Várfalva'},
    {value: 'SzentMihályfalva', label: 'SzentMihályfalva'},
    {value: 'Harasztos', label: 'Harasztos'},
    {value: 'Székelykocsárd', label: 'Székelykocsárd'},
    {value: 'Székföldvár', label: 'Székföldvár'},
    {value: 'Poján', label: 'Poján'},
    {value: 'Csakó', label: 'Csakó'},

]

export const CsikszekOptions = [
    {value: '', label: ''},
    {value: 'Balánbánya', label: 'Balánbánya'},
    {value: 'Csíkcsicsó', label: 'Csíkcsicsó'},
    {value: 'Csíkdánfalva', label: 'Csíkdánfalva'},
    {value: 'Csíkkarcfalva', label: 'Csíkkarcfalva'},
    {value: 'Csíkkozmás', label: 'Csíkkozmás'}, 
    {value: 'Csíkmadaras', label: 'Csíkmadaras'},
    {value: 'Csíkpálfalva', label: 'Csíkpálfalva'},
    {value: 'Csíkrákos', label: 'Csíkrákos'},
    {value: 'Csíkszentdomokos', label: 'Csíkszentdomokos'},
    {value: 'Csíkszentgyörgy', label: 'Csíkszentgyörgy'},
    {value: 'Csíkszentimre', label: 'Csíkszentimre'},
    {value: 'Csíkszentkirály', label: 'Csíkszentkirály'},
    {value: 'Csíkszentmárton', label: 'Csíkszentmárton'},
    {value: 'Csíkszentmihály', label: 'Csíkszentmihály'},
    {value: 'Csíkszentlélek', label: 'Csíkszentlélek'}, 
    {value: 'Csíkszentsimon', label: 'Csíkszentsimon'},
    {value: 'Csíkszenttamás', label: 'Csíkszenttamás'},   
    {value: 'Csíkszereda', label: 'Csíkszereda'},
    {value: 'Csíkszépvíz', label: 'Csíkszépvíz'},
    {value: 'Gödemesterháza', label: 'Gödemesterháza'},  
    {value: 'Gyimesbükk', label: 'Gyimesbükk'},
    {value: 'Gyimesfelsőlok', label: 'Gyimesfelsőlok'},
    {value: 'Gyimesközéplok', label: 'Gyimesközéplok'},
    {value: 'Madéfalva', label: 'Madéfalva'}, 
    {value: 'Tusnádfürdő', label: 'Tusnádfürdő'},
    {value: 'Tusnád', label: 'Tusnád'},
]

export const GyergyoszekOptions = [
    {value: '', label: ''},
    {value: 'Borszék', label: 'Borszék'},
    {value: 'Galócás', label: 'Galócás'},   
    {value: 'Gyergyóalfalu', label: 'Gyergyóalfalu'},
    {value: 'Gyergyócsomafalva', label: 'Gyergyócsomafalva'},
    {value: 'Gyergyóditró', label: 'Gyergyóditró'},
    {value: 'Gyergyóholló', label: 'Gyergyóholló'},
    {value: 'Gyergyóremete', label: 'Gyergyóremete'},
    {value: 'Gyergyószárhegy', label: 'Gyergyószárhegy'},
    {value: 'Gyergyószentmiklós', label: 'Gyergyószentmiklós'},
    {value: 'Gyergyótölgyes', label: 'Gyergyótölgyes'},
    {value: 'Gyergyóújfalu', label: 'Gyergyóújfalu'},
    {value: 'Maroshévíz', label: 'Maroshévíz'},   
    {value: 'Salamás', label: 'Salamás'},
    {value: 'Várhegy', label: 'Várhegy'},
    {value: 'Vasláb', label: 'Vasláb'},    
]

export const KaszonfiuszekOptions = [
    {value: '', label: ''},
    {value: 'Kászonaltíz', label: 'Kászonaltíz'},
    
]

export const KereszturfiuszekOptions = [
    {value: '', label: ''},    
    {value: 'Nagygalambfalva', label: 'Nagygalambfalva'},   
    {value: 'Siménfalva', label: 'Siménfalva'},
    {value: 'Szentábrahám', label: 'Szentábrahám'},    
    {value: 'Székelyandrásfalva', label: 'Székelyandrásfalva'},
    {value: 'Székelykeresztúr', label: 'Székelykeresztúr'},
    {value: 'Újszékely', label: 'Újszékely'},
   
]

export const KezdiszekOptions = [
    {value: '', label: ''},
    {value: 'Bereck', label: 'Bereck'},
    {value: 'Csernáton', label: 'Csernáton'},
    {value: 'Dálnok', label: 'Dálnok'},
    {value: 'Esztelnek', label: 'Esztelnek'},
    {value: 'Gelence', label: 'Gelence'}, 
    {value: 'Kézdialmás', label: 'Kézdialmás'},    
    {value: 'Kézdiszentkereszt', label: 'Kézdiszentkereszt'},
    {value: 'Kézdiszentlélek', label: 'Kézdiszentlélek'},
    {value: 'Kézdivásárhely', label: 'Kézdivásárhely'},
    {value: 'Lemhény', label: 'Lemhény'},
    {value: 'Ozsdola', label: 'Ozsdola'},
    {value: 'Szentkatolna', label: 'Szentkatolna'},
    {value: 'Torja', label: 'Torja'},
 ]

 export const MarosszekOptions = [
    {value: '', label: ''},
    {value: 'Ákosfalva', label: 'Ákosfalva'},
    {value: 'Balavásár', label: 'Balavásár'},
    {value: 'Backamadaras', label: 'Backamadaras'},
    {value: 'Beresztelke', label: 'Beresztelke'},
    {value: 'Csíkfalva', label: 'Csíkfalva'},
    {value: 'Erdőszentgyörgy', label: 'Erdőszentgyörgy'},
    {value: 'Gernyeszeg', label: 'Gernyeszeg'},
    {value: 'Gyulakuta', label: 'Gyulakuta'},
    {value: 'Havad', label: 'Havad'},
    {value: 'Jedd', label: 'Jedd'},
    {value: 'Kibéd', label: 'Kibéd'},
    {value: 'Koronka', label: 'Koronka'},
    {value: 'Lukafalva', label: 'Lukafalva'},
    {value: 'Makfalva', label: 'Makfalva'},
    {value: 'Maroskeresztúr', label: 'Maroskeresztúr'},
    {value: 'Marossárpatak', label: 'Marossárpatak'},
    {value: 'Marosszentanna', label: 'Marosszentanna'},
    {value: 'Marosszentgyörgy', label: 'Marosszentgyörgy'},
    {value: 'Marosszentimre', label: 'Marosszentimre'},
    {value: 'Marosszentkirály', label: 'Marosszentkirály'},  
    {value: 'Marosvásárhely', label: 'Marosvásárhely'},
    {value: 'Mezőbánd', label: 'Mezőbánd'},
    {value: 'Mezőcsávás', label: 'Mezőcsávás'},
    {value: 'Mezőmadaras', label: 'Mezőmadaras'},
    {value: 'Mezőpanit', label: 'Mezőpanit'},
    {value: 'Mezősámsond', label: 'Mezősámsond'}, 
    {value: 'Nagyernye', label: 'Nagyernye'},
    {value: 'Nyárádgálfalva', label: 'Nyárádgálfalva'},   
    {value: 'Nyárádkarácson', label: 'Nyárádkarácson'},
    {value: 'Nyárádmagyarós', label: 'Nyárádmagyarós'},
    {value: 'Nyárádremete', label: 'Nyárádremete'},
    {value: 'Nyárádszereda', label: 'Nyárádszereda'},
    {value: 'Nyárádtő', label: 'Nyárádtő'},
    {value: 'Sóvárad', label: 'Sóvárad'},
    {value: 'Székelybere', label: 'Székelybere'},
    {value: 'Székelyhodos', label: 'Székelyhodos'},
    {value: 'Székelyvécke', label: 'Székelyvécke'},
    {value: 'Szováta', label: 'Szováta'},
    {value: 'Vajdaszentivány', label: 'Vajdaszentivány'},
    
]

export const MiklosvarfiuszekOptions = [
    {value: '', label: ''},
    {value: 'Alsórákos', label: 'Alsórákos'},
    {value: 'Apáca', label: 'Apáca'},
    {value: 'Bardóc', label: 'Bardóc'},
    {value: 'Barót', label: 'Barót'},
    {value: 'Bölön', label: 'Bölön'},
    {value: 'Nagyajta', label: 'Nagyajta'},
    {value: 'Nagybacon', label: 'Nagybacon'},
    {value: 'Ürmös', label: 'Ürmös'},
    {value: 'Vargyas', label: 'Vargyas'},
]

export const OrbaiszekOptions = [
    {value: '', label: ''},
    {value: 'Barátos', label: 'Barátos'},
    {value: 'Nagyborosnyó', label: 'Nagyborosnyó'},
    {value: 'Kommandó', label: 'Kommandó'},
    {value: 'Kovászna', label: 'Kovászna'},
    {value: 'Zabola', label: 'Zabola'},
    {value: 'Zágon', label: 'Zágon'},
]

export const SepsiszekOptions = [
    {value: '', label: ''},
    {value: 'Árkos', label: 'Árkos'},
    {value: 'Előpatak', label: 'Előpatak'},
    {value: 'Gidófalva', label: 'Gidófalva'},
    {value: 'Hidvég', label: 'Hidvég'},
    {value: 'Maksa', label: 'Maksa'},
    {value: 'Málnás', label: 'Málnás'},
    {value: 'Mikóújfalu', label: 'Mikóújfalu'},
    {value: 'Kökös', label: 'Kökös'},
    {value: 'Sepsibodok', label: 'Sepsibodok'},  
    {value: 'Sepsibükszád', label: 'Sepsibükszád'},
    {value: 'Sepsikőröspatak', label: 'Sepsikőröspatak'},
    {value: 'Sepsiszentgyörgy', label: 'Sepsiszentgyörgy'},
    {value: 'Illyefalva', label: 'Illyefalva'},
    {value: 'Réty', label: 'Réty'},
    {value: 'Uzon', label: 'Uzon'},
]

export const UdvarhelyszekOptions = [
    {value: '', label: ''},
    {value: 'Bögöz', label: 'Bögöz'},
    {value: 'Etéd', label: 'Etéd'},
    {value: 'Farkaslaka', label: 'Farkaslaka'},
    {value: 'Felsőboldogfalva', label: 'Felsőboldogfalva'},
    {value: 'Fenyéd', label: 'Fenyéd'},
    {value: 'Homoród', label: 'Homoród'},
    {value: 'Homoródalmás', label: 'Homoródalmás'},
    {value: 'Homoródszentmárton', label: 'Homoródszentmárton'},
    {value: 'Kaca', label: 'Kaca'},
    {value: 'Kányád', label: 'Kányád'},
    {value: 'Kápolnásfalu', label: 'Kápolnásfalu'},
    {value: 'Korond', label: 'Korond'},
    {value: 'Lövéte', label: 'Lövéte'},    
    {value: 'Máréfalva', label: 'Máréfalva'}, 
    {value: 'Oklánd', label: 'Oklánd'},
    {value: 'Oroszhegy', label: 'Oroszhegy'},
    {value: 'Parajd', label: 'Parajd'},
    {value: 'Szentegyháza', label: 'Szentegyháza'},
    {value: 'Székelyderzs', label: 'Székelyderzs'},  
    {value: 'Székelyszentlélek', label: 'Székelyszentlélek'},
    {value: 'Székelyvarság', label: 'Székelyvarság'},
    {value: 'Székelyudvarhely', label: 'Székelyudvarhely'},    
    {value: 'Zetelaka', label: 'Zetelaka'},
]
