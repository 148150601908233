import React, { useEffect,  useContext } from 'react';  
import {useNavigate} from "react-router-dom";
import Axios from "axios"
import { useImmerReducer } from 'use-immer';
import {Grid,  Snackbar, Typography, Button,  TextField, FormControlLabel, Checkbox} from "@mui/material";


//
import StateContext from "../../Contexts/StateContext";


import {listingTypeOptions, propertyStatusOptions, rentalFrequencyOptions } from "../../Constants/IngatlanOptions";


function ListingUpdate(props) {
    const { config, environment } = require('../../index');
    const navigate = useNavigate()
    const GlobalState = useContext(StateContext) 

    const initialState = {
        titleValue: props.listingData.title,
        listingTypeValue: props.listingData.listing_type,
        descriptionValue: props.listingData.description,
        areaValue: props.listingData.area,
        boroughValue: props.listingData.borough,        
        propertyStatusValue: props.listingData.property_status,
        priceStatusValue: props.listingData.price_status,
        priceValue: props.listingData.price,
        rentFrequencyValue: props.listingData.rent_frequency,
        roomsValue: props.listingData.rooms,
        furnishedValue:props.listingData.furnished,
        poolValue: props.listingData.pool,
        elevatorValue: props.listingData.elevator,
        airconditioningValue: props.listingData.airconditioning,
        parkingValue: props.listingData.parking,
        wellnessValue: props.listingData.wellness,
        kitchenValue: props.listingData.kitchen,
        breakfastValue: props.listingData.breakfast,
        petsAllowedValue: props.listingData.pets_allowed,
        wifiValue: props.listingData.wifi,
        airportTransferValue: props.listingData.airport_transfer,
        vacationTicketValue: props.listingData.vacation_ticket,        
        sendRequest: 0,
        openSnack: false,
        disabledListingUpdateBtn: false,        
       };
   
       function ReducerFunction(draft, action){
           switch(action.type){
               case 'catchTitleChange':
                  draft.titleValue = action.titleChosen;
                  break;
                case 'catchListingTypeChange':
                    draft.listingTypeValue = action.listingTypeChosen;
                    break;
                case 'catchDescriptionChange':
                    draft.descriptionValue = action.descriptionChosen;
                    break;                
               
                case 'catchPropertyStatusChange':
                    draft.propertyStatusValue = action.propertyStatusChosen;
                    break;
                case 'catchPriceStatusChange':
                    draft.priceStatusValue = action.priceStatusChosen;
                    break;
                case 'catchPriceChange':
                    draft.priceValue = action.priceChosen;
                    break;
                case 'catchRentFrequencyChange':
                    draft.rentFrequencyValue = action.rentFrequencyChosen;
                    break;
                case 'catchRoomsChange':
                    draft.roomsValue = action.roomsChosen;
                    break;
                case 'catchFurnishedChange':
                    draft.furnishedValue = action.furnishedChosen;
                    break;
                case 'catchPoolChange':
                    draft.poolValue = action.poolChosen;
                    break;
                case 'catchElevatorChange':
                    draft.elevatorValue = action.elevatorChosen;
                    break;
                case 'catchAirconditioningChange':
                    draft.airconditioningValue = action.airConditioningChosen;
                    break;
                case 'catchParkingChange':
                    draft.parkingValue = action.parkingChosen;
                    break;
                case 'catchWellnessChange':
                    draft.wellnessValue = action.wellnessChosen;
                    break;
                case 'catchKitchenChange':
                    draft.kitchenValue = action.kitchenChosen;
                    break;
                case 'catchBreakfastChange':
                    draft.breakfastValue = action.breakfastChosen;
                    break;
                case 'catchPetsAllowedChange':
                    draft.petsAllowedValue = action.petsAllowedChosen;
                    break;
                case 'catchWifiChange':
                    draft.wifiValue = action.wifiChosen;
                    break;
                case 'catchAirportTransferChange':
                    draft.airportTransferValue = action.airportTransferChosen;
                    break;
                case 'catchVacationTicketChange':
                    draft.vacationTicketValue = action.vacationTicketChosen;
                    break;               
                case 'changeSendRequest':
                    draft.sendRequest = draft.sendRequest + 1;
                    break; 
                case 'openTheSnack':
                    draft.openSnack = true;
                    break;
                case 'disabledListingUpdateBtn':
                    draft.disabledListingUpdateBtn = true;
                    break;
                case 'allowTheListingUpdateBtn':
                    draft.disabledListingUpdateBtn = false;
                    break;
                default:
                    break;
                
           }
       }
    const [state, dispatch] = useImmerReducer(ReducerFunction, initialState)  
    
   
    function FormSubmit(e){
        e.preventDefault();        
        dispatch({type:'changeSendRequest'});
        dispatch({type: 'disabledListingUpdateBtn'})
    }

    useEffect(() =>{
        if(state.sendRequest){  
            const data = new FormData();
            data.append('title', state.titleValue);
            data.append('listing_type', state.listingTypeValue);
            data.append('description', state.descriptionValue);        
            data.append('property_status', state.propertyStatusValue);
            data.append('price_status', state.priceStatusValue);
            data.append('price', state.priceValue);
            data.append('rent_frequency', state.rentFrequencyValue);
            data.append('rooms', state.roomsValue);
            data.append('furnished', state.furnishedValue);
            data.append('pool', state.poolValue);
            data.append('elevator', state.elevatorValue);
            data.append('airconditioning', state.airconditioningValue);
            data.append('parking', state.parkingValue);
            data.append('wellness', state.wellnessValue);
            data.append('kitchen', state.kitchenValue);
            data.append('breakfast', state.breakfastValue);
            data.append('pets_allowed', state.petsAllowedValue);
            data.append('wifi', state.wifiValue);
            data.append('airport_transfer', state.airportTransferValue);
            data.append('vacation_ticket', state.vacationTicketValue);            
            data.append('seller', GlobalState.userId);
           
            async function UpdateProperty(){
                try{ 
                    const ListingUpdateEndpoint = config[environment].updateListingEndpoint(props.listingData.id);    
                    const response =  await Axios.patch(ListingUpdateEndpoint, data);                    
                    console.log(response);
                    dispatch({type: 'openTheSnack'});
                    //useEffects for navigate
                } catch (e){
                    dispatch({
                        type: 'allowTheListingUpdateBtn',
                       })
                }
            }
            UpdateProperty();
        }
    }, [config, environment, state.sendRequest]);

    function PriceDisplay(){
        if(state.propertyStatusValue === "Kiadó" && state.rentFrequencyValue === "Day"){
            return "Price per Day*";
        }
        else if(state.propertyStatusValue === "Kiadó" && state.rentFrequencyValue === "Week"){
            return "Price per Week*";
        }
        else if(state.propertyStatusValue === "Kiadó" && state.rentFrequencyValue === "Month"){
            return "Price per Month*";
        }
        else {
            return "Price*";
        }
    }  

    useEffect(() => {
        let timeoutId;
    
        if (state.openSnack) {
            timeoutId = setTimeout(() => {
                navigate(0);
            }, 1500);
        }
    
        return () => {
            clearTimeout(timeoutId);
        };
    }, [state.openSnack, navigate]);
    

    return (
        <div>
            
        <div style={{width:'75%', marginLeft: "auto", marginRight: "auto", marginTop: "3rem", border:"2px solid #105b79", padding: '3rem' }}>
            <form onSubmit={FormSubmit}>
                <Grid item container justifyContent="center">
                    <Typography variant='h4'>OBJEKTUM FRISSÍTÉSE</Typography>                   
                </Grid>
                
                <Grid item container xs={8} sx={{marginTop: "1rem", marginLeft: "auto", marginRight: "auto"}}>
                    <Button variant='contained' fullWidth type='submit' >FRISSÍT</Button>
                </Grid>               
                <Grid item container sx={{marginTop: "1rem"}}>
                    <TextField id="title" label="Title*" variant="standard" fullWidth value={state.titleValue}
                     onChange={(e)=>dispatch({type: 'catchTitleChange', titleChosen: e.target.value})}/>
                </Grid>
                
                <Grid item container sx={{marginTop: "1rem"}}>
                    <TextField id="description" label="Description" variant="standard" fullWidth value={state.descriptionValue}
                     onChange={(e)=>dispatch({type: 'catchDescriptionChange', descriptionChosen: e.target.value})}/>
                </Grid>           
                                  
               
                <Grid item container sx={{marginTop: "1rem"}}>
                            <TextField id="priceStatus" label="Price Status" variant="standard" fullWidth value={state.priceStatusValue}
                            onChange={(e)=>dispatch({type: 'catchPriceStatusChange', priceStatusChosen: e.target.value})}/>
                    </Grid>
                <Grid item container justifyContent={'space-between'}>                   
                    <Grid item  sx={{marginTop: "1rem"}}>
                        <TextField 
                        id="listingType" 
                        label="Listing Type*" 
                        variant="standard" 
                        fullWidth 
                        value={state.listingTypeValue}
                        onChange={(e)=>dispatch({type: 'catchListingTypeChange', listingTypeChosen: e.target.value})}
                        select 
                        SelectProps={{ native: true, }}>
                            {listingTypeOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                {option.label}
                                </option>
                            ))} 
                        </TextField>
                    </Grid>    
                    <Grid item sx={{marginTop: "1rem"}}>
                        <TextField 
                        id="propertyStatus" 
                        label="Property Status*" 
                        variant="standard" 
                        fullWidth 
                        value={state.propertyStatusValue}
                        onChange={(e)=>dispatch({type: 'catchPropertyStatusChange', propertyStatusChosen: e.target.value})}
                        select 
                        SelectProps={{ native: true, }}>
                            {propertyStatusOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                {option.label}
                                </option>
                            ))} 
                        </TextField>
                    </Grid>
                </Grid>              
                

                <Grid item container justifyContent={'space-between'}>                    
                    <Grid item sx={{marginTop: "1rem"}}>                    
                    <TextField 
                    id="rentFrequency" 
                    label="Rent Frequency*" 
                    variant="standard"
                    disabled={state.propertyStatusValue === 'Eladó' ? true : false} 
                    fullWidth 
                    value={state.rentFrequencyValue}
                    onChange={(e)=>dispatch({type: 'catchRentFrequencyChange', rentFrequencyChosen: e.target.value})}
                    select 
                    SelectProps={{ native: true, }}>
                        {rentalFrequencyOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                            {option.label}
                            </option>
                        ))} 
                    </TextField>
                    </Grid>
                    <Grid item  sx={{marginTop: "1rem"}}>
                        <TextField 
                        id="Price*"
                        type="number" 
                        label={PriceDisplay()}  
                        variant="standard" 
                        fullWidth 
                        value={state.priceValue}
                        onChange={(e)=>dispatch({type: 'catchPriceChange', priceChosen: e.target.value})}/>
                    </Grid>
                </Grid>

                
                <Grid item container sx={{marginTop: "1rem"}}>
                    <TextField id="description" label="Description" variant="outlined" 
                        multiline rows= {10}
                        fullWidth value={state.descriptionValue}
                        onChange={(e)=>dispatch({type: 'catchDescriptionChange', titleChosen: e.target.value})}/>
                </Grid>

                {state.listingTypeValue === 'Office'  ? ("") : (
                <Grid item container sx={{marginTop: "1rem"}}>
                    <TextField id="rooms" type="number" label="Rooms" variant="standard" fullWidth value={state.roomsValue}
                     onChange={(e)=>dispatch({type: 'catchRoomsChange', roomsChosen: e.target.value})}/>
                </Grid>
                )}
                <Grid item container justifyContent={'space-between'}>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.furnishedValue} 
                        onChange={(e)=>dispatch({type: 'catchFurnishedChange', furnishedChosen: e.target.checked})}/>} label="Furnished" />
                    </Grid>
                    <Grid item  sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.poolValue} 
                        onChange={(e)=>dispatch({type: 'catchPoolChange', poolChosen: e.target.checked})}/>} label="Pool" />
                    </Grid>
                    <Grid item  sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.elevatorValue} 
                        onChange={(e)=>dispatch({type: 'catchElevatorChange', elevatorChosen: e.target.checked})}/>} label="Elevator" />
                    </Grid>
                    <Grid item  sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.airconditioningValue} 
                        onChange={(e)=>dispatch({type: 'catchAirconditioningChange', airConditioningChosen: e.target.checked})}/>} label="Air Conditioning" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.parkingValue} 
                        onChange={(e)=>dispatch({type: 'catchParkingChange', parkingChosen: e.target.checked})}/>} label="Parking" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.wellnessValue} 
                        onChange={(e)=>dispatch({type: 'catchWellnessChange', wellnessChosen: e.target.checked})}/>} label="Wellness" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.kitchenValue} 
                        onChange={(e)=>dispatch({type: 'catchKitchenChange', kitchenChosen: e.target.checked})}/>} label="Kitchen" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.breakfastValue} 
                        onChange={(e)=>dispatch({type: 'catchBreakfastChange', breakfastChosen: e.target.checked})}/>} label="Breakfast" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.petsAllowedValue} 
                        onChange={(e)=>dispatch({type: 'catchPetsAllowedChange', petsAllowedChosen: e.target.checked})}/>} label="Pets Allowed" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.wifiValue} 
                        onChange={(e)=>dispatch({type: 'catchWifiChange', wifiChosen: e.target.checked})}/>} label="Wifi" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.airportTransferValue} 
                        onChange={(e)=>dispatch({type: 'catchAirportTransferChange', airportTransferChosen: e.target.checked})}/>} label="Airport Transfer" />
                    </Grid>
                    <Grid item sx={{marginTop: "1rem"}}>
                        <FormControlLabel control={<Checkbox checked={state.vacationTicketValue} 
                        onChange={(e)=>dispatch({type: 'catchVacationTicketChange', vacationTicketChosen: e.target.checked})}/>} label="Vacation Ticket" />
                    </Grid>
                </Grid>              
 
                <Grid item container xs={8} sx={{marginTop: "1rem", marginLeft: "auto", marginRight: "auto"}}>
                    <Button variant='contained' fullWidth type='submit' disabled={state.disabledListingUpdateBtn}>FRISSÍT</Button>
                </Grid>
            </form> 
            <Button variant='contained'  onClick={props.closeDialog} >MÉGSE</Button>    
            <Snackbar
                open={state.openSnack}     
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <Typography variant="body1">
                    Sikeresen frissítetted az adatlapot!
                </Typography>
            </Snackbar>
            
            </div>
        </div>
    )
}   

export default ListingUpdate;