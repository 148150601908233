import React, { useContext} from 'react';
import Axios from 'axios'
import {useNavigate, useParams} from "react-router-dom";
import { Typography, Button} from "@mui/material";


//Contexts
import DispatchContext from "../../Contexts/DispatchContext";
import StateContext from "../../Contexts/StateContext";


function Activation() {
  const {config, environment } = require('../../index');
  const navigate = useNavigate();
  const params = useParams();

  const GlobalDispatch = useContext(DispatchContext);
  const GlobalState = useContext(StateContext);

async function ActivationHandler(){
   try{
    const activationEndpoint = config[environment].activationEndpoint;
    const response = await Axios.post(activationEndpoint, {uid: params.uid, token: params.token});    
    navigate("/login");   
    }catch(e){
        
    }
}

return (
    <div>
      <Typography variant="h4" align="center" gutterBottom>
      Fiók aktiválása itt helyben!</Typography>
      <Button variant="contained" color="primary" fullwidth sx={{mrginTop: '1rem'}} onClick={ActivationHandler}>Fiókaktiválás</Button>
    </div>
  );
}
export default Activation;