import React, { useState, useContext, useEffect } from 'react';
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import { useImmerReducer } from 'use-immer';
import { Alert, Grid, Button, Modal, TextField, Typography, IconButton, Snackbar, Autocomplete } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'; 
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; 
import CloseIcon from '@mui/icons-material/Close';

import StateContext from "../../Contexts/StateContext";
import { centuryOptions } from '../../Constants/CenturyConstants';
import { formatDate } from '../../Utils/DateUtils';

const AddScientist = ({ open, onClose }) => {
    const {config, environment } = require('../../index');
    const navigate = useNavigate()
    const GlobalState = useContext(StateContext) 
    const userId = GlobalState.userId;

    const initialState = {
      
        scientistFirstName: '',
        scientistLastName: '',
        scientistCentury: '',
        scientistThesis: '',
        scientistThesisDate: dayjs(),
        scientistResearchField: '',
        scientistBirthDate: dayjs(),
        scientistBirthPlace: '',
        scientistDeathDate: dayjs(),
        scientistDeathPlace: '',
        scientistSzekelyfoldConnection: '',
        fieldsOfScience: [],
        sendRequest: 0,
        contributor: '',
        userProfile:{
            companyName: '',
            phoneNumber: '',
        },
        openSnack: false,
        openErrorSnack: false,
        snackMessage: '', 
        disabledAddScientistBtn: false,
        FirstNameError: {
            hasError: false,
            errorMessage: ''
        },
        LastNameError: {
            hasError: false,
            errorMessage: ''
        },
        CenturyError: {
            hasError: false,
            errorMessage: ''
        },
        ResearchFieldError: {
            hasError: false,
            errorMessage: ''
        },
        SzekelyfoldConnectionError: {
            hasError: false,
            errorMessage: ''
        },
        BirthDateError: {
            hasError: false,
            errorMessage: ''
        },
        BirthPlaceError: {
            hasError: false,
            errorMessage: ''
        },
        DeathDateError: {
            hasError: false,
            errorMessage: ''
        },
        DeathPlaceError: {
            hasError: false,
            errorMessage: ''
        },
        ThesisError: {
            hasError: false,
            errorMessage: ''
        },
        ThesisDateError: {
            hasError: false,
            errorMessage: ''
        },


    }   
    
    function ReducerFunction(draft, action) {
        switch (action.type) {
            case 'catchFirstNameChange':
                draft.scientistFirstName = action.scientistFirstNameChosen;
                draft.FirstNameError.hasError = false;
                draft.FirstNameError.errorMessage = '';
                break;
            case 'catchLastNameChange':
                draft.scientistLastName = action.scientistLastNameChosen;
                draft.LastNameError.hasError = false;
                draft.LastNameError.errorMessage = '';
                break;
            case 'catchCenturyChange':
                draft.scientistCentury = action.scientistCenturyChosen;
                draft.CenturyError.hasError = false;
                draft.CenturyError.errorMessage = '';
                break;
            case 'catchThesisChange':
                draft.scientistThesis = action.scientistThesisChosen;
                draft.ThesisError.hasError = false;
                draft.ThesisError.errorMessage = '';
                break;
            case 'catchThesisDateChange':
                draft.scientistThesisDate = action.scientistThesisDateChosen;
                draft.ThesisDateError.hasError = false;
                draft.ThesisDateError.errorMessage = '';
                break;
            case 'catchResearchFieldChange':
                draft.scientistResearchField = action.scientistResearchFieldChosen;
                draft.ResearchFieldError.hasError = false;
                draft.ResearchFieldError.errorMessage = '';
                break;
            case 'catchBirthDateChange':
                draft.scientistBirthDate = action.scientistBirthDateChosen;
                draft.BirthDateError.hasError = false;
                draft.BirthDateError.errorMessage = '';
                break;
            case 'catchBirthPlaceChange':
                draft.scientistBirthPlace = action.scientistBirthPlaceChosen;
                draft.BirthPlaceError.hasError = false;
                draft.BirthPlaceError.errorMessage = '';
                break;
            case 'catchDeathDateChange':
                draft.scientistDeathDate = action.scientistDeathDateChosen;
                draft.DeathDateError.hasError = false;
                draft.DeathDateError.errorMessage = '';
                break;
            case 'catchDeathPlaceChange':
                draft.scientistDeathPlace = action.scientistDeathPlaceChosen;
                draft.DeathPlaceError.hasError = false;
                draft.DeathPlaceError.errorMessage = '';
                break;
            case 'catchSzekelyfoldConnectionChange':
                draft.scientistSzekelyfoldConnection = action.scientistSzekelyfoldConnectionChosen;
                draft.SzekelyfoldConnectionError.hasError = false;
                draft.SzekelyfoldConnectionError.errorMessage = '';
                break;
            case 'catchFieldsOfScience':
                draft.fieldsOfScience = action.fieldsOfScience;
                return;
            case 'changeSendRequest':
                draft.sendRequest = draft.sendRequest + 1;
                console.log(draft);
                break;
            case 'catchUserProfileInfo':
                draft.userProfile.companyName = action.profileObject.company_name; 
                draft.userProfile.phoneNumber = action.profileObject.phone_number;
                break;
            case 'reset':
                return {
                    ...initialState,
                    userProfile: draft.userProfile
                };
            case 'openTheSnack':
                draft.openSnack = true;                
                break;
            case 'openTheErrorSnack':
                draft.openErrorSnack = true;                
                break;
            case 'allowTheAddScientistBtn':
                draft.disabledAddScientistBtn = false;
                break;
            case 'emptyFirstName':
                draft.FirstNameError.hasError = true;
                draft.FirstNameError.errorMessage = 'A keresztnév mező nem lehet üres';
                break;
            case 'emptyLastName':
                draft.LastNameError.hasError = true;
                draft.LastNameError.errorMessage = 'A vezetéknév mező nem lehet üres';
                break;
            case 'emptyCentury':
                draft.CenturyError.hasError = true;
                draft.CenturyError.errorMessage = 'Az évszázad mező nem lehet üres';
                break;           
            case 'emptyResearchField':
                draft.ResearchFieldError.hasError = true;
                draft.ResearchFieldError.errorMessage = 'A kutatási terület mező nem lehet üres';
                break;           
            case 'emptySzekelyfoldConnection':
                draft.SzekelyfoldConnectionError.hasError = true;
                draft.SzekelyfoldConnectionError.errorMessage = 'A székelyföldi kötődés mező nem lehet üres';
                break;
            default:
                break;
        }
    }

    const [state, dispatch] = useImmerReducer(ReducerFunction, initialState)

    //request to get profile info
    useEffect(() => {
        async function getProfileInfo() {
            try {
                const profileEndpoint = config[environment].getProfileEndpoint(userId); 
                const response = await axios.get(profileEndpoint);                  
                dispatch({type: 'catchUserProfileInfo', profileObject: response.data});
            } catch (e) {
                console.log(e.response);
            }

        }
        getProfileInfo();
    }, [config, environment, dispatch, userId]);


    //getting fields of science for the select input
    useEffect(() => {
        const fieldsOfScienceEndpoint = config[environment].fieldsOfScienceEndpoint;
        async function GetFieldsOfScience() {
            try {
                const response = await axios.get(fieldsOfScienceEndpoint);
                dispatch({type: 'catchFieldsOfScience', fieldsOfScience: response.data});
            } catch (e) {
                console.log('error', e);
            }
        }
        GetFieldsOfScience();
    }, [config, environment, dispatch]);

        

    useEffect(() => {
        if(state.sendRequest) {
            const data = new FormData();
            data.append('firstname', state.scientistFirstName);
            data.append('lastname', state.scientistLastName);
            data.append('century', (Number(state.scientistCentury)-2).toString());
            data.append('thesis_title', state.scientistThesis);
            data.append('thesis_date', formatDate(state.scientistThesisDate));
            data.append('fields_of_science', state.scientistResearchField);
            data.append('birth_date', formatDate(state.scientistBirthDate));
            data.append('birth_place', state.scientistBirthPlace);
            data.append('death_date', formatDate(state.scientistDeathDate));
            data.append('death_place', state.scientistDeathPlace);
            data.append('szekler_tie', state.scientistSzekelyfoldConnection);
            data.append('contributor', userId);

            async function AddScientist() {
                try {
                    const checkScientistEndpoint = config[environment].checkScientistEndpoint; 
                    const checkResponse = await axios.get(checkScientistEndpoint, {
                        params: {
                            firstname: state.scientistFirstName,
                            lastname: state.scientistLastName,
                            fields_of_science: state.scientistResearchField
                        }
                    });
            
                    if (checkResponse.data.exists) {
                        // Scientist with the same first name, last name and field of science already exists                        
                        dispatch({type: 'openTheErrorSnack'})
                        return;
                    }
            
                    const createScientistEndpoint = config[environment].createScientistEndpoint;
                    const createResponse = await axios.post(createScientistEndpoint, data);
                    dispatch({type: 'openTheSnack'});
                    window.location.reload();
                } catch (e) {
                    dispatch({type: 'allowTheAddScientistBtn'});
                }
            }
            AddScientist();
            
        }
    }, [config, environment, state.sendRequest]);

    useEffect(()=>{
        if (state.openSnack){
          setTimeout(()=>{  
            navigate('/academia');          
          }, 1000)
        }
      }, [state.openSnack]) 


      useEffect(()=>{
        if (state.openErrorSnack){
          setTimeout(()=>{            
          }, 2000)
        }
      }, [state.openErrorSnack])   

    const handleReset = () => {
        dispatch({ type: 'reset' });
    };
    

    function SubmitButtonDisplay(){        
    
        if(GlobalState.userIsLogged && state.userProfile.companyName !== null && state.userProfile.companyName !== "" && 
            state.userProfile.phoneNumber !== null && state.userProfile.phoneNumber !== "" ){
            return (<Button variant='contained'  type='submit' disabled={state.disabledAddPropertyBtn} >BEKÜLDÉS</Button>);
        } 
        else if(GlobalState.userIsLogged && (state.userProfile.companyName === null || state.userProfile.companyName === "" || 
            state.userProfile.phoneNumber === null || state.userProfile.phoneNumber === "")){
            return (<Button variant='outlined'  onClick={()=> navigate("/profile")}  >TÖLTSD KI A PROFILOD OBJEKTUMOK HOZZAADÁSÁHOZ</Button>);
        }
        else if (!GlobalState.userIsLogged){
            return (<Button variant='outlined'  onClick={()=> navigate("/login")}  >JELENTKEZZ BE OBJEKTUMOK HOZZAADÁSÁHOZ</Button>);
        }
    }

    
    function FormSubmit(e) {
        e.preventDefault();
        console.log(state);
        if(!state.FirstNameError.hasError && !state.LastNameError.hasError && !state.CenturyError.hasError &&
            !state.ResearchFieldError.hasError && !state.SzekelyfoldConnectionError.hasError && 
            state.scientistFirstName && state.scientistLastName &&
            state.scientistCentury && state.scientistResearchField && state.scientistSzekelyfoldConnection) {
            dispatch({type: 'changeSendRequest'});
            dispatch({type: 'disabledAddScientistBtn'});
        }
        else if(!state.scientistFirstName) {
            dispatch({type: 'emptyFirstName'});
        }
        else if(!state.scientistLastName) {
            dispatch({type: 'emptyLastName'});
        }
        else if(!state.scientistCentury) {
            dispatch({type: 'emptyCentury'});
        }   
        else if(!state.scientistResearchField) {
            dispatch({type: 'emptyResearchField'});
        }        
        else if(!state.scientistSzekelyfoldConnection) {
            dispatch({type: 'emptySzekelyfoldConnection'}); 
        }       
    }


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Modal open={open} onClose={onClose}>
            <form onSubmit={FormSubmit}>
                <Grid
                item
                container
                direction="column"
                alignItems="stretch"
                justifyContent="center"
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%', // Set default width to full width
                    maxWidth: 400, // Set max width for computer screens
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    '@media (min-width: 768px)': { // Tablet media query
                    maxWidth: '66.66%', // 2/3 of the screen width for tablets
                    },
                    '@media (min-width: 992px)': { // Large tablet/desktop media query
                    maxWidth: '50%', // 1/2 of the screen width for computers
                    },
                }}
                >
                    <Grid container justifyContent="space-between" >
                        <Typography variant='h4'>Kutató hozzáadása</Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid item sx={{ marginTop: "1rem" }}>
                        <Grid container spacing={2} justifyContent={'space-between'}>
                            <Grid item xs={6}>
                                <TextField
                                    required
                                    variant="standard"
                                    label="Kutató családneve"
                                    value={state.scientistLastName}
                                    onChange={(e)=> dispatch({type: 'catchLastNameChange', scientistLastNameChosen: e.target.value})}
                                    onBlur={(e)=>dispatch({type: 'catchLastNameError', scientistLastNameChosen: e.target.value})}
                                    error={state.LastNameError.hasError}
                                    helperText={state.LastNameError.errorMessage}                                                                                                     
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                                
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    required    
                                    variant="standard"
                                    label="Kutató keresztneve"
                                    value={state.scientistFirstName}
                                    onChange={(e)=> dispatch({type: 'catchFirstNameChange', scientistFirstNameChosen: e.target.value})}
                                    onBlur={(e)=>dispatch({type: 'catchFirstNameError', scientistFirstNameChosen: e.target.value})}
                                    error={state.FirstNameError.hasError}
                                    helperText={state.FirstNameError.errorMessage}
                                    fullWidth
                                    sx={{ mb: 2 }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField variant="standard" label="Évszázad" 
                                required
                                value={state.scientistCentury}
                                onChange={(e)=> dispatch({type: 'catchCenturyChange', scientistCenturyChosen: e.target.value})}
                                onBlur={(e)=>dispatch({type: 'catchCenturyError', scientistCenturyChosen: e.target.value})}
                                error={state.CenturyError.hasError}
                                helperText={state.CenturyError.errorMessage}
                                select
                                SelectProps={{native: true}}                               
                                
                                fullWidth sx={{ mb: 2 }} >
                                    {centuryOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                        {option.label}
                                        </option>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{marginTop:"1rem"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sx={{ marginTop: "1rem" }}>
                                <TextField variant="standard" label="Tézis címe" 
                                value={state.scientistThesis}
                                onChange={(e)=> dispatch({type: 'catchThesisChange', scientistThesisChosen: e.target.value})}
                                fullWidth sx={{ mb: 2 }} />
                            </Grid>
                            <Grid item xs={6}>
                                        <DatePicker 
                                            label="Tézis ideje" 
                                            value={state.scientistThesisDate}
                                            minDate={dayjs(new Date(1600, 0, 1))}
                                            onChange={(e)=> {
                                                if(e && e.target){
                                                    let formattedDate = formatDate(e.target.value);
                                                    dispatch({type: 'catchThesisDateChange', scientistThesisDateChosen: formattedDate})
                                            }}}
                                            fullWidth 
                                            sx={{ mb: 2 }} 
                                        />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item  sx={{ marginTop: "1rem" }}>
                        <Grid container spacing={2} >
                            <Grid item xs={4} >                              
                                <Autocomplete
                                    variant="standard"
                                    options={state.fieldsOfScience}
                                    getOptionLabel={(option) => option.name}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => 
                                        <TextField 
                                            {...params} 
                                            label="Kutatási terület" 
                                            required 
                                            fullWidth 
                                            className="autocomplete-input"
                                        />
                                    }
                                    onChange={(event, newValue) => {
                                        dispatch({type: 'catchResearchFieldChange', scientistResearchFieldChosen: newValue ? newValue.id : ""})
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ marginTop: "3rem" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <DatePicker 
                                    label="Születés ideje" 
                                    value={state.scientistBirthDate}
                                    minDate={dayjs(new Date(1600, 0, 1))}
                                    onChange={(e)=> {
                                        if(e && e.target){
                                            let formattedDate = formatDate(e.target.value);
                                            dispatch({type: 'catchBirthDateChange', scientistBirthDateChosen: formattedDate})
                                    }}}
                                    fullWidth 
                                    sx={{ mb: 2 }} 
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField variant="standard" label="Születés helye" value={state.scientistBirthPlace}
                                onChange={(e)=> dispatch({type: 'catchBirthPlaceChange', scientistBirthPlaceChosen: e.target.value})}
                                fullWidth sx={{ mb: 2 }} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ marginTop: "1rem" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <DatePicker 
                                label="Elhalálozás ideje" 
                                value={state.scientistDeathDate}
                                minDate={dayjs(new Date(1600, 0, 1))}
                                onChange={(e)=> {
                                    if(e && e.target){
                                    let formattedDate = formatDate(e.target.value);
                                    dispatch({type: 'catchDeathDateChange', scientistDeathDateChosen: formattedDate})
                                }}}
                                fullWidth sx={{ mb: 2 }} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField variant="standard" label="Elhalálozás helye" 
                                onChange={(e)=> dispatch({type: 'catchDeathPlaceChange', scientistDeathPlaceChosen: e.target.value})}
                                fullWidth sx={{ mb: 2 }} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sx={{ marginTop: "1rem" }}>
                        <TextField variant="standard" label="Kötődés Székelyföldhöz" value={state.scientistSzekelyfoldConnection}
                        required
                        onChange={(e)=> dispatch({type: 'catchSzekelyfoldConnectionChange', scientistSzekelyfoldConnectionChosen: e.target.value})} 
                        fullWidth sx={{ mb: 2 }} />
                    </Grid>
                    <Grid item sx={{ marginTop: "1rem" }}>                        
                        {SubmitButtonDisplay()}                        
                        <Button variant="contained" onClick={handleReset} sx={{ ml: 2 }}>
                            Visszaállítás
                        </Button>
                    </Grid>
                </Grid>
            </form>
             
        </Modal>
        <Snackbar
            open={state.openSnack}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            >
            <Alert severity="success">
                A kutatót sikeresen hozzáadtad!
            </Alert>
        </Snackbar>       

        <Snackbar
            open={state.openErrorSnack}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            >
            <Alert severity="error">
                Ilyen nevű kutató már szerepel az adatbázisban! 
                <br/> Válasszon más kutatási területet, vagy adjon meg másik kutatót!
                <br/> Használjon megkülönböztető iniciálét, vagy indokolt esetben adja hozzá saját monogrammját! 
                <br/> Példák: János I. Kájoni, Bányai János (:K.Sz.)
            </Alert>
        </Snackbar>
    </LocalizationProvider>
    );
};

export default AddScientist;