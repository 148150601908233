import React, { useEffect, useState } from 'react';  
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { Grid, Typography, Button, CircularProgress, Card, CardContent, CardMedia, CardActions } from "@mui/material";

// Assets
import defaultProfilePicture from "../../Assets/defaultProfilePicture.jpg";

// Define individual default images
import sznmImage from "../../Assets/sznm.png";
import szntImage from "../../Assets/sznt.png";
import sznpImage from "../../Assets/sznp.jpg";
import szvszImage from "../../Assets/szvsz.png";
import dsfImage from "../../Assets/dsz.png";
import gszeImage from "../../Assets/gsze.jpg";
import szkImage from "../../Assets/szk.jpg";
import szlszImage from "../../Assets/szlsz.jpg";
import szkeImage from "../../Assets/szke.jpg";
import szcsvImage from "../../Assets/szcsv.jpg";
import szfilImage from "../../Assets/szfil.png";
import szftImage from "../../Assets/szft.jpg";
import sznepvsImage from "../../Assets/sznepvs.png";
import szvgImage from "../../Assets/szekelyvagta.png";
import szlgImage from "../../Assets/szekelylegio.jpg";
import szmImage from "../../Assets/szekelymiko.png";
import scisicImage from "../../Assets/scisic.png";




function Institutions() {
    const navigate = useNavigate();
    const [companiesList, setCompaniesList] = useState([]);
    const [websites, setWebsites] = useState([]);
    const [dataIsLoading, setDataIsLoading] = useState(true);
    const { config, environment } = require('../../index');

    // Request to get all profiles
    useEffect(() => {
        async function fetchData() {
            try {  
                
                const companiesEndpoint = config[environment].companiesEndpoint;              
                const companiesResponse = await Axios.get(companiesEndpoint);
                setCompaniesList(companiesResponse.data);

                const websitesData = [
                    { name: "Székely Nemzeti Múzeum", url: "https://www.sznm.ro", defaultImage: sznmImage },
                    { name: "Székely Mikó Kollégium", url: "https://www.facebook.com/szekelymiko", defaultImage: szmImage},
                    { name: "Pro Scientia Siculorum Egyesület", url: "https://proscisic.ro/", defaultImage: scisicImage},
                    { name: "Székely Nemzeti Tanács", url: "https://www.sznt.org", defaultImage: szntImage },
                    { name: "Székely Nemzeti Párt", url: "https://www.facebook.com/szekelynemzetipart/", defaultImage: sznpImage },
                    { name: "Székelyek Világszövetsége", url: "https://www.szekelyekvilagszovetsege.hu/", defaultImage: szvszImage },
                    { name: "Digitális Székelyföld", url: "https://www.digitalisszekelyfold.ro", defaultImage: dsfImage },
                    { name: "Székely Gazdaszervezetek Egyesülete", url: "https://www.facebook.com/SzekelyGazdaszervezetekEgyesulete", defaultImage: gszeImage },
                    { name: "Székely Könyvtár", url: "https://www.facebook.com/szekelykonyvtar", defaultImage: szkImage },
                    { name: "Székely Labdarúgó-válogatott", url: "https://www.facebook.com/SzekelyValogatott", defaultImage: szlszImage },
                    { name: "Székelyföldi Kézművesek", url: "https://www.szekely-kezmuvesek.ro", defaultImage: szkeImage },
                    { name: "Székely és Csángó Világ", url: "https://www.facebook.com/groups/1888184294730816/", defaultImage: szcsvImage },
                    { name: "Székelyföldi Filharmónia", url: "https://www.filharmonia.ro/", defaultImage: szfilImage },                    
                    { name: "Székely Népviselet", url: "https://www.facebook.com/groups/307577142659595", defaultImage: sznepvsImage },
                    { name: "Székelyföldi Földtani Társulat", url: "https://www.facebook.com/groups/747593969042183", defaultImage: szftImage },
                    { name: "Székely Vágta", url: "http://www.szekelyvagta.ro", defaultImage: szvgImage},
                    { name: "Székely Légió", url: "https://www.facebook.com/sepsioskfanpage", defaultImage: szlgImage},
                    
                ];

                setWebsites(websitesData);
                setDataIsLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        fetchData();
    }, []);

    // Loading
    if (dataIsLoading) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <>
            <Typography variant="h4" style={{ textAlign: "center", paddingTop: "1rem", background: "#032b3b" }}>Regisztrált intézmények</Typography>
            <Grid container justifyContent='flex-start' spacing={2} style={{ padding: "10px", height: "30vh", background: "#032b3b" }}>
                {/* Regisztrált intézmények */}
                {companiesList.map((company) => {
                    function PropertiesDisplay() {
                        if (company.seller_listings.length === 0) {
                            return (
                                <Button disabled size="small">Nincs közzétett objektum</Button>
                            );
                        } else {
                            return (
                                <Button size="small" onClick={() => navigate(`/institutions/${company.seller}`)}>{company.seller_listings.length} közzétett objektum</Button>
                            );
                        }
                    }

                    if (company.company_name && company.phone_number) {
                        return (
                            <Grid key={company.id} item sx={{ paddingTop: "1rem", maxWidth: '20rem' }}>
                                <Card sx={{ maxWidth: 300 }} key={company.id}>
                                    <CardMedia
                                        component="img"
                                        sx={{ objectFit: "contain", height: 120, width: "100%" }}
                                        image={company.profile_picture ? company.profile_picture : defaultProfilePicture}
                                        title="Profilkép"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" component="div">
                                            {company.company_name} ...
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {company.bio.substring(0, 100)}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        {PropertiesDisplay()}
                                    </CardActions>
                                </Card>
                            </Grid>
                        );
                    }
                })}
            </Grid>
            <Typography variant="h4" style={{ textAlign: "center", paddingTop: "1rem", background: "#032b3b" }}>Intézményi weboldalak</Typography>
            <Grid container justifyContent="center" alignItems="center" style={{ height: "60vh", background: "#032b3b" }}>
                {/* Intézmény honlapok linkjei */}
                {websites.map((website, index) => (
                    <Grid key={index} item style={{ padding: "1rem" }}>
                        <Card sx={{ maxWidth: 220 }}>
                            <CardMedia
                                component="img"
                                sx={{ objectFit: "contain", height: '90%', width: "100%", maxHeight: 250 }}
                                image={website.defaultImage}
                                title="Website Preview"
                                onClick={() => window.open(website.url, "_blank")}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h6" component="div">
                                    {website.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary"style={{ wordWrap: 'break-word' }}>
                                    {website.url}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" onClick={() => window.open(website.url, "_blank")} variant="outlined">
                                    Látogassa meg a honlapot
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    );
}

export default Institutions;
