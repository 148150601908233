const config = {
    development: {
        activationEndpoint: 'http://localhost:8000/api-auth-djoser/users/activation/',
        loginEndpoint: "http://localhost:8000/api-auth-djoser/token/login/",
        logoutEndpoint: "http://localhost:8000/api-auth-djoser/token/logout/",
        myUserEndpoint: "http://localhost:8000/api-auth-djoser/users/me/",
        registerEndpoint: "http://localhost:8000/api-auth-djoser/users/",        
        
        //getall
        listingsEndpoint: "http://localhost:8000/api/listings/",
        builtHeritageEndpoint: "http://localhost:8000/api/built_heritage/",
        accommodationEndpoint: "http://localhost:8000/api/accommodation/",
        companiesEndpoint: "http://localhost:8000/api/profiles/",
        scientistsEndpoint: "http://localhost:8000/api/academia/",
        fieldsOfScienceEndpoint: "http://localhost:8000/api/fields_of_science/",
        parishEndpoint: (page = 1) => `http://localhost:8000/api/kozsegvaros/?page=${page}`,
        telepulesEndpoint: (page = 1) => `http://localhost:8000/api/telepules/?page=${page}`,
        szekEndpoint: (page = 1) => `http://localhost:8000/api/szek/?page=${page}`,
        megyeEndpoint: (page = 1) => `http://localhost:8000/api/megye/?page=${page}`,
        helynevEndpoint: (page = 1) => `http://localhost:8000/api/helynev/?page=${page}`,
        localityEndpoint: "http://localhost:8000/api/telepules/",

        //dropdowndata
        scientistIdWithFullNameEndpoint: "http://localhost:8000/api/scientists_fullname/",

        //check
        checkScientistEndpoint: "http://localhost:8000/api/academia/check/",

        //create
        createListingEndpoint: "http://localhost:8000/api/listings/create/",
        createScientistEndpoint: "http://localhost:8000/api/academia/create/",
        createPublicationEndpoint: "http://localhost:8000/api/publications/create/",

        
        //detail
        getProfileEndpoint: (userId) => `http://localhost:8000/api/profiles/${userId}/`,
        getListingInfoEndpoint: (paramsId) => `http://localhost:8000/api/listings/${paramsId}/`,
        getProfileInfoEndpoint: (sellerId) => `http://localhost:8000/api/profiles/${sellerId}/`,
        getScientistInfoEndpoint: (scientistId) => `http://localhost:8000/api/academia/${scientistId}/`,
        
        getPublicationsEndpoint: (clickedScientist) => `http://localhost:8000/api/publications/${clickedScientist}`,

        //delete  
        deleteListingEndpoint: (paramsId) => `http://localhost:8000/api/listings/${paramsId}/delete/`,
        deleteScientistEndpoint: (scientistId) => `http://localhost:8000/api/academia/${scientistId}/delete/`,
        deletePublicationEndpoint: (publicationId) => `http://localhost:8000/api/publications/${publicationId}/delete/`,        

        //update
        getProfileUpdateEndpoint: (userId) => `http://localhost:8000/api/profiles/${userId}/update/`,
        getListingUpdateEndpoint: (listingId) => `http://localhost:8000/api/listings/${listingId}/update/`,
        getScientistUpdateEndpoint: (scientistId) => `http://localhost:8000/api/academia/${scientistId}/update/`,
        getPublicationUpdateEndpoint: (publicationId) => `http://localhost:8000/api/publications/${publicationId}/update/`,
  
    },
    production: {
        activationEndpoint: 'https://www.sznoapi.ro/api-auth-djoser/users/activation/',
        loginEndpoint: "https://www.sznoapi.ro/api-auth-djoser/token/login/",
        logoutEndpoint: "https://www.sznoapi.ro/api-auth-djoser/token/logout/",
        myUserEndpoint: "https://www.sznoapi.ro/api-auth-djoser/users/me/",
        registerEndpoint: "https://www.sznoapi.ro/api-auth-djoser/users/",

        //getall
        listingsEndpoint: "https://www.sznoapi.ro/api/listings/",
        builtHeritageEndpoint: "https://www.sznoapi.ro/api/built_heritage/",
        accommodationEndpoint: "https://www.sznoapi.ro/api/accommodation/",
        companiesEndpoint: "https://www.sznoapi.ro/api/profiles/",        
        scientistsEndpoint: "https://www.sznoapi.ro/api/academia/",
        fieldsOfScienceEndpoint: "https://www.sznoapi.ro/api/fields_of_science/",
        parishEndpoint: (page = 1) => `https://www.sznoapi.ro/api/kozsegvaros/?page=${page}`,
        telepulesEndpoint: (page = 1) => `https://www.sznoapi.ro/api/telepules/?page=${page}`,
        szekEndpoint: (page = 1) => `https://www.sznoapi.ro/api/szek/?page=${page}`,
        megyeEndpoint: (page = 1) => `https://www.sznoapi.ro/api/megye/?page=${page}`,
        helynevEndpoint: (page = 1) => `https://www.sznoapi.ro/api/helynev/?page=${page}`,
        localityEndpoint: "https://www.sznoapi.ro/api/telepules/",

        //dropdowndata
        scientistIdWithFullNameEndpoint: "https://www.sznoapi.ro/api/scientists_fullname/",

        //check
        checkScientistEndpoint: "https://www.sznoapi.ro/api/academia/check/",

        //create
        createListingEndpoint: "https://www.sznoapi.ro/api/listings/create/",
        createScientistEndpoint: "https://www.sznoapi.ro/api/academia/create/",
        createPublicationEndpoint: "https://www.sznoapi.ro/api/publications/create/",

        //detail
        getProfileEndpoint: (userId) => `https://www.sznoapi.ro/api/profiles/${userId}/`,
        getProfileInfoEndpoint: (sellerId) => `https://www.sznoapi.ro/api/profiles/${sellerId}/`,
        getListingInfoEndpoint: (paramsId) => `https://www.sznoapi.ro/api/listings/${paramsId}/`,
        getScientistInfoEndpoint: (scientistId) => `https://www.sznoapi.ro/api/academia/${scientistId}/`,

        getPublicationsEndpoint: (clickedScientist) => `https://www.sznoapi.ro/api/publications/${clickedScientist}`,     
        
        //delete
        deleteListingEndpoint: (paramsId) => `https://www.sznoapi.ro/api/listings/${paramsId}/delete/`,
        deleteScientistEndpoint: (scientistId) => `https://www.sznoapi.ro/api/academia/${scientistId}/delete/`,
        deletePublicationEndpoint: (publicationId) => `https://www.sznoapi.ro/api/publications/${publicationId}/delete/`,
        
        //update
        getProfileUpdateEndpoint: (userId) => `https://www.sznoapi.ro/api/profiles/${userId}/update/`,
        getListingUpdateEndpoint: (listingId) => `https://www.sznoapi.ro/api/listings/${listingId}/update/`,
        getScientistUpdateEndpoint: (scientistId) => `https://www.sznoapi.ro/api/academia/${scientistId}/update/`,
        getPublicationUpdateEndpoint: (publicationId) => `https://www.sznoapi.ro/api/publications/${publicationId}/update/`,
    }
  };

  export default config;